const helpers = {
  dateFormat: function (date, withtime) {
    withtime = withtime ?? false;
    return (
      date.getDate() +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      ("" + date.getFullYear()).slice(-2) +
      (withtime ? " " + this.getTime(date) : "")
    );
  },
  getTime: function (date) {
    return (
      ("0" + date.getHours()).slice(-2) +
      ":" +
      ("0" + date.getMinutes()).slice(-2)
    );
  },
  dateFormatFromTo: function (starts, ends, withtime) {
    withtime = withtime ?? false;
    // let res = "";

    if (starts.toISOString().slice(0, 10) != ends.toISOString().slice(0, 10)) {
      return this.dateFormat(ends, withtime);
    }

    if (withtime) {
      return this.getTime(ends);
    }

    return "";
  },
};

export default helpers;
