import React from "react";
import TransferPublicItem from "../PublicProfile/TransferPublicItem";
import DonationPublicItem from "../PublicProfile/DonationPublicItem";
import CauseUserProfileItem from "../Profile/CauseUserProfileItem";

function ForeignProfileItem({ item }) {
  if (item.triggerable_type.includes("Order")) return null;
  return (
    <>
      {item.triggerable_type.includes("Transfer") && (
        <TransferPublicItem item={item} />
      )}
      {item.triggerable_type.includes("Donation") && (
        <DonationPublicItem item={item} />
      )}
    </>
  );
}

export default ForeignProfileItem;
