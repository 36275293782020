import React from "react";
import fake from "../../assets/avatar.jpg";
import PriceSet from "../Shared/PriceSet";
import helpers from "../../helpers/textFormat.js";
import { useTranslation } from "react-i18next";

function OrderProfileItem({ item }) {
	const { t } = useTranslation();
	const created = item?.created_at ? new Date(item?.created_at) : null;
	const products = item?.triggerable?.products;

	const cargo = item?.triggerable?.cargo_price;
	const company = item?.triggerable?.products[0]?.organization?.name;
	const logo = item?.triggerable?.products[0]?.organization?.logo;

	return (
		<div className="shadow-md mb-1 rounded-ftxl overflow-hidden bg-white">
			{products?.length &&
				products.map((product) => (
					<a
						href={`/product-detail/${product?.id}`}
						target="_blank"
						key={product?.id}
						className="flex flex-col  font-LatoRegular  hover:opacity-75"
					>
						<div className="flex flex-row bg-white w-full items-center pr-4">
							<div className="w-24 h-24 relative aspect-square p-4 ml-8 flex ">
								<img
									src={product?.images[0]?.url ?? fake}
									className="object-cover rounded-xl "
									alt="order item" 
								/>
							</div>
							<div className="w-1/4 font-bold font-VolksMedium underline ml-8 mr-0">
								{product.title}
							</div>
							<div className="w-1/4 text-center text-sm ml-auto mr-16">
								x {product.pivot?.quantity}
							</div>
							<PriceSet karma={product?.karma} price={product?.price} />
						</div>
					</a>
				))}

			<div className="ml-8 p-4  font-LatoRegular text-greyText flex flex-row items-center border-t border-bodyBg">
				<span className="w-8 h-8 relative aspect-square flex mr-2">
					<img
						src={logo}
						className="object-cover rounded-full border-bodyBg  border"
					/>
				</span>
				{company}
				<span className="text-sm font-normal ml-16 mr-4 text-gray-500">
					{t("historic.at")}:
				</span>
				<span className="">
					{helpers.dateFormat(created, true)}
					{/* {created.getFullYear()}-{("0" + (created.getMonth() + 1)).slice(-2)}-
          {created.getDate()} {("0" + created.getHours()).slice(-2)}:
          {("0" + created.getMinutes()).slice(-2)} */}
				</span>
				{cargo > 0 && (
					<>
						<span className="text-sm font-normal ml-auto mr-4 text-gray-500">
							{t("cart.cargo_price")}:
						</span>

						<span className="">
							<PriceSet price={cargo} />
						</span>
					</>
				)}
			</div>
		</div>
	);
}

export default OrderProfileItem;
