import React, { useState } from "react";
import img_mao from "../../assets/new-landing-page/builder/c2450b02a705b7f69066cb3d26f48b12b17e29018c5dc4d902451b278dc8187a.png";
import "./tailwind.js";
import { ReactComponent as CheckIcon } from "../../assets/new-landing-page/builder/632ec3860d50cf3f01c05a024ff37e5cfe8df3a94f3398d4bbf5ce96bc7d851a.svg";

const CheckboxCustomizado = ({ checked, onChange }) => {
    return (
        <div className="flex items-center">
            <input
                className="opacity-0 absolute h-0 w-0"
                type="checkbox"
                checked={checked}
                onChange={onChange}
            />
            <div
                className={`flex justify-center items-center w-6 h-6 rounded-lg ${checked ? 'bg-blue-600 border-blue-600' : 'bg-white border-gray-300'
                    } cursor-pointer`}
                onClick={onChange}
            >
                {checked && <CheckIcon className="w-6 h-6 text-white" />}
            </div>
        </div>
    );
};

export const Contact = () => {
    const [formData, setFormData] = useState({
        nome: "",
        email: "",
        contato: "",
        empresa: "",
        organizacao: "",
        mensagem: "",
        privacidade: false,
    });

    const handleChange = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setFormData({ ...formData, [e.target.name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        for (const key in formData) {
            if (!formData[key]) {
                alert("Preencha todos os campos e aceite a Politica de privacidade!");
                return;
            }
        }

        if (!formData.email.includes("@") || !formData.email.includes(".")) {
            alert("E-mail inválido!");
            return;
        }

        fetch(`${process.env.REACT_APP_API_URI}/api/contato`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
        })
            .then((response) => {
                if (response.ok) {
                    alert("Mensagem enviada com sucesso!");
                    setFormData({
                        nome: "",
                        email: "",
                        contato: "",
                        empresa: "",
                        organizacao: "",
                        mensagem: "",
                        privacidade: false,
                    });
                } else {
                    alert("Erro ao enviar mensagem");
                }
            })
            .catch((error) => {
                alert("Erro ao enviar mensagem");
            });
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="justify-end pl-14 mt-2.5 w-full bg-cyan-950 max-md:pl-5 max-md:max-w-full">
                <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                    <div className="flex flex-col w-[46%] max-md:ml-0 max-md:w-full">
                        <div className="flex flex-col self-stretch my-auto max-md:mt-10 max-md:max-w-full">
                            <div className="flex flex-col text-xl text-white font-[5] max-md:max-w-full">
                                <div className="shrink-0 w-14 bg-slate-400 h-[3px]" />
                                <div className="justify-center mt-6 max-md:max-w-full">
                                    <span className="">
                                        Nossos produtos e serviços impulsionam a sua geração de impacto positivo.
                                    </span>
                                    Quer fazer parte dessa nova economia?
                                </div>
                            </div>
                            <div className="flex flex-col mt-4 max-md:max-w-full">
                                <input
                                    className="justify-center px-9 py-2.5 text-base rounded-2xl border border-solid border-slate-400 border-opacity-30 text-slate-400 max-md:px-5 max-md:max-w-full bg-transparent"
                                    placeholder="Nome e Sobrenome"
                                    name="nome"
                                    value={formData.nome}
                                    onChange={handleChange}
                                />
                                <div className="flex pb-4 gap-5 mt-5 text-base whitespace-nowrap text-slate-400 max-md:flex-wrap">
                                    <input
                                        className="justify-center px-9 py-2.5 rounded-2xl border border-solid border-slate-400 border-opacity-30 max-md:px-5 bg-transparent w-1/2"
                                        placeholder="E-mail"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                    <input
                                        className="justify-center px-9 py-2.5 rounded-2xl border border-solid border-slate-400 border-opacity-30 max-md:px-5 bg-transparent w-1/2"
                                        placeholder="Contato"
                                        name="contato"
                                        value={formData.contato}
                                        onChange={handleChange}
                                    />
                                </div>
                                <input
                                    className="flex-1 justify-center px-9 py-2.5 text-base whitespace-nowrap rounded-2xl border border-solid border-slate-400 border-opacity-30 text-slate-400 max-md:px-5 bg-transparent"
                                    placeholder="Empresa"
                                    name="empresa"
                                    value={formData.empresa}
                                    onChange={handleChange}
                                />
                                <div className="flex gap-2.5 px-9 py-2.5 mt-5 rounded-2xl border border-solid border-slate-400 border-opacity-30 max-md:flex-wrap max-md:px-5">
                                    <select
                                        className="flex-1 text-base text-slate-400 max-md:max-w-full bg-transparent"
                                        name="organizacao"
                                        value={formData.organizacao}
                                        onChange={handleChange}
                                    >
                                        <option value="">Tipo de organização</option>
                                        <option value="industria">Industria</option>
                                        <option value="comercio">Comércio</option>
                                        <option value="servicos">Serviços</option>
                                        <option value="outros">Outros</option>
                                    </select>
                                </div>
                                <textarea
                                    className="justify-center px-9 py-5 mt-5 text-base whitespace-nowrap rounded-2xl border border-solid border-slate-400 border-opacity-30 text-slate-400 max-md:px-5 max-md:max-w-full bg-transparent"
                                    placeholder="Mensagem"
                                    name="mensagem"
                                    value={formData.mensagem}
                                    onChange={handleChange}
                                />
                                <div className="flex flex-col mt-5 text-sm max-md:max-w-full">
                                    <div className="flex gap-4 text-slate-400 max-md:flex-wrap">
                                        <CheckboxCustomizado
                                            checked={formData.privacidade}
                                            onChange={() => setFormData({ ...formData, privacidade: !formData.privacidade })}
                                        />
                                        <div className="flex-1 max-md:max-w-full">
                                            Ao informar meus dados, estou ciente e concordo com as diretrizes da{" "}
                                            <span className="font-bold">Política de Privacidade</span>{" "}
                                            da magikk.
                                        </div>
                                    </div>
                                    <button
                                        className="justify-center items-center px-8 py-2.5 mt-5 max-w-full text-white rounded-md bg-slate-400 font-[5] leading-[150%] w-[359px] max-md:px-5"
                                        type="submit"
                                    >
                                        Enviar mensagem
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col ml-5 w-[54%] max-md:ml-0 max-md:w-full">
                        <img
                            loading="lazy"
                            srcSet={img_mao}
                            className="grow w-full aspect-[0.96] max-md:mt-10 max-md:max-w-full"
                        />
                    </div>
                </div>
            </div>
        </form>
    );
};
