import React, { useEffect } from "react";
import MainHeader from "../components/MainHeader";
import { connect } from "react-redux";
import Footer from "../components/Footer";
import CartItem from "../components/Cart/CartItem";
import ProfileCard from "../components/Profile/ProfileCard";
import { useTranslation } from "react-i18next";
import {
  handleCartPayment,
  getShipmentPrice,
  handleGetDeliveryInfo,
  handleGetNeeds,
  handleUpdateDeliveryInfo,
  handleCalculateBasket,
} from "../redux/Cart/cart.helpers";
import loaderGif from "../assets/spinner.gif";
import { setToastrTxt } from "../redux/Cart/cart.actions";
import { checkCurrentUser } from "../redux/Auth/auth.actions";
import { useHistory } from "react-router-dom";
import { is } from "date-fns/locale";

function Cart({
  walletKK,
  cartPayment,
  profileData,
  dispatchSetToastrTxt,
  dispatchCheckCurrentUser,
  currentLang,
}) {
  const { t, i18n } = useTranslation();

  document.title = "magikk - carrinho";

  const history = useHistory();
  let _walletKK = parseInt(walletKK);
  // let _walletKK = parseInt(walletKK);
  const [cartItems, setCartItems] = React.useState(null);
  const [totals, setTotals] = React.useState({});
  const [shipmentPrice, setShipmentPrice] = React.useState(null);
  const [creditCardFormState, setCreditCardFormState] =
    React.useState({});
  const [productsArray, setProductsArray] = React.useState({});
  // address info
  const [userAddress, setUserAddress] = React.useState();
  const [userPhone, setUserPhone] = React.useState();
  const [userDocument, setUserDocument] = React.useState();
  const [needs, setNeeds] = React.useState();
  const [userPostalCode, setUserPostalCode] = React.useState();
  const [userNo, setUserNo] = React.useState();
  const [_update, set_update] = React.useState(true);
  const [userCity, setUserCity] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [addressSaved, setAddressSaved] = React.useState(false);
  const [isOrderReady, setIsOrderReady] = React.useState(false);
  const [isTakeAway, setIsTakeAway] = React.useState(true);
  const [showEditProfile, setShowEditProfile] = React.useState(false);

  const fieldRefPhone = React.useRef();

  const fieldRefs = {
    phone: fieldRefPhone,
  };

  const triggerToastr = ({ toastrTxt, title, err }) => {
    dispatchSetToastrTxt({
      title: title,
      txt: toastrTxt,
      err: err,
    });
    setTimeout(() => {
      dispatchSetToastrTxt();
    }, 3500);
  };

  const onDelete = (item) => {
    try {
      const result = localStorage.getItem("cart");
      if (result !== null) {
        const currentCartArray = JSON.parse(result);

        const newArray = currentCartArray.filter((crt) => {
          return crt.id != item.id;
        });

        localStorage.setItem("cart", JSON.stringify(newArray));

        setCartItems(newArray);
        set_update(true);
        setShipmentPrice(null);
      } else {
        triggerToastr({ toastrTxt: "Error when delete.", err: true });
      }
    } catch (err) {
      triggerToastr({ toastrTxt: "Cart remove error", err: true });
    }
  };

  const updateShipping = async () => {
    if (userPostalCode) {
      // console.log("updating shipping : ", productsArray);
      handleCalculateBasket(
        {
          products: productsArray.products,
          isTakeAway: ((isTakeAway === undefined) ? 0 : isTakeAway)
        },
        currentLang,
      )
        .then((_prices) => {
          console.log("prices : ", _prices);
          setTotals(_prices?.total);
          setShipmentPrice(isTakeAway ? 0 : _prices?.total?.shipmentPrice);
          setIsOrderReady(true);
          setAddressSaved(true);
        })
        .catch((err) => {
          triggerToastr({
            title: err?.response?.data?.message,
            toastrTxt: err?.response?.data?.data,
            err: true,
          });
        });
    }
  };

  const startCartPayment = async () => {
    setLoading(true);
    // const { y, m } = formatDateAndYear();
    const _data = {
      products: productsArray?.products,
    };

    const expire_month = creditCardFormState?.date
      ? creditCardFormState?.date.split("/")[0]
      : null;
    const expire_year = creditCardFormState?.date
      ? creditCardFormState?.date.split("/")[1]
      : null;

    const credit_card = {
      name: "credit_card",
      card_info: {
        holder_name: creditCardFormState?.holder_name,
        // number: creditCardFormState?.number?.replace(/\s/g, ""),
        number: creditCardFormState?.number,
        expire_year: expire_year,
        expire_month: expire_month,
        cvv: creditCardFormState?.cvv,
        document: userDocument,
        billing_address: {
          // line_1: "3986 Simpson Avenue",
          // zip_code: "17403",
          // city: "New York",
          // state: "NY",
          // country: "US",
          line_1: userAddress + " " + userNo,
          zip_code: userPostalCode.replace(/[^0-9]/g, ''),
          city: userCity,
          state: "",
          country: "BR",
        },
      },
    };

    // if (needs?.needCreditCard) {
    _data.credit_card = credit_card;
    _data.language = currentLang;
    _data.isTakeAway = ((isTakeAway === undefined) ? 0 : isTakeAway)
    // }

    await handleCartPayment(_data)
      .then(() => {
        setLoading(false);
        localStorage.removeItem("cart");
        history.push("/payment-success", { data: totals });
      })
      .catch((err) => {
        setLoading(false);
        triggerToastr({
          toastrTxt: err?.message,
          title: err?.title,
          err: true,
        });
      });
    // alert(JSON.stringify(result));
  };

  React.useEffect(async () => {
    const result = await JSON.parse(localStorage.getItem("cart"));
    if (result && cartItems == null) {
      setCartItems(result);
      //console.log(" get-needs burada : ", array);
      set_update(true);
    }
  }, []);

  React.useEffect(async () => {
    let array = JSON.parse(localStorage.getItem("cart"));
    if (_update && array?.length > 0) {
      const _productsArray = array.map((a) => ({
        id: a.id,
        quantity: a.piece || 1,
      }));

      setProductsArray({
        products: _productsArray,
      });

      const _needs = await handleGetNeeds({
        products: _productsArray,
      });
      setNeeds(_needs);
      setAddressSaved(!_needs.needAddress);
      setIsOrderReady(!_needs.needAddress);
      if (shipmentPrice === null) {
        handleCalculateBasket({
          products: _productsArray,
        }).then((_prices) => {
          setShipmentPrice(_prices?.total?.shipmentPrice);
        });
      }
      array = JSON.parse(localStorage.getItem("cart"));
      var totalDollar = 0;
      var totalKK = 0;
      array.map((item) => {
        totalDollar += parseFloat(item.price) * item.piece;
        totalKK += parseInt(item.karma) * item.piece;
      });
      setTotals({
        dollarPrice: totalDollar,
        karmaPrice: totalKK,
      });
      set_update(false);
    }
  }, [_update, cartItems]);

  React.useEffect(async () => {
    //get delivery info
    const infos = await handleGetDeliveryInfo();
    setUserAddress(infos?.address);
    setUserDocument(infos?.document);
    setUserCity(infos?.city);
    setUserNo(infos?.number);
    setUserPhone(infos?.phone);
    setUserPostalCode(infos?.postal_code);
    setShowEditProfile(false);
    if (
      !infos?.number ||
      !infos?.city ||
      !infos?.phone ||
      !infos?.document ||
      !infos?.postal_code ||
      !infos?.address
    ) {
      setShowEditProfile(true);
    }
  }, []);

  function cc_format(value) {
    //console.log(value);
    const temp = value.replace(/\W/gi, "").replace(/(\d{4})/g, "$1 ");
    if (value.length % 4 == 0) {
      return temp.slice(0, -1);
    }
    return temp;
  }
  // console.log(
  //   "Testing",
  //   (!!totals?.shipmentPrice || !!totals?.dollarPrice) &&
  //     (totals.shipmentPrice > 0 || totals.dollarPrice > 0)
  // );
  return (
    <div className="bg-bodyBg">
      <MainHeader search={true} />
      <div className="mx-auto px-4 sm:px-8 md:px-16 lg:px-48 flex flex-col lg:flex-row">
        <div className="w-full lg:w-8/12 flex flex-col">
          <div className="font-VolksMedium text-3xl mb-8 flex flex-row items-center justify-between">
            <div className="w-3/12 whitespace-nowrap">{t("cart.your_cart")}</div>
            <div className="border-t w-9/12 border-black"></div>
          </div>
          <div>{/* {totals?.totalDollar} - {totals?.totalKK} */}</div>

          {cartItems?.length
            ? cartItems.map((item) => {
              return (
                <CartItem
                  key={item.id}
                  item={item}
                  onUpdate={() => {
                    set_update(true);
                  }}
                  onDelete={() => {
                    onDelete(item);
                  }}
                />
              );
            })
            : t("cart.no_items")}
        </div>
        <div className="px-6 py-10 font-VolksMedium w-full lg:w-5/12 bg-primary mt-10 lg:mt-0 lg:ml-10 rounded-kkxl flex flex-col items-center justify-between text-white">
          <div className="mr-auto text-xl mb-4">{t("cart.your_wallet")}</div>
          <ProfileCard page={"cart"} />
          {!!cartItems?.length && (
            <>
              <div className="border-t-2 mt-6 mb-2 border-white w-full"></div>
              <div className="w-full flex flex-row justify-between"></div>
              <div className="w-full flex my-3 flex-row justify-between">
                <div className="text-xl">{t("cart.details")}</div>
              </div>
              {/* <div className="w-full flex my-3 flex-row justify-between">
                <div className="font-LatoRegular text-sm">Retrieval</div>
                <div className="font-LatoRegular text-sm font-semibold">
                  Onsite
                </div>
              </div> */}
              {parseInt(walletKK) < parseInt(totals?.karmaPrice) ? (
                <div className="w-full flex my-3 flex-row justify-between items-center">
                  <div className="text-red-400">
                    {t("cart.insufficient_karma")}
                  </div>
                </div>
              ) : (
                <>
                  {/* <div className="w-full flex my-3 flex-row justify-between items-center">
                    <div className="mr-auto mb-8">
                      {t("cart.make_transaction_private")}
                    </div>
                    <div className="mr-auto mb-4">
                      <input type="checkbox" />
                    </div>
                  </div> */}
                  {needs?.needAddress && (
                    <div className="px-4 text-white font-VolksMedium w-full bg-primary rounded-kkxl flex flex-col items-center justify-between">
                      <div className="mr-auto text-xl mb-4">
                        {t("general.my_data")}
                      </div>
                      <div className="flex flex-col justify-start mb-6 w-full">
                        {
                          showEditProfile ?
                            <div class="flex flex-col justify-start w-full mb-4">
                              {t("donation.incomplete_info")}
                              <button className={`btn-1 bg-secondary btn-effects text-primary font-VolksDemiBold text-base ml-auto mt-4`} onClick={() => history.push("/account-settings")}>{t("button.update_info")}</button>
                            </div>
                            :
                            <>
                              <div className="w-full">
                                <h4 className="font-LatoRegular text-sm">
                                  {t("your_profile.document")} : {userDocument}
                                </h4>
                              </div>
                              <div div className="w-full">
                                <h4 className="font-LatoRegular text-sm">
                                  {t("your_profile.phone")} : {userPhone}
                                </h4>
                              </div>
                              <div className="mr-auto text-xl mt-4 mb-4">
                                {t("donation.title_address")}
                              </div>
                              <div div className="w-full">
                                <h4 className="font-LatoRegular text-sm">
                                  {t("your_profile.pk")} : {userPostalCode}
                                </h4>
                              </div>
                              <div div className="w-full">
                                <h4 className="font-LatoRegular text-sm">
                                  {t("your_profile.address")} : {userAddress}
                                </h4>
                              </div>
                              <div div className="w-full">
                                <h4 className="font-LatoRegular text-sm">
                                  {t("your_profile.city")} : {userCity}
                                </h4>
                              </div>
                              <div div className="w-full">
                                <h4 className="font-LatoRegular text-sm">
                                  {t("your_profile.no")} : {userNo}
                                </h4>
                              </div>
                              <div className="flex flex-col justify-start w-full mb-4">
                                <button className={`btn-1 bg-secondary btn-effects text-primary font-VolksDemiBold text-base ml-auto mt-4`} onClick={() => history.push("/account-settings")}>{t("button.update_info")}</button>
                              </div>
                            </>
                        }</div>


                      <div className="w-full flex my-3 flex-row justify-between items-center">
                        <div className="mr-auto mb-8">
                          {t("cart.take_order_in_magikk")}
                        </div>
                        <div className="mr-auto mb-4 bg-gray-600 w-6 h-6 rounded-md border-success focus:border-blue-500 focus:outline-none cursor-pointer">
                          <input type="checkbox"
                            className={"w-6 h-6 "}
                            onChange={(e) => {
                              setIsTakeAway(e.target.checked);
                              setIsOrderReady(e.target.checked);
                              setAddressSaved(false);
                            }}
                            value={isTakeAway}
                            checked={isTakeAway}
                          />
                        </div>
                      </div>


                    </div>
                  )}



                </>
              )}
              {!!totals?.dollarPrice && (
                <div className="w-full flex my-3 flex-row justify-between items-center text-blue-400">
                  <div className="font-LatoRegular text-sm">
                    Total
                  </div>
                  <div className="flex flex-row font-VolksDemiBold text-xl text-white rounded-xl ml-auto text-blue-400">
                    <div className="ml-2">{"R$ "}</div>
                    {totals?.dollarPrice}
                  </div>
                </div>
              )}
              <div className="w-full flex my-3 flex-row justify-between items-center">
                <div className="font-LatoRegular text-sm">
                  Total karma
                </div>
                <div className="flex flex-row font-VolksDemiBold text-xl text-white rounded-xl ml-auto">
                  {totals?.karmaPrice}
                  <div className="mb-auto text-sm font-VolksMedium">
                    kk
                  </div>
                </div>
              </div>
              {(addressSaved && shipmentPrice > 0) && (
                <>
                  <div className="w-full flex flex-row justify-between items-center mb-8">
                    <div className="font-LatoRegular text-sm">
                      {t("cart.cargo_price")}{" "}
                    </div>

                    <div className="flex flex-row font-VolksDemiBold text-xl text-white rounded-xl ml-auto text-blue-400">
                      <div className="ml-2">{"R$"}</div>
                      {shipmentPrice}
                    </div>
                  </div>

                </>
              )}
              {((!!totals?.shipmentPrice && totals?.shipmentPrice > 0 && !isTakeAway) || (!!totals?.dollarPrice && totals?.dollarPrice > 0)) && (
                <>
                  <div
                    className="px-4 pb-10 mt-4 text-white font-VolksMedium w-full bg-primary rounded-kkxl flex flex-col items-center justify-between">
                    <div className="mr-auto text-xl mb-4">
                      {t("cart.card")}
                    </div>

                    <div className="flex flex-col justify-start mb-6 w-full">
                      <h4 className="font-LatoRegular text-sm">
                        {t("cart.card_name")}
                      </h4>
                      <input
                        className="input-classic w-full text-black pl-4"
                        onChange={(e) => {
                          const value = e.target.value;
                          setCreditCardFormState({
                            ...creditCardFormState,
                            holder_name: value,
                          });
                        }}
                        value={creditCardFormState.holder_name}
                        placeholder="John Doe"
                      />
                    </div>

                    <div className="flex flex-col justify-start mb-6 w-full">
                      <h4 className="font-LatoRegular text-sm">
                        {t("cart.card_number")}
                      </h4>
                      <input
                        className="input-classic w-full text-black pl-4"
                        onChange={(e) => {
                          const txt = e.target.value;
                          let text = txt.replace(/[^0-9]/g, "");
                          if (text.length < 17) {
                            text = cc_format(text);
                            setCreditCardFormState({
                              ...creditCardFormState,
                              number: text,
                            });
                          }
                        }}
                        value={creditCardFormState.number}
                        placeholder="1234 5678 1234 5678"
                      />
                    </div>
                    <div className="flex flex-row justify-between items-center">
                      <div className="flex flex-col justify-start mb-6 w-4/12">
                        <h4 className="font-LatoRegular text-sm whitespace-nowrap">
                          {t("cart.card_expire")}
                        </h4>
                        <input
                          className="input-classic w-full text-black pl-4"
                          onChange={(e) => {
                            var txt = e.target.value;
                            let txt1 = txt.replace(
                              /[^0-9\/]/g,
                              ""
                            );
                            if (
                              txt1.length === 2 &&
                              creditCardFormState?.date
                                ?.length < txt.length
                            ) {
                              txt1 = txt1 + "/";
                            }

                            if (txt1.length < 6) {
                              setCreditCardFormState({
                                ...creditCardFormState,
                                date: txt1,
                              });
                            }
                          }}
                          value={creditCardFormState?.date}
                          placeholder="01/12"
                        />
                      </div>
                      <div className="flex flex-col justify-start mb-6 w-4/12">
                        <h4 className="font-LatoRegular text-sm">
                          CVV
                        </h4>
                        <input
                          className="input-classic w-full text-black pl-4"
                          onChange={(e) => {
                            const txt = e.target.value;
                            let txt1 = txt.replace(
                              /[^0-9\/]/g,
                              ""
                            );

                            if (
                              txt1.length < 4 ||
                              txt1.length <
                              creditCardFormState.cvv.length
                            ) {
                              setCreditCardFormState({
                                ...creditCardFormState,
                                cvv: txt1,
                              });
                            }
                          }}
                          value={creditCardFormState.cvv}
                          placeholder="123"
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              {(!isTakeAway && !isOrderReady) ? (
                <div className="flex flex-col justify-start mb-6 w-full">
                  <div
                    className={`btn-1 ${loading ? "bg-white" : "bg-secondary"
                      } btn-effects text-primary font-VolksDemiBold text-base`}
                    onClick={() => {
                      if (userDocument.length == 11) {
                        handleUpdateDeliveryInfo({
                          name: profileData?.firstname,
                          email: profileData?.email,
                          document: userDocument,
                          address: userAddress,
                          phone: userPhone.replace(/[^0-9]/g, ''),
                          complement: "complemento",
                          number: userNo,
                          district: userCity,
                          city: userCity,
                          state_abbr: "BR",
                          country_id: "BR",
                          postal_code: userPostalCode,
                          note: "note",
                        })
                          .then(() => {
                            updateShipping();
                            dispatchCheckCurrentUser();
                          })
                          .catch((err) => {
                            if (err?.response?.data?.errors) {
                              err = err?.response?.data?.errors;

                              triggerToastr({
                                toastrTxt:
                                  err[Object.keys(err)[0]][0],
                                err: true,
                              });
                            }
                          });
                      } else {
                        triggerToastr({
                          toastrTxt:
                            t("toastr.cpf_lenght"),
                          title: t("toastr.invalid_cpf"),
                          err: true,
                        });
                      }
                    }}
                  >
                    {loading ? (
                      <img
                        className="h-full bg-transparent"
                        src={loaderGif}
                      />
                    ) : (
                      <span>
                        {'Continuar'}
                      </span>
                    )}
                  </div>
                </div>
              ) : (
                <div className="flex flex-col justify-start mb-6 w-full">
                  <div
                    className={`btn-1 ${loading ? "bg-white" : "bg-secondary"
                      } btn-effects text-primary font-VolksDemiBold text-base`}
                    onClick={
                      !loading
                        ? () => startCartPayment()
                        : () => console.log(loading)
                    }
                  >
                    {loading ? (
                      <img
                        className="h-full bg-transparent"
                        src={loaderGif}
                      />
                    ) : (
                      <span>{t("cart.place_order")}</span>
                    )}
                  </div>
                </div>
              )}

              {(isOrderReady && !needs?.needAddress) && (
                <div
                  className={`btn-1 btn-effects ${loading ? "bg-white" : "bg-secondary"
                    } text-primary font-VolksDemiBold text-base`}
                  onClick={
                    !loading
                      ? () => startCartPayment()
                      : () => console.log(loading)
                  }
                >
                  {loading ? (
                    <img
                      className="h-full bg-transparent"
                      src={loaderGif}
                    />
                  ) : (
                    <span>{t("cart.place_order")}</span>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

const mapState = ({ auth, profile }) => ({
  currentLang: auth.lang,
  walletKK: auth.user?.total_karma,
  profileData: profile.user || auth.user,
});

const mapDispatch = (dispatch) => ({
  dispatchSetToastrTxt: (_txt) => dispatch(setToastrTxt(_txt)),
  dispatchCheckCurrentUser: () => dispatch(checkCurrentUser()),
});

export default connect(mapState, mapDispatch)(Cart);
