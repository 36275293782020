import React from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { emailSignInStart } from "../../redux/Auth/auth.actions";
import { Redirect } from "react-router-dom";
import AuthHeader from "../../components/Auth/AuthHeader";
import RegisterMainSection from "../../components/Auth/Register/RegisterMainSection";
import Footer from "../../components/Footer";

export const Register = ({ dispatchEmailSignInStart, user }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // const onSubmit = (data) => {
  //   console.log(data);
  //   dispatchEmailSignInStart(data);
  // };

  if (user) {
    return <Redirect to="/" />;
  }

  document.title = "magikk - cadastro";

  return (
    <div>
      <AuthHeader />
      <RegisterMainSection />
      <Footer />
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchEmailSignInStart: (user) =>
    dispatch(emailSignInStart(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
