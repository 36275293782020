import React from "react";
import { connect } from "react-redux";
import RegisterForm from './RegisterForm';
import RegisterHero from './RegisterHero';

export const RegisterMainSection = (props) => {
  return (
    <div className="container mx-auto flex flex-col md:flex-row md:mt-12">
      <RegisterForm />
      <RegisterHero />
      <div></div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterMainSection);
