import React from "react";
import { connect } from "react-redux";
import MarketCategoryButton from "../Market/MarketCategoryButton";
import {
  startGetProducts,
  startGetCauses,
} from "../../redux/Market/market.actions";
import ProductCard from "../Market/ProductCard";
import CauseCard from "../Market/CauseCard";
import BePartOf from "../Market/BePartOf";

export const MarketBody = ({
  startGetProductsData,
  startGetCausesData,
  productsArray,
  causesArray,
  productsNextPageUrl,
  selectedCategoryData,
  selectedCategoryName,
  goodsOrCauses,
}) => {
  const [state, setState] = React.useState("start");

  React.useEffect(() => {
    if (state === "start") {
      startGetProductsData();
      setState("products");
    }
  }, []);

  React.useEffect(() => {
    if (state === "start") {
      startGetProductsData();
      setState("products");
    }
  }, [goodsOrCauses]);

  const PRODUCTS_DATA = productsArray;

  const CAUSES_DATA = causesArray

  const getGoodsOrCauses = () => {
    if (selectedCategoryData && selectedCategoryName) {
      if (goodsOrCauses === "goods" && selectedCategoryData && selectedCategoryData.length) {
        // setState('products')
        return selectedCategoryData?.map((data) => {
          return <ProductCard key={data.id} item={data} />;
        });
      } else if (goodsOrCauses === "causes" && selectedCategoryData && selectedCategoryData.length) {
        // setState('causes')
        return selectedCategoryData?.map((data) => {
          return <CauseCard key={data.id} item={data} />;
        });
      }
    }
  };

  return (
    <div className="bg-bodyBg p-8 rounded-tl-bodyxl rounded-tr-bodyxl">
      <div className="flex flex-row w-full items-center justify-center gap-x-12 mb-8">
        <MarketCategoryButton
          onClick={() => {
            startGetProductsData();
            setState("products");
          }}
          type="products"
          active={
            (state === "products" && !goodsOrCauses) ||
            goodsOrCauses === "goods"
          }
        />
        <MarketCategoryButton
          onClick={() => {
            startGetCausesData();
            setState("causes");
          }}
          type="causes"
          active={
            (state === "causes" && !goodsOrCauses) || goodsOrCauses === "causes"
          }
        />
      </div>
      <div className="grid grid-cols-4 container mx-auto">
        {goodsOrCauses
          ? getGoodsOrCauses()
          : state && state !== "causes"
          ? PRODUCTS_DATA?.length &&
            PRODUCTS_DATA.map((data) => {
              return <ProductCard key={data.id} item={data} />;
            })
          : state === "causes"
          ? CAUSES_DATA?.length &&
            CAUSES_DATA.map((data) => {
              return <CauseCard key={data.id} item={data} />;
            })
          : null}
      </div>

      <BePartOf />
    </div>
  );
};

const mapState = ({ market }) => ({
  causesArray: market.causesArray,
  productsArray: market.productsArray,
  causesNextPageUrl: market.causesNextPageUrl,
  productsNextPageUrl: market.productsNextPageUrl,
  productsPrevPageUrl: market.productsNextPageUrl,
  causesPrevPageUrl: market.causesNextPageUrl,
  selectedCategoryData: market.selectedCategoryData,
});

const mapDispatchToProps = (dispatch) => ({
  startGetProductsData: () => dispatch(startGetProducts()),
  startGetCausesData: () => dispatch(startGetCauses()),
});

export default connect(mapState, mapDispatchToProps)(MarketBody);
