import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import colors from "../../constants/colors";
import ShareButton from "../../components/Share/Button";
import { setToastrTxt } from "../../redux/Profile/profile.actions";
import {
  startFetchVolunteering,
  setVolunteeringMessage,
  startCancelVolunteer,
} from "../../redux/Market/market.actions";
import {
  AiOutlineClockCircle,
  AiOutlineLink,
  AiOutlineRight,
  AiOutlineCalendar,
} from "react-icons/ai";
import { IoLocationOutline } from "react-icons/io5";
import { checkVolState } from "../../redux/Market/market.helpers";
import { getQrCode } from "../../redux/Auth/auth.helpers";
import { GiEvilFork } from "react-icons/gi";
import moment from "moment";

export const CauseInfo = ({
  cause,
  id,
  user,
  dispatchSetToastrTxt,
  startVolunteer,
  volunteeringMessage,
  dispatchSetVolunteeringMessage,
  dispatchStartCancelVolunteer,
}) => {
  const { t, i18n } = useTranslation();

  const history = useHistory();
  const [buttonState, setButtonState] = useState("");
  const [qrSVG, setQrSVG] = useState("www");

  React.useEffect(async () => {
    // const currentState = await checkVolState(cause?.id);
    // setButtonState(currentState);
    document.title = `magikk - causa - ${cause?.title}`;
  }, [cause]);

  const checkVolunteer = async () => {
    await checkVolState(id)
      .then((data) => {
        // console.log("data", data);
        if (data === "confirmed") {
          setButtonState(data);
          setQrSVG(null);
        } else {
          setButtonState("attended");
          getQrCode().then((xmlQr) => {
            setQrSVG(xmlQr);
          });
        }

      })
      .catch((err) => {
        if (err === "NOT EXISTS" || err === "não existe") {
          setButtonState("available");
        } else if (err === "you are organization" || err === "você é uma organização") {
          setButtonState("organization");
        } else if (err === "Unauthenticated.") {
          // console.log("Unathenticated");
          setButtonState("available");
        }
        setQrSVG(null);
      });
  };

  React.useEffect(async () => {
    // console.log("User", user);
    // console.log("CauseId", cause);
    if (cause) {
      checkVolunteer();
    }
  }, [cause]);

  const volunteeringClick = () => {
    if (user) {
      startVolunteer(id);
      setTimeout(() => {
        checkVolunteer();
      }, 1000);
    } else {
      history.push("/login");
    }
  };

  const cancelClick = () => {
    dispatchStartCancelVolunteer({
      id: id,
      msg: {
        title: t("toastr.cancelled.title"),
        txt: t("toastr.cancelled.txt"),
      },
    });
    setTimeout(() => {
      checkVolunteer();
    }, 1000);
  };

  const latitude = cause?.address_latitude;
  const longitude = cause?.address_longitude;

  const organizationName =
    cause?.organization?.name ||
    cause?.user?.organization?.name ||
    `${cause?.user?.firstname} ${cause?.user?.lastname}`;

    const htmlCauseDescription = {__html:cause?.description};

  return (
    <div className="flex flex-col w-full md:w-5/12 justify-between pl-5 text-sm">
      <div className="flex flex-row">
        <div className="flex flex-col">
          <div className="font-VolksMedium text-3xl mb-2">
            {cause?.title}
          </div>
          <div className="font-LatoRegular mt-auto text-greyText underline">
            {organizationName}
          </div>
        </div>
      </div>
      <div className="border-t-2 my-2"></div>
      <div className="flex flex-col">
        <div className="font-VolksMedium text-xl mb-2">
          {t("cause.descr")}
        </div>
        <div className="font-LatoRegular" dangerouslySetInnerHTML={htmlCauseDescription} />
      </div>
      <div className="flex flex-row w-full justify-between my-4">
        {cause?.association && (
          <div className="w-5/12">
            <div className="font-VolksMedium text-xl mb-4">
              {t("cause.association")}
            </div>
            <div className="font-LatoRegular">
              {cause?.association.toString()}
            </div>
          </div>
        )}

        <div className="w-6/12">
          <div className="font-VolksMedium text-xl mb-4">
            {t("cause.informations")}
          </div>
          <div className="font-OpenSansRegular flex flex-col bg-borderGray rounded-ftxl p-4 justify-around">
            <div></div>
            <div className="flex flex-row mb-3">
              <AiOutlineCalendar size={21} />
              <div className="flex flex-col">
                <span className="ml-2">
                  {moment(cause?.start_date.split(" ")[0], "YYYY-MM-DD").format('DD/MM/YYYY')}
                </span>
                <span className="ml-2">
                  {moment(cause?.finish_date.split(" ")[0], "YYYY-MM-DD").format('DD/MM/YYYY')}
                </span>
              </div>
            </div>
            <div className="flex flex-row mb-3 underline text-blue-500 hover:text-black">
              <AiOutlineLink size={21} />
              <a
                className="ml-2    cursor-pointer"
                target="_blank"
                href={
                  cause?.user?.organization?.website || cause?.website
                }
              >
                web
              </a>
            </div>
            <a
              href={`https://maps.google.com/?q=${latitude},${longitude}`}
              target="_blank"
            >
              <div className="flex flex-row underline text-blue-500 hover:text-black">
                <IoLocationOutline size={21} />
                <span className="ml-2 cursor-pointer ">
                  {cause?.location}
                </span>
              </div>
            </a>
          </div>
          {/* <div className="mt-4 border rounded-xl">asdasdasd</div> */}
        </div>
        <div className="w-5/12 mb-6">
          {!!qrSVG && (
            <div className="flex flex-col bg-borderGray rounded-ftxl p-4 justify-around">
              <img 
                className="max-w-96"
                src={`data:image/svg+xml;utf8,${encodeURIComponent(
                  qrSVG 
                )}`} alt="QR Code"
              />
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-row w-full text-lg justify-end mt-auto font-LatoBold">
        {buttonState === "attended" && (
          <div
            className="btn-1 text-lg font-LatoRegular flex-row justify-between px-2 btn-effects bg-red-200"
            onClick={() => {
              cancelClick();
            }}
          >
            <span className="items-center justify-center flex flex-1 text-black">
              {t("cause.cancel")}
            </span>

          </div>
        )}
                {buttonState === "confirmed" && (
                  <div>{t("cause.you_earned_karma")}</div>
        )}
        {buttonState === "available" && (
          <div
            className="btn-1 text-lg font-LatoRegular bg-secondary text-primary flex-row justify-between px-2 btn-effects"
            onClick={() => {
              volunteeringClick();
            }}
          >
            {t("cause.volunteer")}
          </div>
        )}
        <div
          className="ml-6 btn-1 text-lg   font-LatoRegular flex-row justify-center px-2 btn-effects p-4"
          onClick={() => {
            history.push("/donation");
          }}
        >
          <span className="items-center justify-center flex flex-1">
            {t("cause.donation")}
          </span>
          <div className="bg-white p-1 rounded">
            <AiOutlineRight size={15} color={colors.theme} />
          </div>

        </div>
      </div>
      <ShareButton 
      buttons={['facebook', 'twitter', 'linkedin', 'whatsapp']} 
      title={`magikk - ${cause?.title}`} 
      page={`/cause-detail/${cause?.id}`} 
      summaryText={cause?.description}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchSetToastrTxt: (_txt) => dispatch(setToastrTxt(_txt)),
    startVolunteer: (id) => dispatch(startFetchVolunteering(id)),
    dispatchSetVolunteeringMessage: (tct) =>
      dispatch(setVolunteeringMessage(tct)),
    dispatchStartCancelVolunteer: (pyld) =>
      dispatch(startCancelVolunteer(pyld)),
  };
};

const mapStateToProps = ({ auth, market }) => ({
  user: auth.user,
  volunteeringMessage: market.volunteeringMessage,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CauseInfo);
