import React, { useState } from "react";
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    LinkedinShareButton,
    LinkedinIcon,
    WhatsappShareButton,
    WhatsappIcon,
} from "react-share";

import {RxClipboardCopy, RxCheckCircled} from 'react-icons/rx';

export const ShareButton = ({ title, page, buttons, summaryText }) => {

    const shareUrl = page ? `${process.env.REACT_APP_URI}` + page : `${process.env.REACT_APP_URI}`;
    const summary = summaryText ? summaryText : title;
    const [copied, setCopied] = useState(false);

    return (
        //cria a div e alinha os icones a direita
        <div className="flex flex-col pt-5">
            <div>
                <h3 className="text-lg font-bold text-right">Compartilhar</h3>
            </div>
            <div className="flex flex-row pt-2 justify-end">
                {buttons.includes('facebook') && (
                    <FacebookShareButton url={shareUrl} quote={title}>
                        <FacebookIcon size={32} round={true} />
                    </FacebookShareButton>
                )}
                {buttons.includes('twitter') && (
                    <TwitterShareButton url={shareUrl} title={title}>
                        <TwitterIcon size={32} round={true} />
                    </TwitterShareButton>
                )}
                {buttons.includes('linkedin') && (
                    <LinkedinShareButton url={shareUrl} title={title} summary={summary}>
                        <LinkedinIcon size={32} round={true} />
                    </LinkedinShareButton>
                )}
                {buttons.includes('whatsapp') && (
                    <WhatsappShareButton url={shareUrl} title={title}>
                        <WhatsappIcon size={32} round={true} />
                    </WhatsappShareButton>
                )}
                <button
                    className="flex flex-row justify-center items-center"
                    onClick={() => {
                        navigator.clipboard.writeText(shareUrl);
                        setCopied(true);
                        setTimeout(() => {
                            setCopied(false);
                        }, 2000);
                    }}
                >
                    {copied ? (
                        <RxCheckCircled size={32} />
                    ) : (
                        <RxClipboardCopy size={32} />
                    )}
                </button>
            </div>
        </div>
    )
}

export default ShareButton;