import React, { useState } from "react";
import { handleResetPassword } from "../../../redux/Market/market.helpers";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { setToastrTxt } from "../../../redux/Cart/cart.actions";

function MainSection({ dispatchSetToastrTxt }) {
  const { t, i18n } = useTranslation();

  const [email, setEmail] = useState("");

  const triggerToastr = ({ txt, err }) => {
    dispatchSetToastrTxt({
      title: t("toastr.forget_password"),
      txt: txt,
      err: err,
    });
    if (!err) {
      setEmail("");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }

    setTimeout(() => {
      dispatchSetToastrTxt();
    }, 3500);
  };

  const validateEmail = (value) => {
    return String(value)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
  };

  const clickSubmit = async () => {
    console.log("clickSubmit", email);
    if (!validateEmail(email)) {
      triggerToastr({ txt: t("forget_pass.enter_txt"), err: true });
    } else {
      const result = await handleResetPassword(email);
      if (result === "success") {
        triggerToastr({ txt: t("forget_pass.success") });
      } else {
        triggerToastr({ txt: t("forget_pass.failed"), err: true });
      }
    }
  };

  return (
      <div className="mx-auto container my-24">
        <div className="overflow-hidden responsive bg-primary h-64 rounded-ftxl flex flex-row items-center justify-around font-VolksDemiBold text-white mt-2">
          <h3 className="w-4/12 text-3xl">
            {t("forget_pass.forget_pass")}
          </h3>
          <div className="bg-paleSecondary w-4/12 h-8 h-16 rounded-2xl flex flex-row items-center pr-2 py-2 pl-6">
            <input
                placeholder={t("forget_pass.enter_email")}
                type="text"
                className="flex-1 ml-2 pl-2 font-LatoRegular placeholder-white bg-transparent"
                value={email}
                onChange={(e) => {
                  const value = e.target.value;
                  setEmail(value);
                }}
            />
            <div
                onClick={clickSubmit}
                className="px-4 ml-auto bg-white flex flex-row text-center flex items-center justify-center h-full text-black items-center rounded-xl btn-effects"
            >
            <span className="font-OpenSansRegular">
              {t("forget_pass.send_mail")}
            </span>
            </div>
          </div>
        </div>
      </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchSetToastrTxt: (_txt) => dispatch(setToastrTxt(_txt)),
  };
};

export default connect(null, mapDispatchToProps)(MainSection);
