import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { startGetCause } from "../../redux/Market/market.actions";
import marketTypes from "../../redux/Market/market.types";
import white_logo from "../../assets/white_logo/white.png";
import firebase from "firebase/compat/app";

function CauseCard({
  item,
  dispatchStartLoader,
  dispatchStartGetCause,
}) {
  const history = useHistory();
  var url = item?.images && item.images[0]?.url;
  // const image = { uri: url };
  // const pr = parseInt(item.price);
  const pr = item.amount + " ";

  const handleClick = () => {
    // dispatchStartGetCause(item.id);
    dispatchStartLoader();
    dispatchStartGetCause(item.id);
    // console.log("item", item);
    firebase.analytics().logEvent("clicou_visualizar_causa", {
      id: item.id,
      title: item.title,
    });
    history.push(`/cause-detail/${item.id}`);

  };
  const organizationName =
    item?.organization?.name ||
    item?.user?.organization?.name ||
    `${item?.user?.firstname} ${item?.user?.lastname}`;

  return (
    <div
      onClick={handleClick}
      className="flex flex-col  inline-flex rounded-kkxl overflow-hidden btn-effects"
    >
      {url ? (
        <img
          src={url}
          alt="url"
          style={{ objectFit: "cover" }}
          className="w-full h-auto"
        />
      ) : (
        <div className="flex items-center h-64 w-full justify-center">
          <img
            src={white_logo}
            alt="logo"
            style={{ filter: "grayscale(100%)" }}
          />
        </div>
      )}

      <div className="flex flex-row py-3 px-3 bg-white flex-grow">
        <div className="overflow-hidden text-left">
          <div className="font-VolksMedium overflow-hidden whitespace-nowrap ">
            {item.title}
          </div>
          <div className="text-greyText whitespace-nowrap">
            {/* {item?.user?.firstname} {item?.user?.lastname} */}
            {organizationName}
          </div>
        </div>
        {/* <div className="flex flex-row bg-secondary text-primary font-VolksMedium justify-center items-center w-12 h-12 rounded-xl px-8">
          <div className="mr-1 text-lg font-bold">Doe</div>
          <div className="mr-1 text-lg font-bold">{pr}</div>
          <div className="text-sm mb-4">
            {item.currency === "dollar" ? " R$" : " kk"}
          </div>
        </div> */}
      </div>
    </div>
  );
}

const mapDispatch = (dispatch) => ({
  dispatchStartGetCause: (id) => dispatch(startGetCause(id)),
  dispatchStartLoader: () =>
    dispatch({ type: marketTypes.START_LOADER }),
  dispatchStartGetCause: (_id) => dispatch(startGetCause(_id)),
});

export default connect(null, mapDispatch)(CauseCard);
