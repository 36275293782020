import React, { useEffect } from "react";
import { BsArrowDown } from "react-icons/bs";
import { connect } from "react-redux";
import colors from "../../constants/colors";
import {
  handleMakeTransfer,
  getUserSuggestions,
} from "../../redux/Profile/profile.helpers";
import { setToastrTxt } from "../../redux/Profile/profile.actions";
import { useTranslation } from "react-i18next";
import UserSearchItem from "./UserSearchItem";

function PublicProfileUser({ user }) {
  const { t, i18n } = useTranslation();
  return (
    <div className="flex flex-col px-4 pt-4 pb-4">
      <div className="font-VolksDemiBold text-xl mb-4">
        {user.firstname + " " + user.lastname}
      </div>
      {/* <div className="font-VolksDemiBold text-xl mb-2">Status</div>
      <div className="text-l mb-4 ml-4">{user.status}</div> */}
      {/* <div className="font-VolksDemiBold text-xl mb-2">Country</div> */}
      {user?.info?.country_id && (
        <div className="text-l mb-4 ml-4">{user.info?.country_id}</div>
      )}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchSetToastrTxt: (_txt) => dispatch(setToastrTxt(_txt)),
  };
};
export default connect(null, mapDispatchToProps)(PublicProfileUser);
