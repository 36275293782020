import React from "react";
import { connect } from "react-redux";

export const Gallery = ({ images }) => {
  const [image, setImage] = React.useState(0);

  images = images?.map((im) => {
    return im.url;
  });

  while (images?.length < 4) {
    images.push("");
  }

  const currentImage = images && images[image];

  // const source = {uri: product.}

  // where to comes traslation.json => look from fetch

  return (
    <div className="flex flex-col md:flex-row md:w-7/12 justify-around h-auto md:h-500px mb-10">
      <div className="w-full md:w-3/12 h-full flex flex-row md:flex-col">
        {[0, 1, 2].map((num) => {
          if (!images || !images[num]) {
            return null;
          }
          let activeClass = "opacity-25";

          if (image === num) {
            activeClass = "opacity-100";
          }
          return (
            <div
              key={num}
              className={`cursor-pointer w-1/3 md:w-full h-3/12  p-1 ${activeClass}`}
            >
              <img
                src={images[num]}
                alt="images"
                className="w-full rounded-xl h-auto object-cover"
                onClick={() => setImage(num)}
              />
            </div>
          );
        })}
      </div>
      <div className="w-full md:w-8/12 bg-white-500 h-auto md:h-full rounded-xl mt-4 md:mt-0">
        <img
          // onClick={() => localStorage.setItem("count", 0)}
          src={currentImage}
          alt="currentImage"
          className="w-full rounded-xl md:h-auto object-cover"
        />
      </div>
    </div>
  );

};

export default Gallery;
