import React from "react";
import { useHistory } from 'react-router-dom';
import img_roda from "../../assets/new-landing-page/roda.svg";
import img_voluntarios from "../../assets/new-landing-page/builder/cb0905ccafc45c6f77de9fd92a23561f303c756ce278e026af45c600c6493182.png";
import img_seta from "../../assets/new-landing-page/builder/cbff0545103ce20d7b59be7d9c2d40b6662ad602cc8acdd1616380a5f43ff650.svg";
// import "./index.css";


import "./tailwind.js";

export const Header = () => {
    const history = useHistory();
    return (
        <>
            <div className="relative w-full">
                <div className="flex flex-col w-full max-md:max-w-full">
                    <div className="absolute inset-0 z-10 bg-gradient-to-r from-transparent via-black opacity-80" style={{ backgroundImage: 'linear-gradient(76deg, rgba(0, 0, 0, 0.00) 7.42%, rgba(0, 0, 0, 0.20) 89.21%), linear-gradient(67deg, rgba(1, 65, 79, 0.85) 51.08%, rgba(2, 149, 181, 0.85) 79.11%)' }}></div>
                    <div className="flex gap-0 px-5 w-full max-md:flex-wrap max-md:max-w-full">
                        <div className="flex gap-0 text-sm font-[510] text-cyan-950 max-md:flex-wrap" />
                    </div>
                    <div className="flex flex-col flex-wrap justify-center content-center w-full max-md:max-w-full">
                        <div className="flex overflow-hidden relative flex-col justify-center items-center px-16 py-0.5 w-full min-h-[668px] max-md:px-5 max-md:max-w-full">
                            <img
                                loading="lazy"
                                srcSet={img_voluntarios}
                                className=" absolute inset-0 size-full object-cover"
                            />
                            <div className="flex relative z-10 flex-col justify-center px-8 py-20 w-full max-w-[1194px] max-md:px-5 max-md:max-w-full">
                                <div className="mt-8 mb-2.5 max-md:max-w-full">
                                    <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                                        <div className="flex flex-col w-[59%] max-md:ml-0 max-md:w-full">
                                            <div className="flex flex-col mt-10 max-md:mt-10 max-md:max-w-full">
                                                <div className="flex flex-col max-md:max-w-full">
                                                    <div className="flex flex-col text-4xl font-bold text-white max-md:max-w-full">
                                                        <div className="shrink-0 bg-slate-400 h-[3px] w-[63px]" />
                                                        <div className="mt-6 max-md:max-w-full font-thin">
                                                        <span className="text-blue-300">
                                                        Transforme 
                                                            </span> <br />
                                                            impacto em recompensas!
                                                            {" "}

                                                            {" "}
                                                        </div>
                                                    </div>
                                                    <div className="mt-4 text-lg leading-6 text-white max-md:max-w-full">
                                                    Cadastre-se na magikk, junte karmas e troque por produtos e experiências que promovem o bem.  
                                                    </div>
                                                </div>
                                                <div className="flex flex-col justify-center mt-11 max-w-full text-sm leading-5 text-white font-[5] w-[195px] h-[57px] max-md:mt-10">
                                                    <div className="flex gap-2.5 justify-center px-8 py-2.5 rounded-md bg-slate-400 max-md:px-5 hover:cursor-pointer"
                                                    onClick={() => {
                                                        history.push('/register');
                                                    }
                                                }
                                                    >
                                                        <div>Criar conta</div>
                                                        <img
                                                            loading="lazy"
                                                            src={img_seta}
                                                            className="shrink-0 my-auto w-3.5 aspect-square"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col ml-5 w-[41%] max-md:ml-0 max-md:w-full">
                                            <img
                                                loading="lazy"
                                                srcSet={img_roda}
                                                className="grow w-full max-md:mt-10 max-md:max-w-full"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}