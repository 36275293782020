import { all, call } from "redux-saga/effects";

import authSagas from "./Auth/auth.sagas";
import marketSagas from "./Market/market.sagas";
import profileSagas from "./Profile/profile.sagas";
import cartSagas from "./Cart/cart.sagas";

export default function* rootSaga() {
  yield all([
    call(authSagas),
    call(marketSagas),
    call(profileSagas),
    call(cartSagas),
  ]);
}
