import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import cardbg from "../../assets/login_hero.png";
import emblem from "../../assets/emblem.png";
// import { BASE_URL } from "../../config/env";
import fakeAvatar from "../../assets/avatar.jpg";


const ProfileCard = ({ user, page }) => {
  const sizeStyle = page === "cart" ? "w-full h-48" : "w-full md:w-1/3 rounded-ftxl h-auto md:h-64 py-7 md:my-0 my-5 px-5 ";

  const fullname = user?.firstname + " " + user?.lastname;

  const { t, i18n } = useTranslation();

  const avatar_url =
    !user?.avatar ||
    (user?.avatar && user.avatar.startsWith("magikk"))
      ? fakeAvatar
      : `${process.env.REACT_APP_API_URI}/${user?.avatar}`;
  const location = user?.location || user?.profile?.country;
  const balance = user?.total_karma || user?.wallet?.amount || "0";

  return (
    <div className={`bg-primaryHard ${sizeStyle} rounded-lg flex flex-col justify-between`}>
      <div className="flex flex-col justify-between flex-grow h-full py-4 sm:py-8 px-2 sm:px-6">
        {/* top container  */}
        <div className="justify-between flex flex-1 h-16 sm:h-20">
          <img
            className="w-12 h-12 sm:w-20 sm:h-20 rounded-full object-cover"
            src={avatar_url}
            alt="avatar_url"
          />
          <img className="w-8 h-8 sm:w-12 sm:h-12" src={emblem} alt="emblem"/>
        </div>
        {/* bottom container */}
        <div className="justify-between flex items-end">
          <div className="flex flex-col">
            <h3 className="font-LatoRegular text-secondary underline mb-1 sm:mb">
              {location}
            </h3>
            <h3 className="font-OpenSansRegular text-white text-sm sm:text-base">
              {fullname}
            </h3>
          </div>
          <div className="flex-col flex">
            <h3 className="font-OpenSansRegular text-white text-sm sm:text-lg mb-1 sm:mb-0 text-right">
              {t("profile.digital_wallet")}
            </h3>
            <div className="flex flex-row font-VolksDemiBold text-xl sm:text-4xl text-white rounded-xl ml-auto">
              {balance}
              <div className="mb-auto text-base font-VolksMedium">
                kk
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapState = ({ auth }) => ({
  user: auth.user,
});

export default connect(mapState)(ProfileCard);
