import {
  all,
  call,
  put,
  takeLatest,
  delay,
} from "redux-saga/effects";
import {
  setProducts,
  setCauses,
  setProduct,
  setCause,
  setAllGoals,
  setCategories,
  setSelectedCategoryData,
  setSearchData,
  setVolunteeringMessage,
  finishAddProducts,
  finishAddCauses,
} from "./market.actions";

import { setToastrTxt } from "../Cart/cart.actions";

import marketTypes from "./market.types";
import {
  handleGetProducts,
  handleGetProductDetails,
  handleGetCauseDetails,
  handleGetAllGoals,
  handleGetCauses,
  handleGetCategories,
  handleGetSelectedCategoryData,
  handleGetSearchData,
  handleVolunteering,
  handleCancelVolunteer,
  handleAddProductsAndCauses,
} from "./market.helpers";

// products

function* startGetProducts() {
  try {
    const data = yield handleGetProducts();

    if (data) {
      //console.log('get all products : ', data); // product obj
      yield put(setProducts(data));
    } else {
      //console.log('no data 003');
    }
  } catch (err) {
    //console.log('handle products error', err);
  }
}

export function* onStartGetProducts() {
  yield takeLatest(marketTypes.START_GET_PRODUCTS, startGetProducts);
}

// products

function* startAddProducts({ payload }) {
  try {
    const data = yield handleAddProductsAndCauses(payload);

    if (data) {
      //console.log('Add all products : ', data); // product obj
      yield put(finishAddProducts(data));
    } else {
      yield put(
        setToastrTxt({
          title: "Product",
          txt: "No Data Found",
          err: true,
        })
      );
      yield delay(2000);
      yield put(setToastrTxt());
      //alert('no data error. startAddProducts saga');
    }
  } catch (err) {
    yield put(
      setToastrTxt({
        title: "Product",
        txt: "handle products error",
        err: true,
      })
    );
    yield delay(2000);
    yield put(setToastrTxt());

    //console.log('handle products error', err);
  }
}

function* startAddCauses({ payload }) {
  try {
    const data = yield handleAddProductsAndCauses(payload);

    if (data) {
      //console.log('Add all products : ', data); // product obj
      yield put(finishAddCauses(data));
    } else {
      yield put(
        setToastrTxt({
          title: "Product",
          txt: "No Data Found",
          err: true,
        })
      );
      yield delay(2000);
      yield put(setToastrTxt());
      //alert('no data error. startAddProducts saga');
    }
  } catch (err) {
    yield put(
      setToastrTxt({
        title: "Product",
        txt: "handle products error",
        err: true,
      })
    );
    yield delay(2000);
    yield put(setToastrTxt());

    //console.log('handle products error', err);
  }
}

export function* onStartAddProducts() {
  yield takeLatest(marketTypes.START_ADD_PRODUCTS, startAddProducts);
}

export function* onStartAddCauses() {
  yield takeLatest(marketTypes.START_ADD_CAUSES, startAddCauses);
}
// causes

function* startGetCauses() {
  try {
    const data = yield handleGetCauses();

    if (data) {
      //console.log('causes data...', data); //
      yield put(setCauses(data));
    } else {
      // alert("no data 004");
      //console.log('get causes error');
    }
  } catch (err) {
    //console.log('handle causes error', err);
  }
}

export function* onStartGetCauses() {
  yield takeLatest(marketTypes.START_GET_CAUSES, startGetCauses);
}

// one product

function* startGetProduct({ payload }) {
  try {
    const data = yield handleGetProductDetails(payload);

    if (data) {
      //console.log('product data...', data); // product obj
      yield put(setProduct(data));
    } else {
      //console.log('error: Product data error ... no data');
    }
  } catch (err) {
    //console.log('handle product error', err);
  }
}

export function* onStartGetProduct() {
  yield takeLatest(marketTypes.START_GET_PRODUCT, startGetProduct);
}

// one cause

function* startGetCause({ payload }) {
  try {
    const data = yield handleGetCauseDetails(payload);

    if (data) {
      //console.log('get one cause data...', data); // product obj
      yield put(setCause(data));
    } else {
      // alert("no data 004");
      //console.log('cause data error');
    }
  } catch (err) {
    //console.log('handle cause error', err);
  }
}

export function* onStartGetCause() {
  yield takeLatest(marketTypes.START_GET_CAUSE, startGetCause);
}

// all goals

function* startGetAllGoals() {
  try {
    const data = yield handleGetAllGoals();

    if (data) {
      //console.log('get all goals..', data); //
      yield put(setAllGoals(data));
    } else {
      // alert("no data 004");
      //console.log('all causes get error');
    }
  } catch (err) {
    //console.log('handle startGetAllGoals error', err);
  }
}

export function* onStartGetAllGoals() {
  yield takeLatest(marketTypes.START_GET_ALL_GOALS, startGetAllGoals);
}

//
// categories

function* startGetCategories() {
  try {
    const data = yield handleGetCategories();

    if (data) {
      //console.log('categories data...', data); // good obj
      yield put(setCategories(data));
    } else {
      //console.log(' categories get error');
    }
  } catch (err) {
    //console.log('handle categories error', err);
  }
}

export function* onStartGetCategories() {
  yield takeLatest(
    marketTypes.START_GET_CATEGORIES,
    startGetCategories
  );
}

// selected category data

function* startGetSelectedCategoryData({ payload }) {
  try {
    //console.log('saga type : ', payload.type);
    const data = yield handleGetSelectedCategoryData(payload);

    if (data) {
      //console.log('category data...', data); // good obj
      yield put(setSelectedCategoryData(data));
    } else {
      //console.log('error : no selected category data ');
    }
  } catch (err) {
    //console.log('handle category error', err);
  }
}

export function* onStartGetSelectedCategoryData() {
  yield takeLatest(
    marketTypes.START_GET_SELECTED_CATEGORY_DATA,
    startGetSelectedCategoryData
  );
}

// GET SEARCH DATA

function* startGetSearchData({ payload }) {
  try {
    const data = yield handleGetSearchData(payload);

    if (data) {
      //console.log('goods search data...', data); // good obj
      yield put(setSearchData(data));
    } else {
      //console.log(' error : no data search');
    }
  } catch (err) {
    //console.log('handle search error', err);
  }
}

export function* onStartGetSearchData() {
  yield takeLatest(
    marketTypes.START_GET_SEARCH_DATA,
    startGetSearchData
  );
}

// volunteering

function* startVolunteering({ payload }) {
  try {
    const data = yield handleVolunteering(payload);

    if (data) {
      yield put(setVolunteeringMessage(data));
    } else {
      yield put(
        setToastrTxt({
          title: "Volunteer",
          txt: "Error while volunteering",
          err: true,
        })
      );
      yield delay(2000);
      yield put(setToastrTxt());
      //alert('Error on volunteering');
    }
  } catch (err) {
    //console.log('be volunteer err', err);
  }
}

export function* onStartVolunteering() {
  yield takeLatest(marketTypes.START_VOLUNTEERING, startVolunteering);
}

// cancel volunteer

function* startCancelVolunteer({ payload }) {
  try {
    const data = yield handleCancelVolunteer(payload.id);

    //console.log(' cancelVolState ... ... ...     ', data);
    if (data) {
      yield put(setToastrTxt(payload.msg));
      yield delay(3000);
      yield put(setToastrTxt());
    } else {
      yield put(
        setToastrTxt({
          title: "Volunteer",
          txt: "Error while cancelling",
          err: true,
        })
      );
      yield delay(2000);
      yield put(setToastrTxt());
      //alert('Error');
    }
  } catch (err) {
    //console.log('start cancel volunteer error ...  : ', err);
    yield put(
      setToastrTxt({
        title: "Volunteer",
        txt: "Error while cancelling",
        err: true,
      })
    );
    yield delay(2000);
    yield put(setToastrTxt());
    //alert('Error');
  }
}

export function* onStartCancelVolunteer() {
  yield takeLatest(
    marketTypes.START_CANCEL_VOLUNTEER,
    startCancelVolunteer
  );
}

export default function* marketSagas() {
  yield all([
    call(onStartGetProducts),
    call(onStartGetCauses),
    call(onStartGetProduct),
    call(onStartAddProducts),
    call(onStartGetCause),
    call(onStartGetAllGoals),
    call(onStartGetCategories),
    call(onStartGetSelectedCategoryData),
    call(onStartGetSearchData),
    call(onStartVolunteering),
    call(onStartCancelVolunteer),
    call(onStartAddCauses),
  ]);
}
