import React from "react";
import { connect } from "react-redux";
import { AuthHeader } from "../../components/Auth/AuthHeader";
import MainHeader from "../../components/MainHeader";
import OurCommunity from "../../components/Static/OurCommunity";
import CompanySolutionStatic from "../../components/Static/CompanySolutionStatic";
import { Footer } from "../../components/Footer";
import lets_go from "../../assets/lets_go.png";
//
import dvaz from "../../assets/companies/dvaz.png";
import greenpeople from "../../assets/companies/greenpeople.png";
import layla from "../../assets/companies/layla.png";
import maika from "../../assets/companies/maika.png";
import techset from "../../assets/companies/techset.png";

//
import company_hero from "../../assets/company_hero.png";
import { IoDocumentTextOutline } from "react-icons/io5";
import { FaUserCheck } from "react-icons/fa";
import { AiFillHeart } from "react-icons/ai";
import { BiChevronRight } from "react-icons/bi";
import colors from "../../constants/colors";
import { Redirect, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const IamCompany = ({ user }) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  
  document.title = "magikk - empresa";

  return (
    <div className="bg-bodyBg">
      {user ? <MainHeader /> : <AuthHeader />}
      <div className="container mx-auto flex flex-col md:flex-row mt-12 pb-12">
        <div className="w-full md:w-6/12 flex flex-col items-start justify-center p-4 md:p-8 md:mr-8 mb-4 md:mb-0">
          <h3 className="font-VolksMedium text-4xl mb-4 leading-relaxed">
            {t("i_am_company.first_header")}
          </h3>
          <p className="font-LatoRegular font-bold my-4 text-grey">
            {t("i_am_company.second_header")}
          </p>
          <p className="font-LatoRegular text-sm text-grey mt-4 mb-2">
            {t("i_am_company.first_text")}
          </p>
          <div
            onClick={() => {
              history.push("register");
            }}
            className="btn-1 btn-effects mt-12 py-2 pr-auto self-start"
          >
            be magikk
          </div>
        </div>

        <div className="w-full md:w-6/12 flex flex-row justify-between items-center">
          <img src={company_hero} className="w-full md:w-9/12 rounded-kkxl shadow-md" alt="company hero"/>
          <div className="hidden md:flex w-3/12 h-full items-center justify-center">
            <div className="w-full h-96 bg-primary rounded-r-kkxl"></div>
          </div>
        </div>
      </div>
      <div className="mt-16 px-8 md:px-48 flex flex-wrap justify-around">
        <img className="w-24 object-contain" src={dvaz} alt="dvaz"/>
        <img className="w-24 object-contain" src={greenpeople} alt="greenpeople"/>
        <img className="w-24 object-contain" src={layla} alt="layla"/>
        <img className="w-28 object-contain" src={maika} alt="maika"/>
        <img className="w-24 object-contain" src={techset} alt="techset"/>
      </div>
      <div className="bg-bodyBg pt-16 mt-16 mb-16">
        <div className="flex flex-col md:flex-row items-center container mx-auto md:mr-24 mt-12">
          <div className="w-4/12 px-12 flex flex-col items-start justify-around mr-24">
            <h4 className="text-sm mb-4">
              {t("i_am_company.third_header")}
            </h4>
            <h4 className="font-VolksDemiBold mb-4 text-4xl leading-relaxed">
              {t("i_am_company.fourth_header")}
            </h4>
            <p className="font-LatoRegular text-base text-grey">
              {t("i_am_company.second_text")}
            </p>
          </div>
          <div className="flex flex-col md:flex-row">
            <div className="mb-4 md:mr-8 md:w-1/2">
              <div className="flex flex-col justify-around h-64 p-8 mb-8 bg-white shadow-md rounded-ftxl">
                <div className="rounded-full bg-primary flex items-center justify-center w-20 h-20">
                  <IoDocumentTextOutline color={"white"} size={24} />
                </div>
                <div className="font-VolksDemiBold ">sign up</div>
                <div className="text-xs font-LatoRegular">
                  Fill out's company forms and choose your sustainable development goals
                </div>
              </div>
              <div className="flex flex-col justify-around h-64 p-8 mb-4 bg-white shadow-md rounded-ftxl">
                <div className="rounded-full bg-primary flex items-center justify-center w-20 h-20">
                  <AiFillHeart color={"white"} size={24} />
                </div>
                <div className="font-VolksDemiBold ">be magikk</div>
                <div className="text-xs font-LatoRegular">
                  Fill out's company forms and choose your sustainable development goals
                </div>
              </div>
            </div>
            <div className="mt-4 md:ml-8 md:w-1/2">
              <div className="flex flex-col justify-around h-64 p-8 mb-8 bg-white shadow-md rounded-ftxl">
                <div className="rounded-full bg-primary flex items-center justify-center w-20 h-20">
                  <FaUserCheck color={"white"} size={24} />
                </div>
                <div className="font-VolksDemiBold ">magikk review</div>
                <div className="text-xs font-LatoRegular">
                  Fill out's company forms and choose your sustainable development goals
                </div>
              </div>
              <div className="flex flex-col justify-around h-64 p-8 mb-4 bg-primary shadow-md rounded-ftxl">
                <div className="ml-auto flex items-center justify-center w-20 h-20">
                  <img src={lets_go} className="w-24 h-24" alt="lets go"/>
                </div>
                <div className="font-VolksDemiBold text-white">Let's go</div>
                <div className="text-xs font-LatoRegular text-white w-4/5">
                  Fill out's company forms and choose your sustainable development goals
                </div>
                <div
                  className="bg-white rounded-md btn-effects w-8 h-8 lg:w-10 lg:h-10 flex items-center justify-center"
                  onClick={() => {
                    history.push("/login");
                  }}
                >
                  <BiChevronRight color={colors.theme} size={21} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <DiscoverMarket /> */}
        <CompanySolutionStatic />
        <div className="w-full bg-white">
          <OurCommunity />
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IamCompany);
