import React from "react";

import img_barra_curva from "../../assets/new-landing-page/barra_curva.svg";
import img_mao_logo from "../../assets/new-landing-page/builder/0c8d7f2cafb01093d82e88bfdd6c69cbdab70f8c5b1619954a9071cdbf6e58bb.png";


import "./tailwind.js";

export const KarmaCycle = () => {
    return (
        <>
            <div className={`flex flex-col items-center w-full bg-[url(${img_barra_curva})] bg-no-repeat bg-cover bg-bottom `}>
                <div className={`z-10 self-center px-5 mt-12 w-full max-w-[1176px] max-md:mt-10 max-md:max-w-full`}>
                    <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                        <div className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
                            <div className="flex flex-col grow p-8 w-full shadow-lg bg-white bg-opacity-90 rounded-[32px] max-md:px-5 max-md:mt-5">
                                <img
                                    loading="lazy"
                                    srcSet={img_mao_logo}
                                    className="aspect-[1.12] w-[47px]"
                                />
                                <div className="flex flex-col mt-6">
                                    <div className="text-xl font-[5] text-cyan-950">
                                        G<span className="text-cyan-950">anhe </span>
                                        <span className="text-cyan-950">karma</span>
                                    </div>
                                    <div className="mt-4 text-sm leading-5 text-gray-900 text-opacity-50">
                                        Se voluntarie ou doe para projetos sociais de alta
                                        credibilidade apoiados pela magikk e ainda ganhe karmas no
                                        nosso app. R$ 3 = 3 karmas
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
                            <div className="flex flex-col grow p-8 w-full shadow-lg bg-white bg-opacity-90 rounded-[32px] max-md:px-5 max-md:mt-5">
                                <img
                                    loading="lazy"
                                    srcSet={img_mao_logo}
                                    className="aspect-[1.12] w-[47px]"
                                />
                                <div className="flex flex-col pb-4 mt-6">
                                    <div className="text-xl font-[5] text-cyan-950">
                                        Troque <span className="text-cyan-950">karma</span>
                                    </div>
                                    <div className="mt-4 text-sm leading-5 text-gray-900 text-opacity-50">
                                        Ganhe bens e serviços únicos de marcas incríveis na nossa loja
                                        de recompensas
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
                            <div className="flex flex-col grow px-8 py-8 w-full shadow-lg bg-white bg-opacity-90 rounded-[32px] max-md:px-5 max-md:mt-5">
                                <img
                                    loading="lazy"
                                    srcSet={img_mao_logo}
                                    className="aspect-[1.12] w-[47px]"
                                />
                                <div className="flex flex-col mt-6">
                                    <div className="text-xl font-[5] text-cyan-950">
                                        Distribua <span className="text-cyan-950">karma</span>
                                    </div>
                                    <div className="mt-4 text-sm leading-5 text-gray-900 text-opacity-50">
                                        Inove no reconhecimento profissional: distribua karmas como
                                        recompensa, promovendo engajamento e fortalecendo a cultura da
                                        empresa.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}