import React from 'react';
import colors from '../../constants/colors';
import PriceSet from '../Shared/PriceSet';
import { AiTwotoneHeart } from 'react-icons/ai';
import helpers from '../../helpers/textFormat.js';
import { useTranslation } from "react-i18next";

function CauseUserProfileItem({ item }) {
  const { t } = useTranslation();
  const activity_string = item?.triggerable?.activity_string;
  //const description = item?.triggerable?.description;
  const amount = item?.amount;
  const date = new Date(item?.created_at);

  //const sender = item?.triggerable?.sender;
  //const receiver = item?.triggerable?.receiver;

  //const me = item?.user_id;

  //const side = me == receiver.id ? 1 : -1;

  //const text = amount + ' karmas';

  return (
    <div>
      <div className="shadow-md mb-1 rounded-ftxl overflow-hidden">
        {
          <div className="flex flex-col bg-white font-LatoRegular justify-between p-4">
            <div className="flex flex-row bg-white w-full items-center justify-start">
              {/* <div className="w-24 items-center justify-center flex h-24 ml-4"> */}
              <div className="p-4 pl-0 pr-8 w-24 h-24  ml-8 flex ">
                <div className="w-full h-full flex items-center justify-center  relative aspect-square flex bg-secondary rounded-xl">
                  <AiTwotoneHeart
                    color={colors.theme}
                    size={24}
                    className=""
                  />{' '}
                </div>
              </div>
              {/* </div> */}
              <div className="ml-4  mr-auto">
                <h4 className="font-bold underline font-VolksMedium">
                  {activity_string}
                </h4>
                <span className="text-sm font-normal mr-4 text-gray-500">
                {t("historic.at")}:
                </span>
                <span className="font-LatoRegular text-greyText ">
                  {helpers.dateFormat(date, true)}
                </span>
              </div>
              <PriceSet karma={amount} />
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default CauseUserProfileItem;
