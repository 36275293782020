import React from "react";
import MainHeader from "../components/MainHeader";
import { connect } from "react-redux";
import Footer from "../components/Footer";
import { startCartPayment } from "../redux/Cart/cart.actions";
import pattern_hero from "../assets/pattern_hero.png";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import firebase from "firebase/compat/app";

import ProfileCard from "../components/Profile/ProfileCard";

function DonationSuccess({ cause, cartPayment, profileData }) {
  cause = cause || {};
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const totals = history.location?.state?.data;
  React.useEffect(() => {

    document.title = `magikk - ${t("donation.success")}`;


    firebase.analytics().logEvent('purchase', {
      karma_amount: totals?.karma_amount,
      price_amount: totals?.price_amount,
      email: profileData?.email || '',
      name: profileData?.firstname+' '+profileData?.lastname || '',
      id: profileData?.id || '',
    });

  }, []);

  // console.log("CAUSE", cause);
  // console.log("CART_PAYMENT", cartPayment);

  const imageSource = cause?.images?.length && cause?.images[0].url;
  profileData.total_karma += totals?.karma_amount;
  return (
    <div className="bg-bodyBg">
      <MainHeader search={true} />
      <div className="mx-auto md:px-8 sm:px-4 flex flex-col sm:flex-row">
  <div className="md:w-8/12 sm:w-full flex flex-col p-5">
    <div className="font-VolksMedium text-3xl md:text-3xl mb-8 flex flex-row items-center justify-between">
      <div className="w-3/12 whitespace-nowrap">
        {t("donation.donation")}
      </div>
      <div className="border-t w-9/12 border-black"></div>
    </div>
    <div>
      <div
        className="bg-no-repeat bg-center bg-primary w-full rounded-ftxl p-4 pb-12 sm:h-auto"
        style={{ backgroundImage: `url(${pattern_hero})` }}
      >
        <h4 className="text-3xl font-VolksDemiBold text-white md:mx-24 text-center leading-relaxed my-8">
          {t("donation.success_message")}
        </h4>
      </div>
    </div>
  </div>
  <div className="px-4 sm:px-6 py-10 font-VolksMedium w-full sm:w-5/12 bg-secondary ml-0 sm:ml-10 rounded-kkxl flex flex-col items-center justify-between text-white">
    <div className="sm:mr-auto text-xl mb-4 text-bodyBg">
      {t("cart.your_wallet")}
    </div>

    <ProfileCard page={"cart"} />
    <div className="border-t-2 mt-6 mb-2 border-white w-full"></div>
    <div className="w-full flex flex-row justify-between"></div>
    <div className="w-full flex my-3 flex-row justify-between">
      <div className="text-xl">
        {t("donation.success_message2")}
      </div>
    </div>
    {totals?.price_amount && (
      <div className="w-full flex my-3 flex-row justify-between items-center text-blue-400">
        <div className="font-LatoRegular text-sm">Total</div>
        <div className="flex flex-row font-VolksDemiBold text-xl text-white rounded-xl ml-auto text-blue-400">
          <div className="ml-2">{"R$ "} {totals?.price_amount}</div>
        </div>
      </div>
    )}
    {totals?.karma_amount && (
      <div className="w-full flex my-3 flex-row justify-between items-center text-blue-400">
        <div className="font-LatoRegular text-sm">
          {t("wallet.total_karma")}
        </div>
        <div className="flex flex-row font-VolksDemiBold text-xl text-white rounded-xl ml-auto text-blue-400">
          {totals?.karma_amount}
          <div className="ml-2">{" kk"}</div>
        </div>
      </div>
    )}
    <div
      className={`btn-1 btn-effects text-white font-VolksDemiBold whitespace-nowrap p-4 w-9/12 mt-8`}
      onClick={() => history.push("/")}
    >
      {t("payment_success.back_to_magikk")}
    </div>
  </div>
</div>

      <Footer />
    </div>
  );
}

const mapState = ({ market, profile, auth }) => ({
  cause: market.selectedCause,
  profileData: profile.user || auth.user,
});

const mapDispatch = (dispatch) => ({
  cartPayment: (data) => dispatch(startCartPayment(data)),
});

export default connect(mapState)(DonationSuccess);
