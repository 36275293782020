import React from 'react'
import { useTranslation } from "react-i18next";
import softProd from '../../assets/market-body/Calque_1/Calque_1.png';
import darkProd from '../../assets/market-body/darkProd/darkProd.png';
import softCause from '../../assets/market-body/softCause/softCause.png';
import darkCause from '../../assets/market-body/volunteer/volunteer.png';


function MarketCategoryButton({type, active, onClick}) {
const { t, i18n } = useTranslation();

    const theName = type === 'products' ? t("market.products") : t("market.causes");
    const prodIcon = active ? softProd : darkProd;
    const causeIcon = active ? softCause : darkCause;
    const bg = active ? 'bg-primary' : 'bg-secondary';
    const textColor = active ? 'text-secondary' : 'text-primary';
    const icon = type === 'products' ? prodIcon : causeIcon;
    return (
        <div onClick={onClick} className={`bg-primary w-full md:w-1/4 h-14 rounded-full flex flex-row items-center justify-center btn-effects ${bg}`}>
            <div className={`mr-4 md:text-base text-sm font-VolksMedium ${textColor}`}>{theName}</div>
            <img src={icon} alt="icon" className="w-8" />
        </div>
    )
}

export default MarketCategoryButton
