import {
  IoPeopleCircleOutline,
  IoSchoolSharp,
  IoTransgender,
  IoWaterOutline,
  IoInfinite,
} from "react-icons/io5";
import {
  GiHotMeal,
  GiRollingEnergy,
  GiFlatfish,
  GiIsland,
  GiPeaceDove,
} from "react-icons/gi";
import { BsGraphUp } from "react-icons/bs";
import { FaHeartbeat, FaCubes, FaEquals, FaCity } from "react-icons/fa";
import { CgCodeClimate } from "react-icons/cg";

import { AiOutlineDeploymentUnit } from "react-icons/ai";

const itemSettings = {
  1: {
    color: "#e51f3a",
    icon: <IoPeopleCircleOutline size={28} color={"white"} />,
  },
  2: {
    color: "#e0ad46",
    icon: <GiHotMeal size={28} color={"white"} />,
  },
  3: {
    color: "#bd1a2e",
    
    icon: <IoSchoolSharp size={28} color={"white"} />,
  },
  // good health
  5: {
    color: "#3a923c",
    icon: <FaHeartbeat size={28} color={"white"} />,
  },
  // gender equality
  6: {
    color: "#ff3533",
    icon: <IoTransgender size={28} color={"white"} />,
  },
  // Clean water and sanitation
  7: {
    color: "#13b1d8",
    icon: <IoWaterOutline size={28} color={"white"} />,
  },
  // renewable energy
  8: {
    color: "#F9BB3C",
    icon: <GiRollingEnergy size={28} color={"white"} />,
  },
  9: {
    color: "#91193A",
    icon: <BsGraphUp size={28} color={"white"} />,
  },
  10: {
    color: "#FF5E36",
    icon: <FaCubes size={28} color={"white"} />,
  },
  11: {
    color: "#D5195E",
    icon: <FaEquals size={28} color={"white"} />,
  },
  12: {
    color: "#FF923D",
    icon: <FaCity size={28} color={"white"} />,
  },

  13: {
    color: "#C18C3B",
    icon: <IoInfinite size={28} color={"white"} />,
  },
  14: {
    color: "#31723F",
    icon: <CgCodeClimate size={28} color={"white"} />,
  },
  15: {
    color: "#008ACA",
    icon: <GiFlatfish size={28} color={"white"} />,
  },
  16: {
    color: "#42B73E",
    icon: <GiIsland size={28} color={"white"} />,
  },

  17: {
    color: "#005B8E",
    icon: <GiPeaceDove size={28} color={"white"} />,
  },
  18: {
    color: "#163F5D",
    icon: <AiOutlineDeploymentUnit size={28} color={"white"} />,
  },
};

export const getItemSettings = (id) => {
  let stringId = id.toString();
  return (
    itemSettings[stringId] || {
      color: "blue",
      icon: <GiHotMeal size={28} color={"white"} />,
    }
  );
};
