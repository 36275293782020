import marketTypes from "./market.types";

const INITIAL_STATE = {
  loading: false,
  // user
  lang: "tr",
  successTransferModal: false,
};

const marketReducer = (state = INITIAL_STATE, action) => {
  // console.log(action.type, action.payload);
  switch (action.type) {
    case marketTypes.START_LOADER:
    case marketTypes.START_GET_CAUSES:
    case marketTypes.START_GET_PRODUCTS:
    case marketTypes.START_GET_PRODUCT:
    case marketTypes.START_GET_CAUSE:
    case marketTypes.START_GET_ALL_GOALS:
    case marketTypes.START_GET_CATEGORIES:
    case marketTypes.START_GET_SELECTED_CATEGORY_DATA:
    case marketTypes.START_GET_SEARCH_DATA:
      return {
        ...state,
        loading: true,
      };
    case marketTypes.SET_VOLUNTEERING_MESSAGE:
      return {
        ...state,
        volunteeringMessage: { title: action.payload },
        loading: false,
      };
    case marketTypes.SET_SEARCH_DATA:
      return {
        ...state,
        search: action.payload,
        loading: false,
      };
    case marketTypes.SET_SELECTED_CATEGORY_DATA:
      return {
        ...state,
        selectedCategoryData: action.payload,
        loading: false,
      };
    case marketTypes.SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
        loading: false,
      };
    case marketTypes.SET_PRODUCT:
      return {
        ...state,
        loading: false,
        selectedProduct: action.payload,
      };
    case marketTypes.SET_CAUSE:
      return {
        ...state,
        loading: false,
        selectedCause: action.payload,
      };
    case marketTypes.SET_ALL_GOALS:
      return {
        ...state,
        loading: false,
        allGoals: action.payload,
      };
    case marketTypes.SET_PRODUCTS:
      return {
        ...state,
        productsArray: action.payload.data,
        productsNextPageUrl: action.payload.next_page_url,
        productsPrevPageUrl: action.payload.prev_page_url,
        search: false,
        loading: false,
      };
    case marketTypes.FINISH_ADD_PRODUCTS:
      let _array = state.productsArray;
      // console.log(" array length on state : ", _array.length);
      const newArray = _array.concat(action.payload.data);
      // console.log(" data     ", newArray.length);

      return {
        ...state,
        productsArray: newArray,
        productsNextPageUrl: action.payload.next_page_url,
        productsPrevPageUrl: action.payload.prev_page_url,
        search: false,
        loading: false,
      };
    case marketTypes.FINISH_ADD_CAUSES:
      //console.log(" array length on state : ", _array.length);
      let causeArray = state.causesArray.concat(action.payload.data);
      // console.log(" Cause data is Here     ", causeArray);
      return {
        ...state,
        causesArray: causeArray,
        causesNextPageUrl: action.payload.next_page_url,
        causesPrevPageUrl: action.payload.prev_page_url,
        search: false,
        loading: false,
      };
    case marketTypes.SET_CAUSES:
      // console.log("causes : ", action.payload.data);
      return {
        ...state,
        causesArray: action.payload.data.data,
        causesNextPageUrl: action.payload.data.next_page_url,
        causesPrevPageUrl: action.payload.data.prev_page_url,
        search: false,
        loading: false,
      };
    default:
      return state;
  }
};

export default marketReducer;
