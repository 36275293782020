import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SustainableItem from "../PublicProfile/SustainableItem";
import { getSustainableGoals } from "../../redux/Auth/auth.actions";
import {
  handleUserSustGoals,
  handleUpdateSustGoals,
} from "../../redux/Auth/auth.helpers";
import { useTranslation } from "react-i18next";

import { useHistory } from "react-router-dom";

export const SustainableList = ({
  dispatchGetSustainableGoals,
  sustainableGoals,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  useEffect(() => {
    dispatchGetSustainableGoals();
  }, []);

  const [userSusList, setUserSusList] = useState([]);

  const handleNextStep = async () => {
    localStorage.removeItem("newuser");

    const resp = await handleUpdateSustGoals(userSusList);
    history.push("/create-success");
  };

  return (
<>
  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 w-full h-full">
    {sustainableGoals?.map((sG, index) => {
      const isSelected =
        userSusList && userSusList?.includes(sG.id);

      return (
        <div className="px-4 " key={index}>
          <SustainableItem
            item={sG}
            selectable={true}
            setUserSusList={setUserSusList}
            isSelected={isSelected}
            userSusList={userSusList}
          />
        </div>
      );
    })}
  </div>
  <div
    onClick={handleNextStep}
    className="btn-1 btn-effects ml-auto mt-4"
  >
    {t("register.next_step")}
  </div>
</>

  );
};

const mapStateToProps = ({ auth }) => ({
  sustainableGoals: auth.sustainableGoals,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchGetSustainableGoals: () => dispatch(getSustainableGoals()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SustainableList);
