import React from "react";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../components/NewLandingPage/tailwind.js";
import Footer from "../../components/Footer.js";
import "../../components/NewLandingPage/index.css";
import { Menu } from "../../components/NewLandingPage/Menu.js";

import img_ods_1 from "../../assets/new-landing-page/ods/1.png";
import img_ods_2 from "../../assets/new-landing-page/ods/2.png";
import img_ods_3 from "../../assets/new-landing-page/ods/3.png";
import img_ods_4 from "../../assets/new-landing-page/ods/4.png";
import img_ods_5 from "../../assets/new-landing-page/ods/5.png";
import img_ods_6 from "../../assets/new-landing-page/ods/6.png";
import img_ods_7 from "../../assets/new-landing-page/ods/7.png";
import img_ods_8 from "../../assets/new-landing-page/ods/8.png";
import img_ods_9 from "../../assets/new-landing-page/ods/9.png";
import img_ods_10 from "../../assets/new-landing-page/ods/10.png";
import img_ods_11 from "../../assets/new-landing-page/ods/11.png";
import img_ods_12 from "../../assets/new-landing-page/ods/12.png";
import img_ods_13 from "../../assets/new-landing-page/ods/13.png";
import img_ods_14 from "../../assets/new-landing-page/ods/14.png";
import img_ods_15 from "../../assets/new-landing-page/ods/15.png";
import img_ods_16 from "../../assets/new-landing-page/ods/16.png";
import img_ods_17 from "../../assets/new-landing-page/ods/17.png";

const CustomInput = ({ name, label, value, onChange, inline = false }) => {
    const widthClass = inline ? 'col-span-4' : 'col-span-4 sm:col-span-2 lg:col-span-1';

    return (
        <div className={`flex flex-col gap-2 items-start self-stretch shrink-0 flex-nowrap rounded border border-gray-400 h-12 w-full ${widthClass}`}>
            <div className='flex py-2 px-4 items-center self-stretch shrink-0 flex-nowrap rounded-t relative z-10'>
                <div className='flex h-10 flex-col justify-center items-start grow flex-nowrap relative z-20'>
                    <div className='flex items-center relative z-30 w-full'> {/* Ajuste para usar toda a largura disponível */}
                        <input
                            placeholder={label}
                            name={name}
                            value={value}
                            onChange={onChange}
                            className="w-full h-6 grow font-['Roboto'] text-lg font-normal leading-6 text-gray-900 bg-transparent outline-none overflow-visible text-overflow-ellipsis whitespace-nowrap" // Alterado overflow para 'overflow-visible'
                        />
                    </div>
                    <div className='flex w-auto h-4 py-0 px-1 items-center flex-nowrap bg-gray-100 absolute -top-4 -left-1 z-40'>
                        <span className="h-4 grow font-['Roboto'] text-sm font-normal leading-4 text-gray-600 overflow-visible text-overflow-ellipsis whitespace-nowrap"> {/* Alterado overflow para 'overflow-visible' */}
                            {label}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

const RadioOptions = ({ name, label, selectedOption, onChange, options, inline = false }) => {

    const widthClass = inline ? 'col-span-4' : 'col-span-2';
    return (

        <div className={`flex flex-col gap-2 items-start self-stretch shrink-0 flex-nowrap w-full ${widthClass}`}>
            <span className="text-gray-900 font-medium">{label}:</span>
            {options.map((option) => (
                <label className="inline-flex items-center mt-3" key={option.value}>
                    <input
                        type="radio"
                        className="form-radio h-5 w-5 text-gray-600"
                        name={name}
                        value={option.value}
                        checked={selectedOption === option.value}
                        onChange={(e) => onChange(name, e.target.value)}
                    />
                    <span className="ml-2 text-gray-700">{option.label}</span>
                </label>
            ))}
        </div>

    );
}

const ODSList = ({ setFormData, formData }) => {
    const [odsList] = useState([
        { id: 1, name: 'Erradicação da Pobreza', img: img_ods_1 },
        { id: 2, name: 'Fome Zero e Agricultura Sustentável', img: img_ods_2 },
        { id: 3, name: 'Saúde e Bem-Estar', img: img_ods_3 },
        { id: 4, name: 'Educação de Qualidade', img: img_ods_4 },
        { id: 5, name: 'Igualdade de Gênero', img: img_ods_5 },
        { id: 6, name: 'Água Potável e Saneamento', img: img_ods_6 },
        { id: 7, name: 'Energia Acessível e Limpa', img: img_ods_7 },
        { id: 8, name: 'Trabalho Decente e Crescimento Econômico', img: img_ods_8 },
        { id: 9, name: 'Indústria, Inovação e Infraestrutura', img: img_ods_9 },
        { id: 10, name: 'Redução das Desigualdades', img: img_ods_10 },
        { id: 11, name: 'Cidades e Comunidades Sustentáveis', img: img_ods_11 },
        { id: 12, name: 'Consumo e Produção Responsáveis', img: img_ods_12 },
        { id: 13, name: 'Ação Contra a Mudança Global do Clima', img: img_ods_13 },
        { id: 14, name: 'Vida na Água', img: img_ods_14 },
        { id: 15, name: 'Vida Terrestre', img: img_ods_15 },
        { id: 16, name: 'Paz, Justiça e Instituições Eficazes', img: img_ods_16 },
        { id: 17, name: 'Parcerias e Meios de Implementação', img: img_ods_17 },
    ]);
    const [selectedOds, setSelectedOds] = useState([]);

    const handleSelectOds = (id) => {
        if (selectedOds.includes(id)) {
            // Remove o ID se já estiver selecionado
            const newSelectedOds = selectedOds.filter(odsId => odsId !== id);
            setSelectedOds(newSelectedOds);
            updateFormData(newSelectedOds);
        } else if (selectedOds.length < 4) {
            // Adiciona o ID apenas se menos de 4 já estiverem selecionados
            const newSelectedOds = [...selectedOds, id];
            setSelectedOds(newSelectedOds);
            updateFormData(newSelectedOds);
        }
    };

    const updateFormData = (newSelectedOds) => {
        setFormData({
            ...formData,
            selected_ods: newSelectedOds.map(odsId =>
                odsList.find(ods => ods.id === odsId).name
            ).join(', ')
        });
    };

    return (
        <div className="p-5 grid md:grid-cols-5 md:gap-5 grid-cols-2 gap-1 bg-transparent">
            {odsList.map(ods => (
                <div key={ods.id}
                    onClick={() => handleSelectOds(ods.id)}
                    className={`p-2 cursor-pointer flex flex-col items-center`}
                >
                    <img src={ods.img} alt={ods.name} className={`w-40 h-40 object-cover ${selectedOds.includes(ods.id) ? 'border-4 border-cyan-950' : ''}`} />

                </div>
            ))}
        </div>
    );
};

export const CompanyForm = ({ user }) => {
    const history = useHistory();
    const { t, i18n } = useTranslation();

    document.title = "magikk - entrada";

    if (user) {
        return <Redirect to="/" />;
    }

    const [step, setStep] = useState(1);

    const [formData, setFormData] = useState({
        nome: "",
        empresa: "",
        email: "",
        telefone: "",
        objetivo: "",
        fale_objetivo_parceria: "",
        razao_social: "",
        nome_fantasia: "",
        cnpj: "",
        inscricao_estadual: "",
        inscricao_municipal: "",
        website: "",
        email_institucional: "",
        nome_responsavel: "",
        email_responsavel: "",
        telefone_responsavel: "",
        cargo: "",
        cep: "",
        logradouro: "",
        numero: "",
        complemento: "",
        cidade: "",
        estado: "",
        pais: "",
        ramo_atividade: "",
        quantidade_colaboradores: "",
        principal_local_atuacao: "",
        privacidade: false,
        step: 1,
        selected_ods: ""

    });

    useEffect(() => {
        const savedFormData = localStorage.getItem('companyFormData');
        if (savedFormData) {
            setFormData(JSON.parse(savedFormData));
            console.log(JSON.parse(savedFormData));
        }
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleRadioChange = (groupName, value) => {
        setFormData({ ...formData, [groupName]: value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(formData);

        // remove step from formData
        delete formData.step;

        // verifica se o nome e o email foram preenchidos
        if (!formData.nome || !formData.email || !formData.empresa) {
            alert("Preencha o nome, email e empresa");
            return;
        }


        fetch(`${process.env.REACT_APP_API_URI}/api/contato`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
        })
            .then((response) => {
                if (response.ok) {
                    alert("Mensagem enviada com sucesso!");
                    localStorage.removeItem('companyFormData');
                    history.push("/entrance");
                } else {
                    alert("Erro ao enviar mensagem");
                }
            })
            .catch((error) => {
                alert("Erro ao enviar mensagem");
            });
    };

    const handleSave = () => {
        localStorage.setItem('companyFormData', JSON.stringify(formData || {}));
    };


    return (
        <>
            <div className="flex flex-col bg-zinc-50">
                <Menu companyForm={true} />

                <div className="flex flex-col pb-20 bg-slate-50">
                    <div className="flex flex-col self-center p-2.5 mt-7 w-full max-w-[1150px] max-md:max-w-full">
                        <div className="flex justify-center items-center px-16 text-xl leading-8 font-[5] text-cyan-950 max-md:px-5 max-md:max-w-full">
                            <div className="flex gap-2.5">
                                <div className={`flex justify-center items-center w-10 h-10 rounded-full ${step === 1 ? ' bg-cyan-950 text-white' : 'bg-gray-200 text-cyan-950'} `}>
                                    1
                                </div>
                                <div className="shrink-0 my-auto h-0.5 bg-cyan-950 w-[31px]" />
                                <div className={`flex justify-center items-center w-10 h-10 rounded-full ${step === 2 ? ' bg-cyan-950 text-white' : 'bg-gray-200 text-cyan-950'}`}>
                                    2
                                </div>
                                <div className="shrink-0 my-auto h-0.5 bg-cyan-950 w-[31px]" />
                                <div className={`flex justify-center items-center w-10 h-10 rounded-full ${step === 3 ? ' bg-cyan-950 text-white' : 'bg-gray-200 text-cyan-950'}`}>
                                    3
                                </div>
                            </div>
                        </div>
                        {step === 1 && (
                            <>
                                <div className="mt-7 mb-7 text-4xl text-cyan-950 max-md:max-w-full">
                                    Cadastre sua empresa
                                </div>



                                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">

                                    <CustomInput
                                        name="nome"
                                        label="Nome"
                                        value={formData.nome}
                                        onChange={handleChange}
                                    />

                                    <CustomInput
                                        name="empresa"
                                        label="Empresa"
                                        value={formData.empresa}
                                        onChange={handleChange}
                                    />

                                    <CustomInput
                                        name="email"
                                        label="Email"
                                        value={formData.email}
                                        onChange={handleChange}
                                    />

                                    <CustomInput
                                        name="telefone"
                                        label="Telefone"
                                        value={formData.telefone}
                                        onChange={handleChange}
                                    />

                                    <RadioOptions
                                        name="objetivo"
                                        label="Objetivo da parceria com a magikk"
                                        selectedOption={formData.objetivo}
                                        onChange={handleRadioChange}
                                        options={[
                                            { value: 'impacto', label: 'Desenvolver Projetos de Impacto' },
                                            { value: 'incentivo', label: 'Lei de Incentivo' },
                                            { value: 'diagnostico', label: 'Diagnóstico e/ou Governança' },
                                            { value: 'consumo_consciente', label: 'Vendas Consumo Consciente' },
                                        ]}
                                    />

                                    <CustomInput
                                        name="fale_objetivo_parceria"
                                        label="Fale um pouco melhor dos objetivos da parceria com a magikk"
                                        value={formData.fale_objetivo_parceria}
                                        onChange={handleChange}
                                        inline={true}
                                    />

                                </div>

                            </>
                        )}

                        {step === 2 && (
                            <>

                                <div className="mt-7 mb-7 text-4xl text-cyan-950 max-md:max-w-full">
                                    Finalize seu cadastro
                                </div>

                                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">

                                    <CustomInput
                                        name="razao_social"
                                        label="Razão Social"
                                        value={formData.razao_social}
                                        onChange={handleChange}
                                    />

                                    <CustomInput
                                        name="nome_fantasia"
                                        label="Nome Fantasia"
                                        value={formData.nome_fantasia}
                                        onChange={handleChange}
                                    />

                                    <CustomInput
                                        name="cnpj"
                                        label="CNPJ"
                                        value={formData.cnpj}
                                        onChange={handleChange}
                                    />

                                    <CustomInput
                                        name="inscricao_estadual"
                                        label="Inscrição Estadual"
                                        value={formData.inscricao_estadual}
                                        onChange={handleChange}
                                    />

                                    <CustomInput
                                        name="inscricao_municipal"
                                        label="Inscrição Municipal"
                                        value={formData.inscricao_municipal}
                                        onChange={handleChange}
                                    />

                                    <CustomInput
                                        name="website"
                                        label="Website"
                                        value={formData.website}
                                        onChange={handleChange}
                                    />

                                    <CustomInput
                                        name="email_institucional"
                                        label="Email Institucional"
                                        value={formData.email_institucional}
                                        onChange={handleChange}
                                    />

                                    <CustomInput
                                        name="nome_responsavel"
                                        label="Nome do Responsável"
                                        value={formData.nome_responsavel !== "" ? formData.nome_responsavel : formData.nome}
                                        onChange={handleChange}
                                    />

                                    <CustomInput
                                        name="email_responsavel"
                                        label="Email do Responsável"
                                        value={formData.email_responsavel !== "" ? formData.email_responsavel : formData.email}
                                        onChange={handleChange}
                                    />

                                    <CustomInput
                                        name="telefone_responsavel"
                                        label="Telefone do Responsável"
                                        value={formData.telefone_responsavel !== "" ? formData.telefone_responsavel : formData.telefone}
                                        onChange={handleChange}
                                    />

                                    <CustomInput
                                        name="cargo"
                                        label="Cargo do Responsável"
                                        value={formData.cargo}
                                        onChange={handleChange}
                                    />

                                </div>

                                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 pt-5">
                                    <CustomInput
                                        name="cep"
                                        label="CEP"
                                        value={formData.cep}
                                        onChange={(e) => {
                                            const cep = e.target.value.replace(/\D/g, '');
                                            // Atualiza imediatamente o CEP no formData para evitar perda de caractere
                                            const updatedFormData = { ...formData, cep: cep };
                                            setFormData(updatedFormData);

                                            // Verifica se o CEP tem 8 caracteres e só então faz a requisição
                                            if (cep.length === 8) {
                                                fetch(`https://viacep.com.br/ws/${cep}/json/`)
                                                    .then((response) => response.json())
                                                    .then((data) => {
                                                        setFormData({
                                                            ...updatedFormData,
                                                            logradouro: data.logradouro,
                                                            cidade: data.localidade,
                                                            estado: data.uf,
                                                            pais: 'Brasil',
                                                            principal_local_atuacao: data.localidade,
                                                        });
                                                    });
                                            }
                                        }}
                                    />

                                    <CustomInput
                                        name="logradouro"
                                        label="Logradouro"
                                        value={formData.logradouro}
                                        onChange={handleChange}
                                    />

                                    <CustomInput
                                        name="numero"
                                        label="Número"
                                        value={formData.numero}
                                        onChange={handleChange}
                                    />

                                    <CustomInput
                                        name="complemento"
                                        label="Complemento"
                                        value={formData.complemento}
                                        onChange={handleChange}
                                    />

                                    <CustomInput
                                        name="cidade"
                                        label="Cidade"
                                        value={formData.cidade}
                                        onChange={handleChange}
                                    />

                                    <CustomInput
                                        name="estado"
                                        label="Estado"
                                        value={formData.estado}
                                        onChange={handleChange}
                                    />

                                    <CustomInput
                                        name="pais"
                                        label="País"
                                        value={formData.pais}
                                        onChange={handleChange}
                                    />


                                    <RadioOptions
                                        name="ramo_atividade"
                                        label="ramo de atividade da empresa"
                                        selectedOption={formData.ramo_atividade}
                                        onChange={handleRadioChange}
                                        options={[
                                            { value: 'industria', label: 'Indústria' },
                                            { value: 'comercio', label: 'Comércio' },
                                            { value: 'servicos', label: 'Serviços' },
                                            { value: 'outros', label: 'Outros' },
                                        ]}
                                    />

                                    <RadioOptions
                                        name="quantidade_colaboradores"
                                        label="Quantidade de colaboradores"
                                        selectedOption={formData.quantidade_colaboradores}
                                        onChange={handleRadioChange}
                                        options={[
                                            { value: '1_10', label: 'De 1 a 10' },
                                            { value: '11_50', label: 'De 11 a 50' },
                                            { value: '51_100', label: 'De 51 a 100' },
                                            { value: '101_500', label: 'De 101 a 500' },
                                            { value: '501_0000', label: 'De 501 a 1000' },
                                        ]}
                                    />

                                    <CustomInput
                                        name="principal_local_atuacao"
                                        label="Principal local de atuação"
                                        value={formData.principal_local_atuacao !== "" ? formData.principal_local_atuacao : formData.cidade}
                                        onChange={handleChange}
                                    />

                                </div>


                            </>
                        )}

                        {step === 3 && (
                            <>


                                <div className="mt-7 mb-7 text-4xl text-cyan-950 max-md:max-w-full">
                                    ODS - Marque até 4 opções
                                    <div className="font-thin text-sm text-justify mt-5">
                                    Os Objetivos de Desenvolvimento Sustentável (ODS) são como um roteiro para construir um mundo mais equitativo e sustentável até 2030.
Compostos por 17 objetivos estabelecidos pela ONU, esses objetivos representam um compromisso global em direção a um futuro melhor, onde cada indivíduo e organização desempenham um papel importante.
                                    </div>
                                </div>

                                <ODSList formData={formData} setFormData={setFormData} />
                            </>
                        )}


                        <div className="flex gap-5 mt-7 text-sm leading-5 text-white whitespace-nowrap font-[5] max-md:flex-wrap max-md:max-w-full justify-end">
                            {step > 1 && (
                                <div className="flex justify-center items-center px-8 py-2.5 rounded-md bg-cyan-950 max-md:px-5 hover:cursor-pointer"
                                    onClick=
                                    {() => {
                                        setStep(step - 1);
                                        formData.step = step - 1;
                                    }}
                                >
                                    <div className="flex gap-2.5 hover:cursor-pointer">
                                        <img
                                            loading="lazy"
                                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/9926497d2a206379370d343dac3f129db62098b9820a2da7373fceeafc35c5cf?apiKey=103790acd32d40c0b511bf41726fd638&"
                                            className="shrink-0 my-auto w-3.5 aspect-square rotate-180"
                                        />
                                        <div>Voltar</div>

                                    </div>
                                </div>
                            )}
                            <div className="justify-center items-center px-8 py-2.5 rounded-md bg-cyan-950 max-md:px-5 hover:cursor-pointer"
                                onClick={handleSave}
                            >
                                SALVAR
                            </div>
                            <div className="flex justify-center items-center px-8 py-2.5 rounded-md bg-cyan-950 max-md:px-5 hover:cursor-pointer"
                                onClick={
                                    step === 3 ? handleSubmit : () => {
                                        setStep(step + 1);
                                        formData.step = step + 1;
                                        handleSave();
                                    }
                                }
                            >
                                <div className="flex gap-2.5 ">
                                    <div>
                                        {step === 3 ? 'Enviar' : 'Avançar'}
                                    </div>
                                    <img
                                        loading="lazy"
                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/9926497d2a206379370d343dac3f129db62098b9820a2da7373fceeafc35c5cf?apiKey=103790acd32d40c0b511bf41726fd638&"
                                        className="shrink-0 my-auto w-3.5 aspect-square"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer newLanding={true} />
            </div>
        </>
    );
};

const mapStateToProps = ({ auth }) => ({
    user: auth.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyForm);
