import React from "react";
import { connect } from "react-redux";
import { AuthHeader } from "../../components/Auth/AuthHeader";
import DiscoverMarket from "../../components/Static/DiscoverMarket";
import OurCommunity from "../../components/Static/OurCommunity";
import { Footer } from "../../components/Footer";
import user_static from "../../assets/user_static.png";
import badges from "../../assets/badges.png";
import lets_go from "../../assets/lets_go.png";
import { IoDocumentTextOutline } from "react-icons/io5";
import { FaUserCheck } from "react-icons/fa";
import { AiFillHeart } from "react-icons/ai";
import { BiChevronRight } from "react-icons/bi";
import colors from "../../constants/colors";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Entrance = ({ user }) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  document.title = "magikk - entrada";

  if (user) {
    return <Redirect to="/" />;
  }
  return (
    <div className="bg-white">
      <AuthHeader />
      <div className="container mx-auto flex flex-col sm:flex-row mt-12">
        <div className="w-full sm:w-4/12 bg-white flex flex-col items-center justify-between p-8 sm:mr-8">
          <h3 className="font-VolksMedium text-4xl text">
            {t("entrance.hero_title_1")}
          </h3>
          <p className="font-LatoRegular text-sm text-grey mt-4">
            {t("entrance.hero_text_1")}
          </p>
          <div onClick={() => { history.push('register') }} className="btn-1 btn-effects mt-8 py-2 pr-auto self-start">
            {t("login.volunteer")}
          </div>
        </div>
        <div className="w-full sm:w-8/12 sm:pr-8">
          <img src={user_static} className="object-contain mx-auto" alt="user"/>
        </div>
      </div>
      <div className="mt-16 sm:w-8/12 mx-auto">
        <img src={badges} alt="badges"/>
      </div>
      <div className="bg-bodyBg py-16 mt-16">
        <div className="flex flex-col sm:flex-row items-center container mx-auto mt-12">
          <div className="w-full sm:w-4/12 sm:px-12 sm:mr-24 flex flex-col items-start justify-around">
            <h4 className="text-sm mb-4">{t("entrance.first_steps")}</h4>
            <h4 className="font-VolksDemiBold mb-4 text-4xl">{t("entrance.magikk_vision")}</h4>
            <p className="font-LatoRegular text-base text-grey">{t("entrance.magikk_vision_txt")}
            </p>
          </div>
          <div className="w-full sm:w-1/2 flex flex-col sm:flex-row">
            <div className="flex flex-col h-64 w-full sm:w-64 justify-around p-8 items-start rounded-ftxl mb-8 bg-white shadow-md">
              <div className="rounded-full bg-primary flex items-center justify-center w-20 h-20">
                <IoDocumentTextOutline color={"white"} size={24} />
              </div>
              <div className="font-VolksDemiBold ">{t("entrance.sign_up")}</div>
              <div className="text-xs font-LatoRegular">
                {t("entrance.sign_up_txt")}
              </div>
            </div>
  
            <div className="flex flex-col h-64 w-full sm:w-64 justify-around p-8 items-start rounded-ftxl mb-8 sm:mb-0 bg-white shadow-md">
              <div className="rounded-full bg-primary flex items-center justify-center w-20 h-20">
                <AiFillHeart color={"white"} size={24} />
              </div>
              <div className="font-VolksDemiBold ">{t("entrance.be_magikk")}</div>
              <div className="
text-xs font-LatoRegular">
{t("entrance.be_magikk_txt")}
</div>
</div>
</div>
<div className="w-full sm:w-1/2 pt-8 sm:pt-0">
<div className="flex flex-col h-64 w-full sm:w-64 justify-around p-8 items-start rounded-ftxl mb-8 bg-white shadow-md">
<div className="rounded-full bg-primary flex items-center justify-center w-20 h-20">
<FaUserCheck color={"white"} size={24} />
</div>
<div className="font-VolksDemiBold ">{t("entrance.magikk_review")}</div>
<div className="text-xs font-LatoRegular">
{t("entrance.magikk_review_txt")}
</div>
</div>
<div className="flex flex-col h-64 w-full sm:w-64 justify-around px-8 pb-8 items-start rounded-ftxl mb-4 bg-primary shadow-md">
<div className="ml-auto flex items-center justify-center w-20 h-20">
<img src={lets_go} className="w-24 h-24" alt="lets go"/>
</div>
<div className="font-VolksDemiBold text-white">{t("entrance.lets_go")}</div>
<div className="text-xs font-LatoRegular text-white w-4/5">
{t("entrance.lets_go_txt")}
</div>
<div className="bg-white rounded-md btn-effects" onClick={() => { history.push('/login') }}>
<BiChevronRight color={colors.theme} size={21} />
</div>
</div>
</div>
</div>
{/* <DiscoverMarket /> */}
<OurCommunity />
</div>
<Footer />

  </div>
);  
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Entrance);
