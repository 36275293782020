const userTypes = {
  CREATE_ACCOUNT: "CREATE_ACCOUNT",
  SET_PROFILE_INFO: "SET_PROFILE_INFO",
  SET_FORM_ERROR: "SET_FORM_ERROR",
  EMAIL_SIGN_IN_START: "EMAIL_SIGN_IN_START",
  SIGN_OUT_START: "SIGN_OUT_START",
  SIGN_OUT_SUCCESS: "SIGN_OUT_SUCCESS",
  CHECK_CURRENT_USER: "CHECK_CURRENT_USER",
  CREATE_ACCOUNT_SUCCESS: "CREATE_ACCOUNT_SUCCESS",
  //
  GET_PROFILE_INFO: "GET_PROFILE_INFO",
  SET_LOBBY_MODAL: "SET_MODAL",
  GET_LOBBY_MODAL: "GET_MODAL",
  SET_LANG: "SET_LANG",
  START_GOOGLE_LOGIN: "START_GOOGLE_LOGIN",
  START_FACEBOOK_LOGIN: "START_FACEBOOK_LOGIN",
  START_APPLE_LOGIN: "START_APPLE_LOGIN",
  LOADING: "LOADING",
  START_GET_PROFILE_DATA: "START_GET_PROFILE_DATA",
  SHOW_SUCCESS_MODAL: "SHOW_SUCCESS_MODAL",
  HIDE_SUCCESS_MODAL: "HIDE_SUCCESS_MODAL",
  //
  GET_SUSTAIN_GOALS: "GET_SUSTAIN_GOALS",
  SET_SUSTAIN_GOALS: "SET_SUSTAIN_GOALS",
};

export default userTypes;
