import React, { useEffect, useState } from "react";
import MainHeader from "../components/MainHeader";
import PostGoodDeed from "../components/PublicProfile/PostGoodDeed";
import ProfileHero from "../components/PublicProfile/ProfileHero";
import PublicProfileItem from "../components/PublicProfile/PublicProfileItem";
import SustainableItem from "../components/PublicProfile/SustainableItem";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { startGetAllGoals } from "../redux/Market/market.actions";
import { handleGetUserPublicTransactions } from "../redux/Profile/profile.helpers";
import loaderGif from "../assets/spinner.gif";
import { handleUserSustGoals } from "../redux/Auth/auth.helpers";

import {
  startGetUserTransactions,
  clearTransactions,
} from "../redux/Profile/profile.actions";

function PublicProfile({ user }) {
  //const [realList, setRealList] = useState();
  const { t, i18n } = useTranslation();
  const [userSusList, setUserSusList] = useState();
  const [transferCount, setTransferCount] = useState(0);
  const [dataSpinner, setDataSpinner] = useState(true);
  const [activities, setActivities] = useState();
  const [nextURL, setNextPage] = useState(null);

  // const uploadPicture = (e) => {
  //   setPP({
  //     picturePreview: URL.createObjectURL(e.target.files[0]),
  //     pictureAsFile: e.target.files[0],
  //   });
  // };

  useEffect(async () => {
    //dispatchStartGetUserTransactions();
    // dispatchStartGetAllGoals();
    const sust = await handleUserSustGoals();
    // const list = sust?.map((s)=>s.id);
    //console.log(" user sustake list .... ------ .... ", sust);

    setUserSusList(sust);
    handleGetUserPublicTransactions().then((response) => {
      setActivities(response?.data);
      setNextPage(response?.next_page_url);
      setDataSpinner(false);
    });
  }, []);

  useEffect(() => {
    if (user?.id && user?.transfers_count) {
      setTransferCount(user.transfers_count);
    }
  }, [user]);

  document.title = "magikk - perfil público";

  return (
    <div className="mb-40">
      <MainHeader search={true} />
      <ProfileHero transferCount={transferCount} />
      <div className="flex flex-row container mx-auto mt-36">
        <div
          className="bg-white rounded-ftxl shadow-xl w-6/12 mr-8"
          style={{ height: "fit-content" }}
        >
          <PostGoodDeed />
        </div>
        <div className="bg-white rounded-ftxl shadow-xl w-full flex flex-col p-4">
          {dataSpinner ? (
            <img className="h-48 w-48 self-center" src={loaderGif} alt="profile photo"/>
          ) : (
            <>
              <h4 className="font-LatoRegular font-bold text-2xl text-left">
                {t("profile.recent_activities")}
              </h4>
              <div>
                {activities?.map((trs, index) => (
                  <PublicProfileItem
                    item={trs}
                    itemIndex={index}
                    key={index}
                  />
                ))}
              </div>
            </>
          )}
          <div>
            {!!nextURL && (
              <div
                className="w-full text-center text-primary underline py-2 btn-effects font-LatoRegular"
                onClick={() => {
                  handleGetUserPublicTransactions(nextURL).then(
                    (response) => {
                      setActivities(
                        activities.concat(response?.data)
                      );
                      setNextPage(response?.next_page_url);
                    }
                  );
                }}
              >
                {t("profile.load_more")}
              </div>
            )}
          </div>
        </div>

        <div
          className="bg-white rounded-ftxl shadow-xl w-5/12 ml-8 p-4 flex flex-col"
          style={{ height: "fit-content" }}
        >
          <h4 className="font-LatoRegular font-bold text-2xl text-left mb-4">
            {t("profile.sustainable_goals")}
          </h4>
          <div className="flex flex-col items-center">
            {userSusList &&
              Array.isArray(userSusList) &&
              userSusList.map((goal, index) => (
                <SustainableItem item={goal} key={index} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapState = ({ profile, market, auth }) => ({
  user: auth.user,
  transactions: profile.userTransactions,
  trsNextPageUrl: profile.trsNextPageUrl,
  allGoals: market.allGoals,
});

const mapDispatch = (dispatch) => ({
  dispatchStartGetUserTransactions: (next_url) =>
    dispatch(startGetUserTransactions(next_url)),
  dispatchStartGetAllGoals: () => dispatch(startGetAllGoals()),
  dispatchClearTransactions: () => dispatch(clearTransactions()),
});

export default connect(mapState, mapDispatch)(PublicProfile);
