import React from "react";
import ProfileCard from "./ProfileCard";
import { useTranslation } from "react-i18next";
import StatBox from "./StatBox";

function Dashboard() {
  const { t, i18n } = useTranslation();

  return (
    <div className="xl:container mx-auto px-12">
      <h3 className="font-LatoRegular text-3xl font-bold mb-8 ml-28 sm:ml-0 md:ml-28">
        {t("product.Dashboard")}
      </h3>
      <div className="md:px-28 flex flex-row justify-between flex-wrap w-full">
        <ProfileCard />
        <StatBox type="co2" />
        {/* <StatBox type="env" /> */}
      </div>
    </div>
  );
}

export default Dashboard;
