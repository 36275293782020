import React, { useEffect } from "react";
import { connect } from "react-redux";
// import SmallProductCard from "./SmallProductCard";
import ProductCard from "../Market/ProductCard";
import { useTranslation } from "react-i18next";
import { startGetSelectedCategoryData } from "../../redux/Market/market.actions";

export const OtherProducts = ({
  selectedProduct,
  dispatchStartGetSelectedCategoryData,
  selectedCategoryData,
  productsArray,
}) => {
  const { t, i18n } = useTranslation();

  let otherProducts =
    selectedCategoryData?.length > 4 &&
    typeof selectedCategoryData !== "string"
      ? selectedCategoryData.slice(0, 4)
      : typeof selectedCategoryData !== "string" &&
        selectedCategoryData?.length > 1
      ? selectedCategoryData
      : productsArray;

  otherProducts = otherProducts?.filter(
    (op) => op.id !== selectedProduct?.id
  );

  otherProducts =
    otherProducts?.length > 4
      ? otherProducts.slice(0, 4)
      : otherProducts;
  const productCategoryNo = selectedProduct?.categories;

  useEffect(() => {
    // console.log(" the id ... ", selectedProduct?.id);
    if (productCategoryNo) {
      dispatchStartGetSelectedCategoryData({
        id: productCategoryNo,
        type: "goods",
      });
    }
  }, []);

  // useEffect(() => {
  //   console.log(" others ...    ,", !!selectedCategoryData);
  // }, []);

  return (
    <div className="bg-bodyBg -mb-24 pb-4">
      <div className="mx-auto xl:container">
        <h4 className="font-VolksDemiBold text-xl container pt-12">
          {t("product.others")}
        </h4>
        <div className="my-16">
          <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 ">
            {otherProducts &&
              otherProducts.length &&
              otherProducts.map((oC, ind) => (
                <ProductCard item={oC} key={ind} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth, market }) => ({
  user: auth.user,
  selectedProduct: market.selectedProduct,
  selectedCategoryData: market.selectedCategoryData,
  productsArray: market.productsArray,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchStartGetSelectedCategoryData: (dt) =>
    dispatch(startGetSelectedCategoryData(dt)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OtherProducts);
