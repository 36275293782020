import React from "react";
import climate from "../../assets/climate/climate.png";
import coimage from "../../assets/coimage.jpeg";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

function StatBox({ type, totalCo2, totalEnv }) {
  const { t, i18n } = useTranslation();

  var title = "";
  var infos = [];
  var source = climate;

  let envData = totalEnv;
  let coData = totalCo2;

  // if (type === "env") {
  title = t("profile.climate_action");
  source = climate;
  infos = [envData, "", t("profile.co2_emission")];
  // } else if (type === "co2") {
  //   title = t('profile.consumption_title');
  //   source = coimage;
  //   infos = [coData, "", "Collected trash"];
  // }
  return (
    <div className="bg-white w-full md:w-1/3 rounded-ftxl h-auto md:h-64 py-7 px-5 flex flex-col justify-between">
      <div className="flex flex-row justify-between">
        <h4 className="font-VolksDemiBold text-primary">{title}</h4>
        <img
          className={`w-14 h-14 object-contain rounded-md ${
            type === "env" && "p-2 border border-primary p-1 bg-bodyBg"
          }`}
          src={source}
        />
      </div>
      <div className="flex flex-col justify-between mt-4">
        <h3 className="font-LatoRegular font-bold text-4xl mb-4">{infos[0]} <span className="text-xl">{t("profile.tons")}</span></h3>
        <h3 className="font-LatoRegular whitespace-nowrap">
          {infos[1]} 
        </h3>
        <h3 className="font-LatoRegular">{infos[2]}</h3>
      </div>
    </div>
  );
  
}

const mapState = ({ auth }) => ({
  totalCo2: auth.user.totalCo2,
  totalEnv: auth.user.totalEnv,
});

export default connect(mapState)(StatBox);
