import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import PriceSet from "../Shared/PriceSet";
// import { BASE_URL } from "../../config/env";
import marketTypes from "../../redux/Profile/profile.types";
import white_logo from "../../assets/white_logo/white.png";
import firebase from "firebase/compat/app";

function ProductCard({ item, dispatchStartLoader }) {
  const history = useHistory();
  var url = item?.images?.length > 0 ? item.images[0].url : null;
  if (url) {
    url =
      url?.indexOf("public/upload") !== -1
        ? `${process.env.REACT_APP_API_URI}/${url}`
        : url;
  }
  const handleClick = () => {
    dispatchStartLoader();
    const path = `/product-detail/${item.id}`;
    // console.log("item", item);
    firebase.analytics().logEvent("clicou_visualizar_recompensa", {
      id: item.id,
      title: item.title,
      price: item.price,
      karma: item.karma
    });
    history.push(path);

  };

  const organizationName =
    item?.organization?.name ||
    `${item?.user?.firstname} ${item?.user?.lastname}`;

  return (
    <div
      onClick={handleClick}
      className="flex flex-col sm:inline-flex rounded-kkxl overflow-hidden btn-effects"
    >
      {url ? (
        <img
          src={url}
          alt="url"
          style={{ objectFit: "cover" }}
          className="w-full sm:h-64"
        />
      ) : (
        <div className="flex items-center h-64 w-full justify-center">
          <img
            src={white_logo}
            alt="logo"
            style={{ filter: "grayscale(100%)" }}
          />
        </div>
      )}

      <div className="flex flex-col md:flex-row py-3 bg-white px-2 justify-between flex-grow">
        <div className="sm:grow overflow-hidden p-2">
          <div className="font-VolksMedium overflow-hidden">
            {item.title}
          </div>
          <div className="text-greyText whitespace-nowrap max-w-2/5">
            {organizationName}
          </div>
        </div>
        <div className="">
          <PriceSet karma={item?.karma} price={item?.price} />
        </div>
      </div>
    </div>
  );

}

const mapDispatch = (dispatch) => ({
  dispatchStartLoader: () =>
    dispatch({ type: marketTypes.START_LOADER }),
});

export default connect(null, mapDispatch)(ProductCard);
