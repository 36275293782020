import React, { useState } from "react";
import { connect } from "react-redux";
import one from "../../assets/market_hero/1.jpg";
import two from "../../assets/market_hero/2.jpg";
import three from "../../assets/market_hero/3.jpg";
import four from "../../assets/market_hero/4.jpg";
import five from "../../assets/market_hero/5.jpg";
import { FaList, FaSearch } from "react-icons/fa";
import colors from "../../constants/colors";
import { handleGetSearchData } from "../../redux/Market/market.helpers";
import { AiFillCloseCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { SearchResults } from "./Search/SearchResults";

export const MarketHero = ({
  selectedCategoryName,
  setCategoryModalOpen,
  setSelectedCategoryName,
  setGoodsOrCauses,
  searchResults,
  setSearchResults,
}) => {
  const { t, i18n } = useTranslation();

  const handleSearchChange = async (e) => {
    const val = e.target.value;

    if (val.length < 2) {
      setSearchResults([]);
      return;
    }
    const results = await handleGetSearchData(val);
    let allRes = [];
    const { user, organizations, products, causes } = results;

    user?.map((u) => {
      allRes?.push({
        txt: `${u.firstname} ${u.lastname}`,
        type: "User",
        id: u.id,
      });
    });

    organizations?.map((o) => {
      allRes.push({
        txt: o.name.trim(),
        type: "Organization",
        id: o.id,
      });
    });

    products?.map((p) => {
      allRes.push({
        txt: p.title.trim(),
        type: "Product",
        id: p.id,
      });
    });

    causes?.map((c) => {
      allRes.push({
        txt: c.title.trim(),
        type: "Cause",
        id: c.id,
      });
    });

    function compare(a, b) {
      if (a.txt < b.txt) {
        return -1;
      }
      if (a.txt > b.txt) {
        return 1;
      }
      return 0;
    }

    allRes.sort(compare);
    setSearchResults(allRes);
  };
  return (
    <div className="flex flex-col">
      <div className="flex flex-col md:flex-row bg-primary mx-auto container">
        <div className="flex flex-col w-full md:w-4/12 md:pr-12 text-white py-10 md:py-32 md:justify-center md:text-left text-center">
          <h4 className="font-VolksMedium text-4xl md:text-6xl mb-8">
            {t("market.welcome")}
          </h4>
          <p className="font-LatoRegular text-base md:text-lg">
            {t("market.text")}
          </p>
        </div>
        <div className="flex flex-col md:w-7/12 md:flex-row md:items-center hidden md:flex">
          <div className="px-5 flex flex-col justify-center">
            <img src={one} alt="one" className="rounded-3xl" />
          </div>
          <div className="flex flex-col px-5 justify-center">
            <img src={two} alt="two" className="rounded-3xl " />
            <img src={three} alt="three" className="rounded-3xl mt-4 mb-4" />
          </div>
          <div className="flex flex-col px-5 justify-center">
            <img src={four} alt="four" className="rounded-3xl" />
            <img src={five} alt="five" className="rounded-3xl mt-4 mb-4" />
          </div>
        </div>
      </div>
      <div className="bg-primary pt-2 pb-8 mx-auto container relative">
        <div className="bg-white w-full mx-auto rounded-2xl flex flex-row items-center px-2 py-2 md:px-6 md:py-2">
          <FaSearch size="25" color="whitesmoke" />
          {selectedCategoryName ? (
            <div className="ml-2 border-primary border-2 flex flex-row p-3 h-full text-primary items-center rounded-xl btn-effects">
              <AiFillCloseCircle
                onClick={() => {
                  setSelectedCategoryName();
                  setGoodsOrCauses();
                }}
                size="25"
                color={colors.theme}
              />
              <span className="ml-4 font-OpenSansRegular">
                {selectedCategoryName}
              </span>
            </div>
          ) : (
            <input
              onChange={handleSearchChange}
              placeholder={t("search.placeholder")}
              type="text"
              className="flex-1 ml-2 pl-2 outline-none text-sm md:text-base"
            />
          )}
          <div
            onClick={() => {
              setCategoryModalOpen(true);
            }}
            className="ml-auto bg-primary flex flex-row p-3 h-full text-white items-center rounded-xl btn-effects"
          >
            <FaList size="25" color="white" />
            <span className="ml-2 font-OpenSansRegular text-xs md:text-base">
              {t("market.categ")}
            </span>
          </div>
        </div>
        <SearchResults items={searchResults} />
      </div>
    </div>
  );
};

const mapStateToProps = ({ market }) => ({
  selectedCategoryData: market.selectedCategoryData,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MarketHero);
