import React, { useEffect } from "react";
import { connect } from "react-redux";
import MainHeader from "../components/MainHeader";
import Gallery from "../components/ProductDetail/Gallery";
import CauseInfo from "../components/CauseDetail/CauseInfo";
import OtherCauses from "../components/CauseDetail/OtherCauses";
import Footer from "../components/Footer";
import { useParams } from "react-router-dom";
import {
  setCause,
  startGetCause,
  startGetCauses,
} from "../redux/Market/market.actions";

export const CauseDetail = ({
  selectedCause,
  dispatchStartGetCause,
  startGetCausesData,
}) => {
  const { id } = useParams();
  useEffect(async () => {
    await startGetCausesData();
    await dispatchStartGetCause(id);
  }, []);

  useEffect(() => {
    // console.log(
    //   " inside useeffect selected cause .... .  . .       ",
    //   selectedCause
    // );
    window.scrollTo(0, 0);
  }, [selectedCause]);

  return (
    <div className="bg-white">
      <MainHeader />
      {/* <CauseDetailHero /> */}
      <div className="flex flex-col md:flex-row container mx-auto py-8 pb-32">
        <Gallery images={selectedCause?.images} />
        <CauseInfo cause={selectedCause} id={id} />
      </div>
      <OtherCauses />
      <Footer />
    </div>
  );
};

const mapStateToProps = ({ market }) => ({
  selectedCause: market.selectedCause,
});

const mapDispatchToProps = (dispatch) => ({
  cleanSelectedCause: () => dispatch(setCause()),
  dispatchStartGetCause: (_id) => dispatch(startGetCause(_id)),
  startGetCausesData: () => dispatch(startGetCauses()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CauseDetail);
