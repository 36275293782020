import {
  all,
  call,
  put,
  takeLatest,
  delay,
} from "redux-saga/effects";
import {
  setProfileInfo,
  setFormError,
  emailSignInStart,
  signOutSuccess,
  setSustainableGoals,
} from "./auth.actions";

import {
  handleCreateProfile,
  handleSocialLogin,
  handleEmailSignIn,
  getUserWithToken,
  setLocalStorageTokens,
  handleGetSustainableGoals,
} from "./auth.helpers";
import authTypes from "./auth.types";

function* isUserAuthenticated() {
  try {
    const user = yield getUserWithToken();
    if (!user) {
      setLocalStorageTokens();
    }

    // console.log(
    //   " current user ........ ..  .. . . . . . . . . . . .      ",
    //   user
    // );

    yield put(setProfileInfo(user));
  } catch (err) {
    // setAsyncStorageTokens();
    // console.log("isuserauth er :", err);
    yield put(setProfileInfo());
  }
}

export function* onCheckCurrentUser() {
  yield takeLatest(authTypes.CHECK_CURRENT_USER, isUserAuthenticated);
}

function* emailSignIn({ payload }) {
  try {
    // console.log("EMAIL SIGN IN PAYLOAD => ", payload);
    const user_token = yield handleEmailSignIn(payload);

    //console.log('user_token : ', user_token);

    const data = user_token;

    const getJson = () => {
      if (typeof data === "string") {
        const endOfWeirdObject = data.indexOf("}");
        let containerObj = data.substring(
          endOfWeirdObject + 1,
          data.length
        );
        containerObj = containerObj.replace(/\\/g, "");
        const jsonData = JSON.parse(containerObj);
        return jsonData;
      } else {
        return false;
      }
    };
    const jsonRes = getJson();

    if (jsonRes) {
      if (jsonRes.success && jsonRes.success != "false") {
        const _data = jsonRes.data;
        const { user, token } = _data;
        yield setLocalStorageTokens(token);
        yield put(setProfileInfo(user));
      } else if (!jsonRes.data) {
        yield put(setFormError("not_found"));
      } else {
        yield put(setFormError(jsonRes.data));
      }
    } else {
      if (user_token?.success) {
        const { user, token } = user_token.data;
        yield setLocalStorageTokens(token);
        yield put(setProfileInfo(user));
      } else {
        //console.log('error no :221', user_token.data);
        yield put(setFormError(user_token.data));
      }
    }
  } catch (e) {
    //console.log('set form error : ', e);
    yield put(setFormError(e.response ? e.response.data.message : e));
  }
}

export function* onEmailSignInStart() {
  yield takeLatest(authTypes.EMAIL_SIGN_IN_START, emailSignIn);
}

// create Account

function* createProfile({ payload }) {
  // console.log("create profile", payload);
  try {
    const newProfile = {
      firstname: payload.firstname,
      lastname: payload.lastname,
      phone: payload.phone,
      birthdate: payload.birthdate,
      email: payload.email,
      password: payload.password,
      // password_confirmation: payload.password_confirmation,
      password_confirmation: payload.password,
    };

    const response = yield handleCreateProfile(newProfile);
    const data = response.data;

    if (data.status == "SUCCESS" || data.status == 200) {
      // sign in
      let errors = "";
      // bu nasıl response :D:D
      const isError = () => {
        if (
          typeof data === "string" ||
          typeof data?.data === "string"
        ) {
          // console.log("checkpoint 1 .......", data);
          const endOfWeirdObject = data.data.indexOf("}");
          // console.log("index of weird : ", endOfWeirdObject);
          let containerObj = data.data.substring(
            endOfWeirdObject + 1,
            data.data.length
          );
          containerObj = containerObj.replace(/\\/g, "");
          // console.log("conTainer ", containerObj);
          const jsonData = JSON.parse(containerObj);
          // console.log("checkpoint 2 .......", jsonData);
          return jsonData.errors;
        }

        if (data?.errors || data?.data?.errors) {
          return data?.errors || data?.data?.errors;
        }
      };

      const _Error = isError();

      // console.log("checkpoint aftererror");

      if (_Error) {
        // console.log("checkpoint _error");

        yield put(setFormError(_Error));
      } else {
        // console.log("checkpoint center");

        const loginUser = {
          email: payload.email,
          password: payload.password,
        };

        //console.log('show successx');
        yield all([
          // put(showSuccessModal()),
          put(emailSignInStart(loginUser)),
        ]);
      }
    } else {
      //console.log('we are here.....');
      let errors = "";
      if (typeof data === "string") {
        const jsonData = JSON.parse(data);
        errors = jsonData?.errors;
      } else {
        errors = data?.errors || data?.data?.errors;
      }

      //console.log('errors : ', errors);
      yield put(setFormError(errors));
    }
  } catch (e) {
    yield put(setFormError(e && e.errors));
  }
}

export function* onCreateAccount() {
  //console.log('onCreateAccount');
  yield takeLatest(authTypes.CREATE_ACCOUNT, createProfile);
}

// FACEBOOK
function* startFacebookLogin({ payload }) {
  try {
    const socialUser = payload;

    //console.log('is socialUser ', !!socialUser);
    const loginres = yield handleSocialLogin(socialUser);
    const socialResponse = loginres.data;
    if (
      socialResponse &&
      socialResponse.user &&
      socialResponse.token
    ) {
      const { user, token } = socialResponse;

      yield setLocalStorageTokens(token);
      yield put(setProfileInfo(user));
    } else {
      // console.log(
      //   'facebook login fail socialResponse : ',
      //   socialResponse,
      // );
      yield put(setProfileInfo());
    }
  } catch (err) {
    yield put(setProfileInfo());
    if (err === "cancel") {
      //console.log('user cancelled facebook login...');
    } else {
      //console.log('facebook log err', err);
    }
  }
}

export function* onStartFacebookLogin() {
  //console.log('facebook saga triggered...');
  yield takeLatest(
    authTypes.START_FACEBOOK_LOGIN,
    startFacebookLogin
  );
}

// GMAIL
function* startGoogleLogin({ payload }) {
  try {
    const socialUser = payload;

    //console.log('is socialUser ', !!socialUser);
    const loginres = yield handleSocialLogin(socialUser);
    const socialResponse = loginres.data;
    if (
      socialResponse &&
      socialResponse.user &&
      socialResponse.token
    ) {
      const { user, token } = socialResponse;

      yield setLocalStorageTokens(token);
      yield put(setProfileInfo(user));
    } else {
      // console.log(
      //   'google login fail socialResponse : ',
      //   socialResponse,
      // );
      yield put(setProfileInfo());
    }
  } catch (err) {
    yield put(setProfileInfo());
    if (err === "cancel") {
      //console.log('user cancelled google login...');
    } else {
      //console.log('google log err', err);
    }
  }
}

export function* onStartGoogleLogin() {
  yield takeLatest(authTypes.START_GOOGLE_LOGIN, startGoogleLogin);
}

// GMAIL
function* startAppleLogin({ payload }) {
  try {
    const socialUser = payload;

    //console.log('is socialUser ', !!socialUser);
    const loginres = yield handleSocialLogin(socialUser);
    const socialResponse = loginres.data;
    if (
      socialResponse &&
      socialResponse.user &&
      socialResponse.token
    ) {
      const { user, token } = socialResponse;

      yield setLocalStorageTokens(token);
      yield put(setProfileInfo(user));
    } else {
      // console.log(
      //   'Apple login fail socialResponse : ',
      //   socialResponse,
      // );
      yield put(setProfileInfo());
    }
  } catch (err) {
    yield put(setProfileInfo());
    if (err === "cancel") {
      //console.log('user cancelled Apple login...');
    } else {
      //console.log('Apple log err', err);
    }
  }
}

export function* onStartAppleLogin() {
  yield takeLatest(authTypes.START_APPLE_LOGIN, startAppleLogin);
}



function* signOut() {
  //console.log('signOut saga');
  try {
    yield delay(1000);
    localStorage.removeItem("token");
    localStorage.removeItem("cart");
    yield put(signOutSuccess());
  } catch (err) {
    // console.log(err);
  }
}

export function* onSignOutStart() {
  yield takeLatest(authTypes.SIGN_OUT_START, signOut);
}

//

function* getSustainableGoals() {
  try {
    const data = yield handleGetSustainableGoals();

    if (data) {
      //console.log("sust goals data...", data);
      yield put(setSustainableGoals(data));
    } else {
      yield put(setSustainableGoals(null));
      //alert('no data 0033');
    }
  } catch (err) {
    //console.log('handle sust goals error', err);
  }
}

export function* onGetSustainableGoals() {
  yield takeLatest(authTypes.GET_SUSTAIN_GOALS, getSustainableGoals);
}

export default function* userSagas() {
  yield all([
    call(onEmailSignInStart),
    call(onCreateAccount),
    call(onCheckCurrentUser),
    call(onSignOutStart),
    call(onGetSustainableGoals),
    call(onStartGoogleLogin),
    call(onStartFacebookLogin),
    call(onStartAppleLogin),
  ]);
}
