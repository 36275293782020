import React from "react";
import { useHistory } from 'react-router-dom';
import img_app from "../../assets/new-landing-page/builder/08adfca10da07f007fdd10c49e6ff580a2789b59a89bed196e026741e13e244e.png";
import img_seta from "../../assets/new-landing-page/seta_marketplace.svg";
import img_logo from "../../assets/new-landing-page/magikk_marketplace.svg";
import img_seta_botao from "../../assets/new-landing-page/builder/cbff0545103ce20d7b59be7d9c2d40b6662ad602cc8acdd1616380a5f43ff650.svg";

import "./tailwind.js";

export const Marketplace = () => {
    const history = useHistory();
    return (
        <>
                <div className="flex justify-center items-center px-16 py-20 w-full bg-[#83BBE0] max-md:px-5 max-md:max-w-full" id="marketplace">
                    <div className="mt-3.5 mb-3.5 w-full max-w-[1178px] max-md:max-w-full">
                        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                            <div className="flex flex-col w-[56%] max-md:ml-0 max-md:w-full">
                                <div className="flex grow justify-center items-center px-16 pt-7 w-full bg-[#83BBE0] shadow-lg rounded-[32px] max-md:px-5 max-md:mt-10 max-md:max-w-full">
                                    <img
                                        loading="lazy"
                                        srcSet={img_app}
                                        className="w-80 max-w-full aspect-[0.71]"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col ml-5 w-[44%] max-md:ml-0 max-md:w-full">
                                <div className="flex flex-col items-start max-md:mt-10 max-md:max-w-full">
                                    <div className="flex flex-col self-stretch max-md:max-w-full">
                                        <div className="text-4xl font-[5] leading-[60px] text-cyan-950 max-md:max-w-full">
                                            marketplace
                                        </div>
                                        <div className="mt-4 text-base leading-6 text-cyan-950 max-md:max-w-full">
                                            Espaço de compra e venda de produtos de impacto positivo,
                                            ecológicos e ou sustentáveis, como um shopping center
                                            virtual podem ser adquiridos e comprados via <br />
                                            <span className="font-extrabold">
                                                karma, moeda social da magikk
                                            </span>
                                            .
                                        </div>
                                    </div>
                                    <div className="flex gap-4 mt-10">
                                        <img
                                            loading="lazy"
                                            src={img_seta}
                                            className="shrink-0 self-start aspect-[1.03]  stroke-[3px] stroke-cyan-950 w-[76px]"
                                        />
                                        <div className="flex justify-center items-center">
                                            <img
                                                loading="lazy"
                                                srcSet={img_logo}
                                                className="aspect-square fill-white w-[92px] pl-3"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-col justify-center mt-10 max-w-full text-sm leading-5 text-white whitespace-nowrap font-[5] w-[114px]">
                                        <div 
                                        onClick={() => {
                                            history.push('/market');
                                          }}
                                        className="flex gap-2.5 justify-center px-8 py-2.5 rounded-md bg-cyan-950 max-md:px-5 hover:cursor-pointer">
                                            <div>Loja</div>
                                            <img
                                                loading="lazy"
                                                src={img_seta_botao}
                                                className="shrink-0 my-auto w-3.5 aspect-square"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    );
}