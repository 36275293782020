import React from "react";
import { connect } from "react-redux";
import { emailSignInStart } from "../../redux/Auth/auth.actions";
import { Redirect } from "react-router-dom";
import AuthHeader from "../../components/Auth/AuthHeader";
import MainSection from "../../components/Auth/SignIn/MainSection";
import Footer from "../../components/Footer";

export const Login = ({ dispatchEmailSignInStart, user }) => {
  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm();

  // const onSubmit = (data) => {
  //   console.log(data);
  //   dispatchEmailSignInStart(data);
  // };

  if (user) {
    return <Redirect to="/" />;
  }

  document.title = "magikk - login";

  return (
    <div>
      <AuthHeader />
      <MainSection />
      <Footer />
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchEmailSignInStart: (user) =>
    dispatch(emailSignInStart(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
