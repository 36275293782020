import React from "react";

import img_seta from "../../assets/new-landing-page/builder/3b4bfc2e30e7271bc8b624eb61f27fc1aecd785cc3a75e3698b770b3a539246e.svg";
import img_relatorios from "../../assets/new-landing-page/builder/4a1f75c9f885653478688caad40d4e204aef0f0cf3cf172180f60dea3b1d2f40.svg";

import "./tailwind.js";

export const TransparencyReport = () => {
    return (
        <>
            <div className="flex justify-center items-center px-16 py-16 w-full bg-cyan-950 max-md:px-5 max-md:max-w-full">
                <div className="mb-20 w-full max-w-[1169px] max-md:mb-10 max-md:max-w-full">
                    <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                    <div className="flex flex-col order-1 md:order-2 w-full md:w-2/3">
                            <div className="flex flex-col grow justify-center items-center md:items-start text-white max-md:max-w-full">
                                <img
                                    loading="lazy"
                                    src={img_relatorios}
                                    className="w-[68px]"
                                />
                                <div className="flex flex-col justify-center mt-4 max-md:max-w-full">
                                    <div className="text-3xl font-[5] max-md:max-w-full">
                                        Relatório de Transparência
                                    </div>
                                    <div className="mt-4 text-base leading-6 max-md:max-w-full">
                                        Desenvolva planos de ação e gerencie seus projetos de
                                        impacto com eficiência. Utilize nossa software para
                                        monitorar estratégias de Responsabilidade Socioambiental
                                        Corporativa e analisar relatórios de transparência através
                                        de dashboards intuitivos.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col order-2 md:order-1 w-full md:w-1/3">
                            <div className="flex flex-col grow mt-20 max-md:mt-10">
                                <div className="flex gap-3">
                                    <div className="flex flex-col justify-center items-center px-1 py-1.5 bg-white shadow-xl h-[22px] rounded-[100px] w-[22px]">
                                        <div className="flex justify-center items-center p-1">
                                            <img
                                                loading="lazy"
                                                src={img_seta}
                                                className="w-2 aspect-[1.59]"
                                            />
                                        </div>
                                    </div>
                                    <div className="text-sm leading-5 text-white font-[5]">
                                        Dashboards de Impacto
                                    </div>
                                </div>
                                <div className="flex gap-3 mt-4">
                                    <div className="flex flex-col justify-center items-center px-1 py-1.5 bg-white shadow-xl h-[22px] rounded-[100px] w-[22px]">
                                        <div className="flex justify-center items-center p-1">
                                            <img
                                                loading="lazy"
                                                src={img_seta}
                                                className="w-2 aspect-[1.59]"
                                            />
                                        </div>
                                    </div>
                                    <div className="text-sm leading-5 text-white font-[5]">
                                        Dados de Marketing & Vendas
                                    </div>
                                </div>
                                <div className="flex gap-3 mt-4">
                                    <div className="flex flex-col justify-center items-center px-1 py-1.5 bg-white shadow-xl h-[22px] rounded-[100px] w-[22px]">
                                        <div className="flex justify-center items-center p-1">
                                            <img
                                                loading="lazy"
                                                src={img_seta}
                                                className="w-2 aspect-[1.59]"
                                            />
                                        </div>
                                    </div>
                                    <div className="text-sm leading-5 text-white font-[5]">
                                        Indicadores de Performance & CRM
                                    </div>
                                </div>
                                <div className="flex gap-3 mt-4">
                                    <div className="flex flex-col justify-center items-center px-1 py-1.5 bg-white shadow-xl h-[22px] rounded-[100px] w-[22px]">
                                        <div className="flex justify-center items-center p-1">
                                            <img
                                                loading="lazy"
                                                src={img_seta}
                                                className="w-2 aspect-[1.59]"
                                            />
                                        </div>
                                    </div>
                                    <div className="text-sm leading-5 text-white font-[5]">
                                        Alcance de Encantamento
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}