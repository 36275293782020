const cartTypes = {
  START_CART_PAYMENT: 'START_CART_PAYMENT',
  // donation
  START_MAKE_DONATION: 'START_MAKE_DONATION',
  SET_TOASTR_TXT: 'SET_TOASTR_TXT',
  SET_DONATION_RESULT: 'SET_DONATION_RESULT',
  START_MAKE_PIX: 'START_MAKE_PIX',
  SET_PIX_RESULT: 'SET_PIX_RESULT',
  CLEAR_CART: 'CLEAR_CART',
};

export default cartTypes;
