import cartTypes from "./cart.types";

// main page all products & causes

// make donation

export const startMakeDonation = (payload) => {
  // console.log('action data : ', payload);
  return {
    type: cartTypes.START_MAKE_DONATION,
    payload: payload,
  };
};

export const setDonationResult = (payload) => {
  return { type: cartTypes.SET_DONATION_RESULT, payload: payload };
};

export const startMakePix = (payload) => {
  // console.log('action data : ', payload);
  return {
    type: cartTypes.START_MAKE_PIX,
    payload: payload,
  };
};

export const setPixResult = (payload) => {
  // console.log('set pix result action', payload)
  return { type: cartTypes.SET_PIX_RESULT, payload: payload };
};
// cart payment

export const startCartPayment = (payload) => ({
  type: cartTypes.START_CART_PAYMENT,
  payload,
});

export const setToastrTxt = (payload) => {
  // console.log(' payl ...   ', payload);
  return {
    type: cartTypes.SET_TOASTR_TXT,
    payload: payload,
  };
};

export const clearCart = () => ({
  type: cartTypes.CLEAR_CART
});
