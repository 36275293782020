import React, { useEffect } from "react";
import { connect } from "react-redux";
import CauseCard from "../Market/CauseCard";
import { startGetSelectedCategoryData } from "../../redux/Market/market.actions";
import { useTranslation } from "react-i18next";

export const OtherCauses = ({
  selectedCause,
  dispatchStartGetSelectedCategoryData,
  selectedCategoryData,
  causesArray,
}) => {
  const { t, i18n } = useTranslation();

  let otherCauses =
    selectedCategoryData?.length > 4 &&
    typeof selectedCategoryData !== "string"
      ? selectedCategoryData.slice(0, 4)
      : selectedCategoryData?.length &&
        typeof selectedCategoryData !== "string"
      ? selectedCategoryData
      : causesArray;
  const causeCategoryNo = selectedCause?.categories;

  useEffect(() => {
    if (causeCategoryNo) {
      dispatchStartGetSelectedCategoryData({
        id: causeCategoryNo,
        type: "cause",
      });
    }
  }, []);

  return (
    <div className="bg-bodyBg -mb-24 pb-4">
      <div className="mx-auto container">
        <h4 className="font-VolksDemiBold text-xl container pt-12">
          {t("cause.others")}
        </h4>
        <div className="my-16">
          <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4  ">
            {otherCauses &&
              otherCauses.length &&
              otherCauses.map((oC) => (
                <CauseCard item={oC} key={oC?.id} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth, market }) => ({
  user: auth.user,
  selectedCause: market.selectedCause,
  selectedCategoryData: market.selectedCategoryData,
  causesArray: market.causesArray,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchStartGetSelectedCategoryData: (dt) =>
    dispatch(startGetSelectedCategoryData(dt)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OtherCauses);
