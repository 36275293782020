import profileTypes from "./profile.types";

export const setUserTransactions = (data) => ({
  type: profileTypes.SET_USER_TRANSACTIONS,
  payload: data,
});

export const setForeignUser = (data) => ({
  type: profileTypes.SET_FOREIGN_USER,
  payload: data,
});

export const startGetForeignTransactions = (dataId, nextURL) => ({
  type: profileTypes.START_GET_FOREIGN_TRANSACTIONS,
  payload: {
    foreignId: dataId?.foreignUserId | dataId,
    nextURL: nextURL,
  },
});

export const clearTransactions = () => ({
  type: profileTypes.CLEAR_TRANSACTIONS,
});

export const setUserCauses = (data) => ({
  type: profileTypes.SET_USER_CAUSES,
  payload: data,
});

export const startGetUserTransactions = (data) => ({
  type: profileTypes.START_GET_USER_TRANSACTIONS,
  payload: data,
});

export const startGetUserCauses = () => ({
  type: profileTypes.START_GET_USER_CAUSES,
});

// set toastr

export const setToastrTxt = (payload) => {
  // console.log(" payl ...   ", payload);
  return {
    type: profileTypes.SET_TOASTR_TXT,
    payload: payload,
  };
};

export const resetProfile = () => {
  return {
    type: profileTypes.RESET,
  };
};

// profile update

export const startUpdateProfile = (profile) => {
  return {
    type: profileTypes.START_UPDATE_PROFILE,
    payload: profile,
  };
};

export const setProfileData = (data) => {
  return {
    type: profileTypes.SET_PROFILE_DATA,
    payload: data,
  };
};

export const setGetAddressByPostalCode = (postalCode) => {
  return {
    type: profileTypes.START_GET_ADDRESS_BY_POSTAL_CODE,
    payload: postalCode,
  };
}
