import React from "react";
import { connect } from "react-redux";
import { AuthHeader } from "../../components/Auth/AuthHeader";
import MainHeader from "../../components/MainHeader";
import DiscoverMarket from "../../components/Static/DiscoverMarket";
import OurCommunity from "../../components/Static/OurCommunity";
import NgoSolutionStatic from "../../components/Static/NgoSolutionStatic";
import { Footer } from "../../components/Footer";
import user_static from "../../assets/user_static.png";
import badges from "../../assets/badges.png";
import lets_go from "../../assets/lets_go.png";
import ngo_hero from "../../assets/ngo_hero.png";
import { IoDocumentTextOutline } from "react-icons/io5";
import { FaUserCheck } from "react-icons/fa";
import { AiFillHeart } from "react-icons/ai";
import { BiChevronRight } from "react-icons/bi";
import colors from "../../constants/colors";
import { Redirect, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const IamNgo = ({ user }) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  
  document.title = "magikk - ONGs";

  return (
    <div className="bg-white">
      {user ? <MainHeader /> : <AuthHeader />}
      <div className="container mx-auto flex flex-col lg:flex-row mt-8 lg:mt-12 pb-12">
        <div className="flex-1 pr-4 lg:pr-32 flex flex-col lg:flex-row justify-between items-center">
          <div className="w-full lg:w-3/12 h-full items-center justify-center flex">
            <div className="w-full hidden sm:block h-96 bg-primary rounded-l-kkxl"></div>
          </div>
          <img src={ngo_hero} className="w-full lg:w-9/12 h-auto rounded-kkxl shadow-md" alt="ngo hero"/>
        </div>

        <div className="flex-1 bg-white flex flex-col items-start justify-center p-4 lg:p-8 mt-4 lg:mt-0">
          <h3 className="font-VolksMedium text-3xl sm:text-4xl mb-2 lg:mb-4 leading-relaxed">
            {t("i_am_ngo.hero_title")}
          </h3>
          <p className="font-LatoRegular font-bold text-sm sm:text-base text-grey my-2 lg:my-4">
            {t("i_am_ngo.hero_subtitle")}
          </p>
          <p className="font-LatoRegular text-xs sm:text-sm text-grey mt-2 lg:mt-4 mb-1 lg:mb-2">
            {t("i_am_ngo.hero_txt")}
          </p>
          <div
            onClick={() => {
              history.push("register");
            }}
            className="btn-1 btn-effects mt-4 lg:mt-12 py-2 pr-auto self-start"
          >
            {t("entrance.be_magikk")}
          </div>
        </div>
      </div>
      <div className="mt-4 lg:mt-8 px-4 lg:px-48">
        <img src={badges} className="w-full" alt="badges"/>
      </div>
      <div className="bg-bodyBg pt-8 lg:pt-16 mt-8 lg:mt-16 mb-8 lg:mb-16">
        <div className="flex flex-col lg:flex-row items-center container mx-auto mr-4 lg:mr-24 mt-4 lg:mt-12">
          <div className="flex-1 px-4 lg:px-12 flex flex-col items-start justify-around mb-4 lg:mb-0">
            <h4 className="text-xs sm:text-sm mb-2 lg:mb-4">First Steps</h4>
            <h4 className="font-VolksDemiBold mb-2 lg:mb-4 text-xl sm:text-4xl leading-relaxed">
              how to register Your NGO?
            </h4>
            <p className="font-LatoRegular text-xs sm:text-base text-grey my-2 lg:my-4">
              In a professional context it often happens that private or unknown plants are noticed by when I hear the buzz of the little world among the stalks,
            </p>
          </div>
          <div className="w-full lg:w-1/2 flex flex-col lg:flex-row">
            <div className="px-4 lg:px-8">
              <div className="flex flex-col h-64 w-full lg:w-64 justify-around p-4 lg:p-8 items-start rounded-ftxl mb-4 bg-white shadow-md">
                <div className="rounded-full bg-primary flex items-center justify-center w-16 lg:w-20 h-16 lg:h-20">
                  <IoDocumentTextOutline color={"white"} size={24} />
                </div>
                <div className="font-VolksDemiBold text-base sm:text-lg lg:text-xl mb-2 lg:mb-4">sign up</div>
                <div className="text-xs sm:text-sm font-LatoRegular">
                  Fill out’s company forms and choose your sustainable development goals
                </div>
              </div>        <div className="flex flex-col h-64 w-full lg:w-64 justify-around p-4 lg:p-8 items-start rounded-ftxl mb-4 bg-white shadow-md">
                <div className="rounded-full bg-primary flex items-center justify-center w-16 lg:w-20 h-16 lg:h-20">
                  <AiFillHeart color={"white"} size={24} />
                </div>
                <div className="font-VolksDemiBold text-base sm:text-lg lg:text-xl mb-2 lg:mb-4">be magikk</div>
                <div className="text-xs sm:text-sm font-LatoRegular">
                  Fill out’s company forms and choose your sustainable development goals
                </div>
              </div>
            </div>
            <div className="w-full lg:w-1/2 pt-4 lg:pt-24 px-4 lg:px-0">
              <div className="flex flex-col h-64 w-full lg:w-64 justify-around p-4 lg:p-8 items-start rounded-ftxl mb-4 bg-white shadow-md">
                <div className="rounded-full bg-primary flex items-center justify-center w-16 lg:w-20 h-16 lg:h-20">
                  <FaUserCheck color={"white"} size={24} />
                </div>
                <div className="font-VolksDemiBold text-base sm:text-lg lg:text-xl mb-2 lg:mb-4">
                  magikk review
                </div>
                <div className="text-xs sm:text-sm font-LatoRegular">
                  Fill out’s company forms and choose your sustainable development goals
                </div>
              </div>
              <div className="flex flex-col h-64 w-full lg:w-64 justify-around p-4 lg:p-8 items-start rounded-ftxl mb-4 bg-primary shadow-md">
                <div className="ml-auto flex items-center justify-center w-20 h-20 lg:w-24 lg:h-24">
                  <img src={lets_go} className="w-full h-full" alt="lets go"/>
                </div>
                <div className="font-VolksDemiBold text-white text-base sm:text-lg lg:text-xl mb-2 lg:mb-4">
                  Let's go
                </div>
                <div className="text-xs sm:text-sm font-LatoRegular text-white w-full lg:w-4/5">
                  Fill out’s company forms and choose your sustainable development goals
                </div>
                <div
                  className="bg-white rounded-md btn-effects w-8 h-8 lg:w-10 lg:h-10 flex items-center justify-center"
                  onClick={() => {
                    history.push("/login");
                  }}
                >
                  <BiChevronRight color={colors.theme} size={21} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <DiscoverMarket /> */}
        <NgoSolutionStatic />
        <div className="w-full bg-white">
          <OurCommunity />
        </div>
      </div>
      <Footer />
    </div>
  );

};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(IamNgo);
