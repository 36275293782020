import React from "react";
import { connect } from "react-redux";
import { CgShoppingCart } from "react-icons/cg";
import colors from "../../constants/colors";
import { setToastrTxt } from "../../redux/Profile/profile.actions";
import { useTranslation } from "react-i18next";
import PriceSet from "../Shared/PriceSet";
import { AiOutlineLink } from "react-icons/ai";
import { HiBadgeCheck } from "react-icons/hi";
import { GiModernCity } from "react-icons/gi";
import { useHistory } from "react-router-dom";
import ShareButton from "../../components/Share/Button";
import moment from "moment";
import CustomModal from "../../components/Shared/CustomModal";
// import { BASE_URL } from "../../config/env";

export const ProductInfo = ({
  product,
  isUser,
  dispatchSetToastrTxt,
  user =null
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [piece, setPiece] = React.useState(1);
  const amount = product?.amount;
  const newGood = [product];
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const toggleModalVisibility = () => {
    setIsModalVisible(!isModalVisible);
  };

  const goToCart = () => {
    history.push("/cart");
  };

  const organizationName =
    product?.organization?.name ||
    `${product?.user?.firstname} ${product?.user?.lastname}`;

  const getOrgImg = () => {
    if (product?.organization?.logo) {
      // logo magikkupload mı?
      return (
        <img
          className="w-16 h-16 rounded-full mr-2 border-black border object-fit contain"
          src={product?.organization?.logo}
          style={{ marginRight: 9 }}
          alt="product organization logo"
        />
      );
    }
    // else if (product?.images?.length) {

    //   return <img src={`${BASE_URL}${product?.images[0]?.url}`} />;
    // }
    else {
      <HiBadgeCheck size={21} style={{ marginRight: 10 }} />;
    }
  };

  const orgImgSrc = getOrgImg();

  const addToCart = () => {


    if(product.adult_only && user.birthdate === null){
      dispatchSetToastrTxt({
        title: t("cart.birthdate"),
        txt: t("cart.birthdate_null"),
      });
      return false;
    }

    let now = moment();
    let userBirthDate = moment(user.birthdate);

    const yearsDifference = now.diff(userBirthDate, 'years');

    if(product.adult_only && yearsDifference < 18){
      dispatchSetToastrTxt({
        title: t("cart.adult"),
        txt: t("cart.adult_only"),
      });
      return false;
    }

    const result = localStorage.getItem("cart");

    if (result) {
      const currentCartArray = JSON.parse(result);
      const inCart = currentCartArray.find(
        (crt) => crt.id == product.id
      );

      let newArray = [];
      if (inCart) {
        // arrayde var
        if (inCart.piece) {
          newArray = currentCartArray.map((onCartItem) => {
            if (inCart.id == onCartItem.id) {
              onCartItem.piece += piece;
            }

            return onCartItem;
          });
        } else {
          // no piece prop
          //alert("piece yok");
          newArray = currentCartArray.map((onCartItem) => {
            if (inCart.id == onCartItem.id) {
              onCartItem.piece = 1;
            }

            return onCartItem;
          });
        }
      } else {
        newGood[0].piece = piece;
        newArray = JSON.parse(result).concat(newGood);
      }

      if (newArray && newArray[0]) {
        localStorage.setItem("cart", JSON.stringify(newArray));
      }
    } else {
      newGood[0].piece = piece;
      localStorage.setItem("cart", JSON.stringify(newGood));
    }
    if (!isUser) {
      history.push("/login");
    }
    toggleModalVisibility();

  };

  React.useEffect(() => {
    // console.log(' inside useeffect ..    ', product);
    document.title = `magikk - loja - ${product?.title}`;
  }, [product]);

  const htmlProductDescription = {__html:product?.description};

  return (
    <div className="flex flex-col w-full md:w-5/12 justify-between pl-5 text-sm">
      <div className="flex flex-row">
        <div className="flex flex-col">
          <div className="font-VolksMedium text-3xl">
            {product?.title}
          </div>

          <div className="font-LatoRegular text-greyText flex flex-row my-3 items-center">
            {orgImgSrc}
            {organizationName}
          </div>
        </div>
        <div className="ml-auto">
          <PriceSet price={product?.price} karma={product?.karma} />
        </div>
      </div>
      <div className="border-t-2"></div>
      <div className="flex flex-col">
        <div className="font-VolksMedium text-xl mb-2">
          {t("product.descr")}
        </div>
        <div className="font-LatoRegular" dangerouslySetInnerHTML={htmlProductDescription} />
      </div>
      <div className="flex flex-row w-full justify-between my-4">
        <div className="w-5/12">
          <div className="font-VolksMedium text-xl mb-4">
            {t("product.seller")}
          </div>
          <div className="font-LatoRegular">
            <div className="flex flex-row mb-3">
              <HiBadgeCheck size={21} />
              {/* // to do logo */}
              <span className="ml-2 font-bold underline">
                {organizationName}
              </span>
            </div>
            <div className="flex flex-row mb-3 underline text-blue-500 hover:text-black">
              <AiOutlineLink size={21} />

              <a
                target="_blank"
                rel="noreferrer"
                className="ml-2 cursor-pointer"
                href={product?.organization?.website}
              >
                web
              </a>
            </div>
            <div className="flex flex-row mb-3 ">
              <GiModernCity size={21} />
              <span className="ml-2">
                {
                  product?.organization
                    ?.delivery_information_organization?.city
                }
              </span>
            </div>
          </div>
        </div>
        <div className="w-5/12">
          {/* <div className="font-VolksMedium text-xl mb-4">
            {t("product.Retrieve_it")}
          </div>
          <div className="font-LatoRegular">
            {
              "There are many variations passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected or randomised words which don't look even slightly believable If you are going to use ."
            }
          </div> */}
        </div>
      </div>

      <div className="flex flex-row w-full justify-between mt-4 font-LatoBold">
        <div className="flex flex-row font-LatoRegular text-4xl items-center">
          <div
            className="btn-effects"
            onClick={() => {
              if (piece > 1) {
                setPiece(piece - 1);
              }
            }}
          >
            -
          </div>
          <div className="rounded-xl border mx-4 border-textGrey py-2 px-4 text-2xl">
            {piece}
          </div>
          <div
            className="btn-effects"
            onClick={() => {
              if (piece < amount) {
                setPiece(piece + 1);
              }
            }}
          >
            +
          </div>
        </div>
        <div
          className="btn-1 font-LatoRegular flex-row justify-between px-2 btn-effects"
          onClick={addToCart}
        >
          {t("product.add_cart")}
          <div className="bg-white p-1 rounded">
            <CgShoppingCart size={15} color={colors.theme} />
          </div>
        </div>
      </div>
      <ShareButton 
      buttons={['facebook','twitter','linkedin','whatsapp']} 
      title={`magikk - ${product?.title}`} 
      page={ `/product/${product?.id}`}
      summaryText={product?.description}
      />
      <CustomModal
        isVisible={isModalVisible}
        message={t("product.continue_shopping_or_go_to_cart")}
        onConfirm={goToCart}
        onCancel={toggleModalVisibility}
        confirmText={t("product.go_to_cart")}
        cancelText={t("product.continue_shopping")}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchSetToastrTxt: (_txt) => dispatch(setToastrTxt(_txt)),
  };
};

export default connect(null, mapDispatchToProps)(ProductInfo);
