import React, { useEffect, useRef, useState } from "react";
import pattern_hero from "../../assets/pattern_hero.png";
import fakeAvatar from "../../assets/avatar.jpg";
import loaderGif from "../../assets/spinner.gif";
import { connect } from "react-redux";
// import { BASE_URL } from "../../config/env";
import { HiPencil } from "react-icons/hi";
import colors from "../../constants/colors";
import { checkCurrentUser } from "../../redux/Auth/auth.actions";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { updateProfileImage } from "../../redux/Profile/profile.helpers";
import profileSagas from "../../redux/Profile/profile.sagas";

function ProfileHero({
  user,
  foreignUser,
  loading,
  isForeign,
  transferCount,
  isProfilePage,
}) {
  const { t, i18n } = useTranslation();

  const [loader, setLoader] = useState(loading);
  const dispatch = useDispatch();
  const inputEl = useRef(null);
  const [currentUser, setCurrentUser] = useState(user);

  useEffect(() => {
    dispatch(checkCurrentUser());
    //console.log("Current Foreign", foreignUser);
  }, []);

  useEffect(() => {
    if (isForeign) {
      setCurrentUser(foreignUser);
      setAvatar(
        !foreignUser?.avatar ||
          (foreignUser?.avatar &&
            foreignUser.avatar.startsWith("magikk"))
          ? fakeAvatar
          : `${process.env.REACT_APP_API_URI}/${foreignUser?.avatar}`
      );
    }
    // console.log("Current User", currentUser);
  }, [foreignUser, currentUser, user]);

  let defaultAvatar =
    !currentUser?.avatar ||
    (currentUser?.avatar && currentUser.avatar.startsWith("magikk"))
      ? fakeAvatar
      : `${process.env.REACT_APP_API_URI}/${currentUser?.avatar}`;

  const [avatar, setAvatar] = useState(defaultAvatar);

  const sendFileToServer = (file) => {
    setLoader(true);
    var formData = new FormData();
    formData.append("avatar", file);

    updateProfileImage(formData, (_avatar) => {
      const new_avatar = `${process.env.REACT_APP_API_URI}/${_avatar}`;
      setAvatar(new_avatar);
      setLoader(false);
    });
  };

  const causes_length =
    currentUser?.attended_causes_count ||
    currentUser?.attended_causes?.length;

    return (
      <div className="mx-auto container px-4 sm:px-6 lg:px-8">
        <div
          style={{ backgroundImage: `url(${pattern_hero})` }}
          className="bg-no-repeat bg-center overflow-hidden responsive bg-primary h-80 rounded-ftxl flex flex-col font-VolksDemiBold text-white mt-2"
        >
          <div className="h-64 my-16 ">
            <div
              style={{
                maxWidth: 1000,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <div className="flex-1 flex items-center justify-center text-center text-4xl sm:text-5xl mb-8">
                <h3 className="mt-4 hidden sm:block">
                  {isForeign
                    ? currentUser?.firstname +
                      " " +
                      currentUser?.lastname
                    : isProfilePage ? t("profile.my_profile") : t("profile.public_profile")}
                </h3>
              </div>
              <div className="flex flex-row justify-center text-center mb-8 mt-16">
                <div className="text-2xl sm:text-3xl ml-4 sm:ml-60 mr-auto">
                  <h3>{transferCount ?? 0}</h3>
                  <h4 className="text-lg sm:text-xl font-LatoRegular text-secondary">
                    {t("profile.good_deeds")}
                  </h4>
                </div>
                <div className="text-2xl sm:text-3xl">
                  <h3>{currentUser?.donations_count ?? 0}</h3>
                  <h4 className="text-lg sm:text-xl font-LatoRegular text-secondary">
                    {t("profile.donations")}
                  </h4>
                </div>
                <div className="text-2xl sm:text-3xl mr-4 sm:mr-60 ml-auto">
                  <h3>{causes_length ?? 0}</h3>
                  <h4 className="text-lg sm:text-xl font-LatoRegular text-secondary">
                    {t("market.causes")}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className={`absolute w-32 h-32 sm:w-48 sm:h-48 border-4 border-secondary rounded-full ${isProfilePage? 'top-40' : 'hidden md:block top-72'} ml-10`}>
            <img
              className="w-full h-full rounded-full object-cover"
              src={loading ? loaderGif : avatar}
            />
            {!isForeign && (
              <input
                type="file"
                onChange={(e) => {
                  sendFileToServer(e.target.files[0]);
                }}
                className="hidden"
                ref={inputEl}
              />
            )}
            {!isForeign && (
              <div
                onClick={() => {
                  inputEl.current.click();
                }}
                className="btn-effects bg-secondary absolute w-12 h-12 rounded-full inset-3/4 flex items-center justify-center"
              >
                <HiPencil size={30} color={colors.theme} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
}

const mapState = ({ auth, profile }) => ({
  foreignUser: profile.foreignUser,
  user: auth.user,
  loading: auth.loading,
});

export default connect(mapState)(ProfileHero);
