import React, { useState, useEffect } from "react";
import { getItemSettings } from "../../constants/sustainableConfig";
import { GiRollingEnergy } from "react-icons/gi";

function SustainableItem({
  item,
  selectable,
  isSelected,
  setUserSusList,
  userSusList,
}) {
  const settings = getItemSettings(item.id);
  const { color, icon } = settings;

  return (
    <div
      className="w-full capitalize h-14 text-white font-LatoRegular font-bold flex md:flex-row items-center justify-start pl-4 rounded-2xl"
      style={{ backgroundColor: color }}
    >
      {icon || <GiRollingEnergy size={28} color={"white"} />}

      {localStorage.getItem("lang") === "en-us" ? (
        <p className="md:ml-4 text-sm md:text-base">{item.name}</p>
      ) : (
        <p className="md:ml-4 text-sm md:text-base">{item.name_br}</p>
      )}

      {selectable && (
        <input
          type="checkbox"
          className="ml-auto mr-4"
          checked={isSelected}
          onChange={(e) => {
            var newArray = userSusList?.filter(
              (sg) => sg !== item.id
            );
            if (e.target.checked) {
              newArray?.push(item.id);
            }
            //console.log(newArray);
            setUserSusList(newArray);
          }}
        />
      )}
    </div>
  );
}

export default SustainableItem;
