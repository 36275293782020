import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AuthRoute from "./hoc/AuthRoute";
import { connect } from "react-redux";
import { checkCurrentUser, setLang } from "./redux/Auth/auth.actions";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import Market from "./pages/Market.js";
import Profile from "./pages/Profile.js";
import PublicProfile from "./pages/PublicProfile.js";
import ForeignProfile from "./pages/ForeignProfile.js";
import ProductDetail from "./pages/ProductDetail.js";
import CauseDetail from "./pages/CauseDetail.js";
import AccountSettings from "./pages/AccountSettings.js";
import axios from "axios";
import Cart from "./pages/Cart";
import Donation from "./pages/Donation";
import DonationSuccess from "./pages/DonationSuccess";
import PaymentSuccess from "./pages/PaymentSuccess";
import SetSustainableGoals from "./pages/Auth/SetSustainableGoals";
import CreateSuccess from "./pages/Auth/CreateSuccess";
import Entrance from "./pages/Static/Entrance";
import IamNgo from "./pages/Static/IamNgo";
import IamCompany from "./pages/Static/IamCompany";
import { ForgetPassword } from "./pages/Auth/ForgetPassword";
import ScrollToTop from "./components/Shared/ScrollToTop";
import Mobile from "./components/Mobile";
import TermOfUse from "./pages/Static/TermOfUse";
import PrivacyPolicy from "./pages/Static/PrivacyPolicy";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import { useCookies } from 'react-cookie';
import NewLandingPage from "./pages/Static/NewLandingPage.js";
import CompanyForm from "./pages/Static/CompanyForm.js";


axios.interceptors.request.use(
  async (config) => {
    let token = localStorage.getItem("token");
    token = token?.replace(/['"]+/g, "");
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// Import the functions you need from the SDKs you need

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId:
    process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase

function App({ dispatchCheckCurrentUser, dispatchSetLang }) {
  const [cookies, setCookie] = useCookies(['magikk_lgpd']);


  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
  useEffect(() => {
    document.title = "magikk";
    try {

      if (cookies.magikk_lgpd === 'true') {
          firebase.analytics().setAnalyticsCollectionEnabled(true);
          firebase.analytics().logEvent('cookies_permitidos');
      } else {
          firebase.analytics().logEvent('cookies_recusados');
          firebase.analytics().setAnalyticsCollectionEnabled(false);
      }
  } catch (e) {
      console.log("Firebase initializing error", e);
  }


  }, [cookies]);


  //gera um numero aleatorio entre 10 e 300
  // const randomNumber = Math.floor(Math.random() * 200) + 10;
  //reload page after 2 seconds
  // setTimeout(() => {window.location.reload(); }, (randomNumber*1000));


  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      dispatchCheckCurrentUser();
    }
  }, []);

  useEffect(() => {
    const lang = localStorage.getItem("lang");

    // console.log(" inside App.js : lang : ", lang);
    if (lang) {
      dispatchSetLang(lang);
    }
  }, []);

  // if (isMobile) {
  //   return <Mobile />;
  // }

  // console.log("*****API", process.env.REACT_APP_API_URI);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <AuthRoute exact path="/">
          <Market />
        </AuthRoute>
        <AuthRoute exact path="/profile">
          <Profile />
        </AuthRoute>
        <AuthRoute exact path="/public-profile">
          <PublicProfile />
        </AuthRoute>
        <Route exact path="/user-profile/:id">
          <ForeignProfile />
        </Route>
        <AuthRoute exact path="/cart">
          <Cart />
        </AuthRoute>
        <AuthRoute exact path="/donation">
          <Donation />
        </AuthRoute>
        <AuthRoute exact path="/donation-success">
          <DonationSuccess />
        </AuthRoute>
        <AuthRoute exact path="/payment-success">
          <PaymentSuccess />
        </AuthRoute>
        <AuthRoute exact path="/account-settings">
          <AccountSettings />
        </AuthRoute>
        {/* <Route path="/entrance">
          <Entrance />
        </Route> */}
        <Route path="/entrance">
          <NewLandingPage />
        </Route>
        <Route path="/company-form">
          <CompanyForm />
        </Route>
        <Route path="/i-am-ngo">
          <IamNgo />
        </Route>
        <Route path="/i-am-company">
          <IamCompany />
        </Route>
        <Route path="/term-of-use">
          <TermOfUse />
        </Route>
        <Route path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/forget-password">
          <ForgetPassword />
        </Route>
        <Route path="/register">
          <Register />
        </Route>
        <Route path="/set-sustainable-goals">
          <SetSustainableGoals />
        </Route>
        <Route path="/create-success">
          <CreateSuccess />
        </Route>
        <Route path="/market">
          <Market />
        </Route>
        <Route path="/product-detail/:id">
          <ProductDetail />
        </Route>

        <Route path="/cause-detail/:id">
          <CauseDetail />
        </Route>
        <Route path="/ngo">
          <Login />
        </Route>
        <Route path="/company">
          <Login />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

const mapState = ({ auth }) => ({
  user: auth.user,
  loading: auth.loading,
});

const mapDispatch = (dispatch) => ({
  dispatchCheckCurrentUser: () => dispatch(checkCurrentUser()),
  dispatchSetLang: (lng) => dispatch(setLang(lng)),
});

export default connect(mapState, mapDispatch)(App);
