import React from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  startGetProduct,
  startGetCause,
} from "../../../redux/Market/market.actions";
import { useTranslation } from "react-i18next";
import { startGetForeignTransactions } from "../../../redux/Profile/profile.actions";

export const SearchResults = ({ items, style }) => {
  const dispatch = useDispatch(null);
  const history = useHistory();
  const handleClick = (_item) => {
    if (!!!localStorage.getItem("token"))
      return history.push("/login");
    const item = _item;
    switch (item.type) {
      case "Product":
        dispatch(startGetProduct(item.id));
        history.push(`/product-detail/${item.id}`);
        break;
      case "Cause":
        dispatch(startGetCause(item.id));
        history.push(`/cause-detail/${item.id}`);
        break;
      case "Organization":
        dispatch(
          startGetForeignTransactions({ foreignUserId: item.id }),
        );
        history.push(`/user-profile/${item.id}`);
        break;

      case "User":
        dispatch(
          startGetForeignTransactions({ foreignUserId: item.id }),
        );
        history.push(`/user-profile/${item.id}`);
        break;

      default:
        break;
    }
  };

  if (items?.length === 0) {
    return null;
  }

  return (
    <div
      className={`w-8/12 absolute left-0 right-0 ml-auto mr-auto pl-6 pr-2 ${style}`}
    >
      <div className="bg-transparent w-100 max-h-64 mr-4 rounded-ftxl shadow-md mt-1 overflow-y-scroll">
        {items?.map((item) => {
          return (
            <div
              onClick={() => handleClick(item)}
              className="btn-effects active:bg-primary active:opacity-90 hover:bg-bodyBg flex bg-white flex-row px-6 justify-between pb-3 pt-3"
            >
              <div className="w-7/12 whitespace-nowrap truncate font-VolksDemiBold">
                {item.txt}
              </div>
              <div className="text-gray-400">{item.type}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = ({ market }) => ({
  search: market.search,
});

export default connect(mapStateToProps, null)(SearchResults);
