import {
  all,
  call,
  put,
  takeLatest,
  delay,
} from "redux-saga/effects";
import {
  setUserTransactions,
  setUserCauses,
  setProfileData,
  setForeignUser,
  setToastrTxt,
  setGetAddressByPostalCode

} from "./profile.actions";
import profileTypes from "./profile.types";
import {
  handleGetUserTransactions,
  handleGetForeignTransactions,
  handleGetUserCauses,
  handleUpdateProfile,
  handleGetAddressByPostalCode,
} from "./profile.helpers";

// get transactions

// GET foreign TRANSACTIONS

function* startGetForeignTransactions({ payload }) {
  //console.log(' start get userrr transactions ... .. . . .', payload);
  try {
    //console.log("Payload", payload);
    const data = yield handleGetForeignTransactions(payload);
    //console.log("Data", data);
    if (data) {
      // console.log("get user trans data...", data); // good obj
      yield put(setForeignUser(data));
    } else {
      // yield put(
      //   setToastrTxt({
      //     title: 'Foreign Transactions',
      //     txt: '',
      //   }),
      // );
      // yield delay(2000);
      // yield put(setToastrTxt());
      //alert("no data trans get foreig 0323");
    }
  } catch (err) {
    //console.log('get fore transactions error', err);
  }
}

export function* onStartGetForeignTransactions() {
  yield takeLatest(
    profileTypes.START_GET_FOREIGN_TRANSACTIONS,
    startGetForeignTransactions
  );
}

// GET USER TRANSACTIONS

function* startGetUserTransactions({ payload }) {
  //console.log(' start get userrr transactions ... .. . . .');
  try {
    const data = yield handleGetUserTransactions(payload);

    if (data) {
      // console.log("get user trans data...", data); // good obj
      //console.log(" user transactions setted : ", data);
      yield put(setUserTransactions(data));
    } else {
      // yield put(
      //   setToastrTxt({
      //     title: 'Volunteer',
      //     txt: 'Error while cancelling',
      //   }),
      // );
      // yield delay(2000);
      // yield put(setToastrTxt());
      //alert('no data trans get user 0323');
    }
  } catch (err) {
    //console.log('get user transactions error', err);
  }
}

export function* onStartGetUserTransactions() {
  yield takeLatest(
    profileTypes.START_GET_USER_TRANSACTIONS,
    startGetUserTransactions
  );
}

// GET USER causes

function* startGetUserCauses({ payload }) {
  try {
    const data = yield handleGetUserCauses(payload);

    if (data) {
      //console.log('get user cause data...', data); // good obj
      const attended_causes = !!data ? data[0]?.attended_causes : [];
      yield put(setUserCauses(attended_causes));
    } else {
      //alert('no  cause get user 0323');
    }
  } catch (err) {
    //console.log('get user cause error', err);
  }
}

export function* onStartGetUserCauses() {
  yield takeLatest(
    profileTypes.START_GET_USER_CAUSES,
    startGetUserCauses
  );
}

// UPDATE PROFILE

function* startUpdateProfile({ payload }) {
  try {
    const data = yield handleUpdateProfile(payload);

    if (data) {
      //console.log('update profile data...', data); // good obj
      yield put(setProfileData(data));
    } else {
      //alert('Update profile error.');
      yield put(
        setToastrTxt({
          title: "Profile",
          txt: "Couldn't update profile",
          err: true,
        })
      );
      yield delay(2000);
      yield put(setToastrTxt());
    }
  } catch (err) {
    console.log("handle update profile error", err);
  }
}

export function* onStartUpdateProfile() {
  yield takeLatest(
    profileTypes.START_UPDATE_PROFILE,
    startUpdateProfile
  );
}

export function* onStartGetAddressByPostalCode() {
  yield takeLatest(
    profileTypes.START_GET_ADDRESS_BY_POSTAL_CODE,
    startGetAddressByPostalCode
  );
}

export function* startGetAddressByPostalCode({ payload }) {
  try {
    const data = yield handleGetAddressByPostalCode(payload);
    if(data){
      yield put(setGetAddressByPostalCode(data));
    }
      
  } catch (err) {
    console.log("handle get address by postal code error", err);
  }
}

export default function* profileSagas() {
  yield all([
    call(onStartGetUserTransactions),
    call(onStartGetForeignTransactions),
    call(onStartGetUserCauses),
    call(onStartUpdateProfile),
    call(onStartGetAddressByPostalCode),
  ]);
}
