import React from "react";
import { connect } from "react-redux";
import MainHeader from "../components/MainHeader";
import Gallery from "../components/ProductDetail/Gallery";
import ProductInfo from "../components/ProductDetail/ProductInfo";
import OtherProducts from "../components/ProductDetail/OtherProducts";
import Footer from "../components/Footer";
import { useParams } from "react-router-dom";
import {
  startGetProduct,
  startGetProducts,
} from "../redux/Market/market.actions";

export const ProductDetail = ({
  user,
  selectedProduct,
  startGetProductsData,
  dispatchStartGetProduct,
}) => {
  const { id } = useParams();
  // console.log("nav..par..", id);
  // console.log("user : ", user);

  React.useEffect(() => {
    // console.log(
    //   " inside useEEEEE selected prod .... .  . .       ",
    //   selectedProduct
    // );
    startGetProductsData();
    dispatchStartGetProduct(id);
  }, [id]);

  // product yoksa loading
  // product varsa set items

  return (
    <div className="bg-white">
      <MainHeader />
      <div className="flex flex-col md:flex-row container mx-auto py-8">
        <Gallery images={selectedProduct?.images} />
        <ProductInfo product={selectedProduct} isUser={!!user} user={user} />
      </div>
      <OtherProducts />
      <Footer />
    </div>
  );
};

const mapStateToProps = ({ auth, market }) => ({
  user: auth.user,
  selectedProduct: market.selectedProduct,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchStartGetProduct: (_id) => dispatch(startGetProduct(_id)),
  startGetProductsData: () => dispatch(startGetProducts()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductDetail);
