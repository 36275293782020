import React, { useState, useEffect, useRef } from "react";
import { BsArrowDown } from "react-icons/bs";
import { connect } from "react-redux";
import colors from "../../constants/colors";
import {
  handleMakeTransfer,
  getUserSuggestions,
} from "../../redux/Profile/profile.helpers";
import { setToastrTxt } from "../../redux/Profile/profile.actions";
import { useTranslation } from "react-i18next";
import UserSearchItem from "./UserSearchItem";

function PostGoodDeed({ dispatchSetToastrTxt }) {
  const { t, i18n } = useTranslation();

  const [transferAmount, setTransferAmount] = useState("");
  const [description, setDescription] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [error, showError] = useState(null);
  const [userTxt, setUserTxt] = useState("");
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (selectedUser?.firstname) {
      setUserTxt(`${selectedUser.firstname} ${selectedUser.lastname}`);
    }
  }, [selectedUser]);

  useEffect(() => {
    if (userTxt && userTxt.length > 2) {
      timeoutRef.current = setTimeout(() => {
        getUserSuggestions(userTxt, (res) => {
          if (res && res.length) {
            setFilteredUsers(res);
          } else {
            setFilteredUsers([]);
          }
        });
      }, 1250);

      return () => {
        clearTimeout(timeoutRef.current);
      };
    } else {
      setFilteredUsers([]);
    }
  }, [userTxt]);

  const onSubmit = async () => {
    const trObj = {
      amount: transferAmount,
      receiver_id:
        typeof selectedUser?.id === "string"
          ? parseInt(selectedUser.id)
          : selectedUser?.id,
      description,
      is_private: true,
    };

    const emptyKey = Object.keys(trObj).find((key) => !trObj[key]);

    if (emptyKey) {
      showError(emptyKey);
      return;
    }

    const result = await handleMakeTransfer(trObj);
    if (result.success) {
      dispatchSetToastrTxt({ title: result.message, txt: userTxt });
      setTransferAmount("");
      setDescription("");
      setSelectedUser(null);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      setTimeout(() => {
        dispatchSetToastrTxt();
      }, 3000);
    } else {
      console.log("error", result);
    }
  };

  return (
    <div className="flex flex-col font-VolksDemiBold px-4 pt-4 pb-4">
      <div className="text-xl mb-4">{t("good_deed.good_deed")}</div>
      <input
        className="font-OpenSansRegular input-classic w-full rounded-xl pl-4"
        placeholder={"45 karma"}
        onFocus={() => {
          showError(null);
        }}
        value={transferAmount}
        onChange={(e) => {
          let txt1 = e.target.value.replace(/[^0-9]/g, "");
          txt1 = txt1 > 100000 ? 100000 : txt1;
          setTransferAmount(txt1);
        }}
      />
      {error === "amount" && (
        <span className="text-red-400 my-2">
          {t("good_deed.error")}
        </span>
      )}
      <div className="bg-secondary rounded-full p-2 flex items-center justify-center mr-auto my-4 ">
        <BsArrowDown
          size={30}
          color={colors.theme}
          className="font-bold"
        />
      </div>
      <div>
        <input
          className={`font-OpenSansRegular input-classic w-full ${
            error !== "receiver_id" && "mb-8"
          } rounded-xl p-2 pl-4`}
          onChange={(e) => setUserTxt(e.target.value)}
          value={userTxt}
          placeholder={"User name"}
          onFocus={() => {
            showError(null);
          }}
        />
        {error === "receiver_id" && (
          <div className="text-red-400 my-2">
            {t("good_deed.error")}
          </div>
        )}
        {filteredUsers?.length > 0 && (
          <div className="absolute bg-secondary w-1/4 flex flex-col items-center text-black border-2 rounded-lg z-112 left-12 -mt-2">
            {filteredUsers.map((f) => (
              <UserSearchItem
                key={f.id}
                user={f}
                onClick={() => {
                  setSelectedUser(f);
                  setFilteredUsers([]);
                }}
              />
            ))}
          </div>
        )}
      </div>

      <textarea
        rows={"5"}
        className={`p-2 font-OpenSansRegular border-borderGray rounded border w-full ${
          error !== "description" && "mb-4"
        } rounded-xl`}
        placeholder={t("good_deed.description")}
        value={description}
        onChange={(e) => {
          setDescription(e.target.value);
        }}
        onFocus={() => {
          showError(null);
        }}
      />
      {error === "description" && (
        <div className="text-red-400 my-2">
          {t("good_deed.error")}
        </div>
      )}

      <div className="btn-1 ml-auto btn-effects text-base" onClick={onSubmit}>
        {t("good_deed.share_the_love")}
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchSetToastrTxt: (_txt) => dispatch(setToastrTxt(_txt)),
  };
};

export default connect(null, mapDispatchToProps)(PostGoodDeed);
