import marketTypes from "./market.types";

// main page all products & causes

export const startGetProducts = () => {
  // console.log(" inside action ... ");
  return {
    type: marketTypes.START_GET_PRODUCTS,
  };
};

export const startAddProducts = (pageUrl) => {
  // console.log(" inside action ... ", pageUrl);
  return {
    type: marketTypes.START_ADD_PRODUCTS,
    payload: pageUrl,
  };
};

export const finishAddProducts = (prods) => {
  // console.log(" inside finish action ... ", prods);
  return {
    type: marketTypes.FINISH_ADD_PRODUCTS,
    payload: prods,
  };
};

export const finishAddCauses = (prods) => {
  // console.log(" inside finish causes ... ", prods);
  return {
    type: marketTypes.FINISH_ADD_CAUSES,
    payload: prods,
  };
};

export const setProducts = (products) => {
  return {
    type: marketTypes.SET_PRODUCTS,
    payload: products,
  };
};

export const startGetCauses = () => {
  return {
    type: marketTypes.START_GET_CAUSES,
  };
};

export const setCauses = (causes) => {
  return {
    type: marketTypes.SET_CAUSES,
    payload: causes,
  };
};

export const startAddCauses = (pageUrl) => {
  // console.log(" inside action ... ", pageUrl);
  return {
    type: marketTypes.START_ADD_CAUSES,
    payload: pageUrl,
  };
};

// one product

export const startGetProduct = (id) => {
  return {
    type: marketTypes.START_GET_PRODUCT,
    payload: id,
  };
};

export const setProduct = (product) => {
  return {
    type: marketTypes.SET_PRODUCT,
    payload: product,
  };
};

// one cause

export const startGetCause = (id) => {
  // console.log(" inside action CAuse ..... . . . . . .", id);
  return {
    type: marketTypes.START_GET_CAUSE,
    payload: id,
  };
};

export const setCause = (cause) => {
  return {
    type: marketTypes.SET_CAUSE,
    payload: cause,
  };
};

// all goals

export const startGetAllGoals = () => {
  return {
    type: marketTypes.START_GET_ALL_GOALS,
  };
};

export const setAllGoals = (goals) => {
  return {
    type: marketTypes.SET_ALL_GOALS,
    payload: goals,
  };
};

//
// categories

export const startGetCategories = () => {
  return {
    type: marketTypes.START_GET_CATEGORIES,
  };
};

export const setCategories = (categories) => {
  return {
    type: marketTypes.SET_CATEGORIES,
    payload: categories,
  };
};

// selected category details

export const startGetSelectedCategoryData = ({ type, id }) => {
  return {
    type: marketTypes.START_GET_SELECTED_CATEGORY_DATA,
    payload: { type, id },
  };
};

export const setSelectedCategoryData = (categoryData) => {
  return {
    type: marketTypes.SET_SELECTED_CATEGORY_DATA,
    payload: categoryData,
  };
};

// search data

export const startGetSearchData = (txt) => {
  return {
    type: marketTypes.START_GET_SEARCH_DATA,
    payload: txt,
  };
};

export const setSearchData = (array) => {
  return {
    type: marketTypes.SET_SEARCH_DATA,
    payload: array,
  };
};

// start volunteer

export const startFetchVolunteering = (id) => ({
  type: marketTypes.START_VOLUNTEERING,
  payload: id,
});

export const setVolunteeringMessage = (payload) => ({
  type: marketTypes.SET_VOLUNTEERING_MESSAGE,
  payload,
});
// cancel volunteer

export const startCancelVolunteer = (id) => {
  return {
    type: marketTypes.START_CANCEL_VOLUNTEER,
    payload: id,
  };
};
