import React from "react";
import img_seta from "../../assets/new-landing-page/seta.svg";
import img_karma from "../../assets/new-landing-page/builder/25f7552c-6777-4120-9a9c-98f9a1f10774.png";
import { useState } from "react";

import img_ods_1 from "../../assets/new-landing-page/builder/ce47a93e588216a65ef81faac8e0651cba134a3e450d76224ab01513ff183ba3.svg";
import img_ods_2 from "../../assets/new-landing-page/builder/b0b822ad6cb7792d80be855f41dcc531284ced053b16a1cfb95db445de6466e6.svg";
import img_ods_4 from "../../assets/new-landing-page/builder/c29b03507001e3f6fb0fd283e1f50328e5993d2ade690f1b66ddf5dbd80de55f.svg";
import img_ods_6 from "../../assets/new-landing-page/builder/fdf80b5de7aaec5b46298d67b14eed4abd003ef6c6101d8223afb980ef845f8c.svg";

import "./tailwind.js";

export const KarmaKredits = () => {

    const [showODS, setShowODS] = useState(false);


    return (
        <>
            <div className="self-center px-10 py-5 mt-20 w-full bg-white shadow-lg max-w-[1176px] rounded-[32px] max-md:px-5 max-md:mt-10 max-md:max-w-full" id="karma">

                {!showODS && (
                    <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                        <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                            <div className="flex gap-5 items-start self-stretch my-auto max-md:flex-wrap max-md:mt-10 max-md:max-w-full">
                                <div className="flex-auto self-start text-5xl font-[5] leading-[53.5px] text-cyan-950 max-md:text-4xl">
                                    <span className="text-4xl volkswagen-demi-bold" style={{
                                        fontWeight: "1000",
                                    }}>karma</span>{" "}
                                    <span className="text-3xl">kredits</span>
                                </div>
                                <div className="flex-auto self-end mt-6 text-sm leading-5 text-cyan-950">
                                    <span className="font-bold">Moeda social digital</span>{" "}
                                    complementar para estimular o impacto socioambiental e
                                    recompensar boas acoes.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
                            <div className="flex grow gap-5 max-md:flex-wrap max-md:mt-10 max-md:max-w-full">
                                <img
                                    loading="lazy"
                                    srcSet={img_karma}
                                    className="grow shrink-0 aspect-[2.33] basis-0 w-fit"
                                />
                                <img
                                    loading="lazy"
                                    src={img_seta}
                                    className="shrink-0 my-auto aspect-square w-[53px] hover:cursor-pointer"
                                    onClick={() => setShowODS(!showODS)}
                                />

                            </div>
                        </div>
                    </div>
                )}
                {showODS && (
                    <div className="flex gap-5 max-md:flex-col max-md:gap-0 py-14">
                        <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
                            <div className="flex grow gap-5 max-md:flex-wrap max-md:mt-10 max-md:max-w-full">
                                <img
                                    loading="lazy"
                                    src={img_seta}
                                    className="shrink-0 my-auto aspect-square w-[53px] hover:cursor-pointer transform rotate-180"
                                    onClick={() => setShowODS(!showODS)}
                                />
                                <div className="flex gap-5 justify-between">
                                    <img
                                        loading="lazy"
                                        src={img_ods_1}
                                        className="shrink-0 aspect-square w-[88px]"
                                    />
                                    <img
                                        loading="lazy"
                                        src={img_ods_2}
                                        className="shrink-0 aspect-square w-[88px]"
                                    />
                                    <img
                                        loading="lazy"
                                        src={img_ods_4}
                                        className="shrink-0 aspect-square w-[88px]"
                                    />
                                    <img
                                        loading="lazy"
                                        src={img_ods_6}
                                        className="shrink-0 aspect-square w-[88px]"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                            <div className="flex-auto self-start text-sm leading-5 text-cyan-950 max-md:max-w-full">
                                Nossa moeda é emitida a partir dos{" "}
                                <span className="font-black">
                                    Objetivos de Desenvolvimento Sustentável (ODS){" "}
                                </span>
                                da ONU, onde o uso do Sistema IRIS+ de contabilidade de impacto é
                                utilizado para garantir o nível mínimo de consistência nas declarações de
                                impacto.
                            </div>
                        </div>

                    </div>
                )}

            </div>
        </>
    );
}