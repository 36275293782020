import React from "react";
import OrderProfileItem from "../Profile/OrderProfileItem";
import TransferPublicItem from "../PublicProfile/TransferPublicItem";
import DonationPublicItem from "../PublicProfile/DonationPublicItem";

function PublicProfileItem({ item }) {
  return (
    <>
      {item.triggerable_type.includes("Transfer") && (
        <TransferPublicItem item={item} />
      )}
      {item.triggerable_type.includes("Donation") && (
        <DonationPublicItem item={item} />
      )}
      {item.triggerable_type.includes("CauseUser") && (
        <DonationPublicItem item={item} />
      )}
    </>
  );
}

export default PublicProfileItem;
