import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../components/NewLandingPage/tailwind.js";
import { Header } from "../../components/NewLandingPage/Header.js";
import { TransparencyReport } from "../../components/NewLandingPage/TransparencyReport.js";
import { ImpactDeliver } from "../../components/NewLandingPage/ImpactDeliver.js";
import { KarmaKredits } from "../../components/NewLandingPage/KarmaKredits.js";
import { KarmaCycle } from "../../components/NewLandingPage/KarmaCycle.js";
import { Marketplace } from "../../components/NewLandingPage/Marketplace.js";
import { KarmaStamp } from "../../components/NewLandingPage/KarmaStamp.js";
import { BePartOf } from "../../components/NewLandingPage/BePartOf.js";
import { Contact } from "../../components/NewLandingPage/Contact.js";
import Footer from "../../components/Footer.js";
import "../../components/NewLandingPage/index.css";
import { Menu } from "../../components/NewLandingPage/Menu.js";

export const NewLandingPage = ({ user }) => {
    const history = useHistory();
    const { t, i18n } = useTranslation();

    document.title = "magikk - entrada";

    if (user) {
        return <Redirect to="/" />;
    }
    return (
        <>
            <div className="flex flex-col bg-zinc-50">
                <Menu />
                <Header />
                <TransparencyReport />
                <ImpactDeliver />
                <KarmaKredits />
                <KarmaCycle />
                <Marketplace />
                <KarmaStamp />
                <BePartOf />
                <Contact />
                <Footer newLanding={true} />
            </div>
        </>
    );
};

const mapStateToProps = ({ auth }) => ({
    user: auth.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NewLandingPage);
