import React from "react";
import fakeAvatar from "../../assets/avatar.jpg";
// import { BASE_URL } from "../../config/env";

function UserSearchItem({ user, onClick }) {
  const avatar_url =
    !user?.avatar || (user?.avatar && user.avatar.startsWith("magikk"))
      ? fakeAvatar
      : `${process.env.REACT_APP_API_URI}/${user?.avatar}`;

  return (
    <div
      className="flex flex-row items-center justify-start w-full py-2 btn-effects public-hover"
      onClick={onClick}
    >
      <img src={avatar_url} className="w-1/12 h-8 mx-2 contain" alt="avatar"/>
      <h4>
        {user.firstname} {user.lastname}
      </h4>
    </div>
  );
}

export default UserSearchItem;
