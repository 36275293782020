import React from "react";
import { connect } from "react-redux";
import {
  emailSignInStart,
  signOutStart,
} from "../redux/Auth/auth.actions";
import MainHeader from "../components/MainHeader";
import Dashboard from "../components/Profile/Dashboard";
import YourHistoric from "../components/Profile/YourHistoric";
import Footer from "../components/Footer";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { resetProfile } from "../redux/Profile/profile.actions";
import ProfileHero from "../components/PublicProfile/ProfileHero";

export const Profile = ({
  dispatchEmailSignInStart,
  dispatchResetProfile,
  dispatchSignOutStart,
}) => {
  const { t, i18n } = useTranslation();
  // logged in user Profileplace
  const history = useHistory();

  const onSubmit = (data) => {
    // console.log(data);
    dispatchEmailSignInStart(data);
  };

  document.title = "magikk - perfil";

  React.useEffect(() => {
    // startGetProductsData();
  }, []);

  return (
    <div className="bg-bodyBg">
      <MainHeader search={true} />
      <ProfileHero isProfilePage={true} />
      <Dashboard />
      <div className="container mx-auto px-2 sm:px-12">
  <div className="px-4 sm:px-28 flex flex-col sm:flex-row">
    <div className="w-full sm:w-5/12 font-LatoRegular mt-2 underline text-primary flex flex-row justify-between">
      <h4
        className="btn-effects text-hover"
        onClick={() => history.push("/account-settings")}
      >
        {t("profile.edit_profile")}
      </h4>
      <h4
        onClick={() => history.push("/public-profile")}
        className="btn-effects text-hover"
      >
        {t("profile.public_profile")}
      </h4>
    </div>
    <div className="w-full sm:w-4/12 mt-2 sm:mt-0 underline flex flex-row justify-end">
      <h4
        className="btn-effects text-hover"
        onClick={() => {
          // console.log(" Log Out...");
          dispatchSignOutStart();
          dispatchResetProfile();
        }}
      >
        {t("login.logout")}
      </h4>
    </div>
  </div>
</div>
      <YourHistoric />
      <Footer />
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchEmailSignInStart: (user) =>
    dispatch(emailSignInStart(user)),
  dispatchSignOutStart: () => dispatch(signOutStart()),
  dispatchResetProfile: () => dispatch(resetProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
