import React, { useEffect, useState, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import MainHeader from "../components/MainHeader";
import PublicProfileUser from "../components/PublicProfile/PublicProfileUser";
import ProfileHero from "../components/PublicProfile/ProfileHero";
import ForeignProfileItem from "../components/PublicProfile/ForeignProfileItem";
import SustainableItem from "../components/PublicProfile/SustainableItem";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { startGetAllGoals } from "../redux/Market/market.actions";
import { getUserActivities } from "../redux/Profile/profile.helpers";
import loaderGif from "../assets/spinner.gif";

import {
  startGetForeignTransactions,
  clearTransactions,
} from "../redux/Profile/profile.actions";

function ForeignProfile({
  dispatchStartGetForeignTransactions,
  dispatchStartGetAllGoals,
  allGoals,
  transactions,
  foreignNextPageUrl,
  dispatchClearTransactions,
  foreignUser,
}) {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [transferCount, setTransferCount] = useState(0);
  const [PP, setPP] = useState();
  const [dataSpinner, setDataSpinner] = useState(true);
  const [activities, setActivities] = useState([]);
  const [nextPageExist, setNextPageExist] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatchStartGetForeignTransactions(id, foreignNextPageUrl);

    dispatchStartGetAllGoals();
    //console.log("Foreign User 2", foreignUser);
  }, []);

  useEffect(() => {
    // console.log("FOREIGNUSER", foreignUser);
    setTransferCount(foreignUser?.transfers_count);
    setActivities([]);
    setCurrentPage(1);
    setNextPageExist(false);
    getUserActivities(id, 1).then((response) => {
      if (response?.data?.data?.length > 0) {
        setActivities(response.data.data);
        setNextPageExist(!!response.data.next_page_url);
        setCurrentPage(2);
      }
    });

    setDataSpinner(false);
  }, [foreignUser]);

  return (
    <div className="mb-40">
      <MainHeader search={true} />
      {foreignUser && (
        <ProfileHero transferCount={transferCount} isForeign={true} />
      )}
      <div className="flex flex-row container mx-auto mt-36">
        <div
          className="bg-white rounded-ftxl shadow-xl w-6/12 mr-8"
          style={{ height: "fit-content" }}
        >
          {foreignUser && <PublicProfileUser user={foreignUser} />}
        </div>
        <div
          className="bg-white rounded-ftxl shadow-xl w-full flex flex-col p-4"
          style={{ height: "fit-content" }}
        >
          {dataSpinner ? (
            <img className="h-48 w-48 self-center" src={loaderGif} alt="profile photo"/>
          ) : (
            <>
              <h4 className="font-LatoRegular font-bold text-2xl text-left">
                {t("profile.recent_activities")}
              </h4>
              <>
                {foreignUser &&
                  activities.map((trs, index) => {
                    return (
                      <>
                        <ForeignProfileItem
                          key={index}
                          item={trs}
                          itemIndex={index}
                        />
                      </>
                    );
                  })}
              </>
            </>
          )}
          {nextPageExist && (
            <div
              className="text-hover w-full text-center text-primary underline py-2 btn-effects font-LatoRegular"
              onClick={() => {
                getUserActivities(id, currentPage).then(
                  (response) => {
                    if (response?.data?.data?.length > 0) {
                      setActivities(
                        activities.concat(response.data.data)
                      );
                      setNextPageExist(!!response.data.next_page_url);
                      setCurrentPage(currentPage + 1);
                    }
                  }
                );
              }}
            >
              {t("profile.load_more")}
              
            </div>
          )}
        </div>

        <div
          className="bg-white rounded-ftxl shadow-xl w-5/12 ml-8 p-4 flex flex-col"
          style={{ height: "fit-content" }}
        >
          <h4 className="font-LatoRegular font-bold text-2xl text-left mb-4">
            {t("profile.sustainable_goals")}
          </h4>
          <div className="flex flex-col items-center">
            {allGoals &&
              Array.isArray(allGoals) &&
              allGoals.map((goal) => <SustainableItem item={goal} />)}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapState = ({ profile, market }) => ({
  foreignUser: profile.foreignUser,
  transactions: profile.foreignTransactions,
  foreignNextPageUrl: profile.foreignNextPageUrl,
  allGoals: market.allGoals,
});

const mapDispatch = (dispatch) => ({
  dispatchStartGetForeignTransactions: (
    foreignId,
    foreignNextPageUrl
  ) =>
    dispatch(
      startGetForeignTransactions(foreignId, foreignNextPageUrl)
    ),
  dispatchStartGetAllGoals: () => dispatch(startGetAllGoals()),
  dispatchClearTransactions: () => dispatch(clearTransactions()),
});

export default connect(mapState, mapDispatch)(ForeignProfile);
