
import CookieConsent from "react-cookie-consent";
import { useCookies } from 'react-cookie';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import colors from '../constants/colors';


const Cookies = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [cookies, setCookie] = useCookies(['magikk_lgpd']);


    function handleCookieStatus(cookieConsent) {
        if (cookieConsent) {
            setCookie('magikk_lgpd', 'true');
            return;
        }

        setCookie('magikk_lgpd', 'false');
        return;
    }

    // console.log("cookies.magikk_lgpd", cookies.magikk_lgpd);

    return (
        <>
            {cookies.magikk_lgpd === undefined && <CookieConsent
                cookieName="magikk_lgpd"
                enableDeclineButton
                onAccept={() => handleCookieStatus(true)}
                onDecline={() => handleCookieStatus(false)}
                buttonText={t("cookies.agree")}
                declineButtonText={t("cookies.disagree")}
                style={{ 
                    background: colors.themeHard, 
                    borderRadius: "10px 10px 0px 0",
                    fontSize: "14px",
                    fontFamily: "Volkswagen-Medium",
                }}
                buttonStyle={{
                    background: '#cce0ec',
                    borderRadius: "5px",
                    fontSize: "18px",
                    color: colors.profileHard,
                }}
                declineButtonStyle={{
                    borderRadius: "5px",
                    fontSize: "14px",
                    background: colors.greyText,
                    color: colors.profileHard,
                }}
            >
                <div className="mb-1 text-hover" onClick={() => {
                    history.push('/term-of-use');
                }}
                >{t("cookies.message")}  {t("cookies.more_info")}
                </div>

            </CookieConsent>
            }
        </>
    );
}


export default Cookies;