import React from 'react';
import colors from "../../constants/colors";

const CustomModal = ({
  isVisible,
  title,
  message,
  onConfirm,
  onCancel,
  confirmText = 'Confirmar',
  cancelText = 'Cancelar',
  template = 'default'
}) => {
  if (!isVisible) return null;

  const confirmButtonClass =
    template === 'danger' ? 'bg-danger text-white' : 'bg-primary text-white';

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md mx-auto text-center">
        <p className="mb-6">{message}</p>
        <div className="flex justify-between">

          <button
            className="bg-gray-300 text-black py-2 px-4 rounded-md w-1/2 mr-2"
            onClick={onCancel}
          >
            {cancelText}
          </button>
          <button
            className={`${confirmButtonClass} py-2 px-4 rounded-md w-1/2 `}
            onClick={onConfirm}
          >
            {confirmText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
