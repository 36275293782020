const marketTypes = {
  SET_USER_TRANSACTIONS: "SET_USER_TRANSACTIONS",
  SET_USER_CAUSES: "SET_USER_CAUSES",
  START_GET_USER_TRANSACTIONS: "START_GET_USER_TRANSACTIONS",
  START_GET_USER_CAUSES: "START_GET_USER_CAUSES",

  SET_TOASTR_TXT: "SET_TOASTR_TXT",
  //
  START_UPDATE_PROFILE: "START_UPDATE_PROFILE",
  SET_PROFILE_DATA: "SET_PROFILE_DATA",

  CLEAR_TRANSACTIONS: "CLEAR_TRANSACTIONS",

  START_GET_FOREIGN_TRANSACTIONS: "START_GET_FOREIGN_TRANSACTIONS",
  SET_FOREIGN_USER: "SET_FOREIGN_USER",
  //
  START_LOADER: "START_LOADER",
  RESET: "RESET",
  START_GET_ADDRESS_BY_POSTAL_CODE: "START_GET_ADDRESS_BY_POSTAL_CODE",
};

export default marketTypes;
