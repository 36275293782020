import React from "react";
import { connect } from "react-redux";
import { Footer } from "../../components/Footer";
import { MainHeader } from "../../components/MainHeader";
import pattern_hero from "../../assets/pattern_hero.png";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const CreateSuccess = (props) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  return (
    <div>
      <MainHeader />
      <div
        className="container mx-auto bg-primary my-8 rounded-ftxl flex flex-col items-center justify-center h-96 px-4 md:px-20"
        style={{ backgroundImage: `url(${pattern_hero})` }}
      >
        <h3 className="text-white font-VolksDemiBold text-2xl md:text-4xl w-full md:w-3/5 text-center mt-10 leading-relaxed">
          {t("register.finish_title")}
        </h3>
        <p className="text-white font-LatoRegular text-center text-sm md:text-base w-full md:w-3/5 my-4 leading-relaxed">
          {t("register.finish_text")}
        </p>
        <div
          className="btn-1 btn-effects bg-secondary text-primary w-40 md:w-52 py-4 md:py-6"
          onClick={() => {
            history.push("/account-settings");
          }}
        >
          {t("register.finish")}
        </div>
      </div>

      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CreateSuccess);
