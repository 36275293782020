import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  startGetUserTransactions,
  startGetUserCauses,
} from "../../redux/Profile/profile.actions";
import HistoricHeader from "./HistoricHeader";
import OrderProfileItem from "./OrderProfileItem";
import TransferProfileItem from "./TransferProfileItem";
import CauseUserProfileItem from "./CauseUserProfileItem";
import DonationProfileItem from "./DonationProfileItem";
import SystemGiftProfileItem from "./SystemGiftProfileItem";
import CauseProfileItem from "./CauseProfileItem";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import { getQrCode } from "../../redux/Auth/auth.helpers";

function YourHistoric({
  dispatchStartGetUserTransactions,
  transactions,
  causes,
  dispatchStartGetUserCauses,
  trsNextPageUrl,
}) {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatchStartGetUserTransactions();
    dispatchStartGetUserCauses();
  }, []);

  const [selectedTab, setSelectedTab] = useState("transactions");
  const [qrModal, setQrModal] = useState();
  const [QR, setQR] = useState();

  useEffect(async () => {
    const _svg = await getQrCode();
    setQR(
      <img
        src={`data:image/svg+xml;utf8,${encodeURIComponent(_svg)}`}
      />
    );
  }, []);

  return (
    <div className="mt-6 sm:mt-12">
      <HistoricHeader
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      <div className="w-full md:px-44">
        <Modal
          isOpen={!!qrModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div
            className="btn-effects w-full pr-auto text-2xl text-gray-500 mb-4"
            onClick={() => setQrModal()}
          >
            X
          </div>
  
          <div className="font-LatoRegular text-xl sm:text-2xl font-bold w-full text-center mb-2">
            {qrModal?.title}
          </div>
          <div className="underline mb-6 font-LatoRegular">
            {qrModal?.location}
          </div>
          <div className="btn-1 btn-effects w-full py-3 sm:py-6 px-4 mb-6 font-LatoRegular text-sm sm:text-base">
            <span className="text-xs mr-auto sm:text-sm">
              {t("profile.receive")}
            </span>{" "}
            {qrModal?.amount} kk
          </div>
          <div className="w-full sm:w-9/12 p-4 bg-secondary rounded-ftxl">
            {QR}
          </div>
        </Modal>
        <div className="px-3 sm:px-6">
          {selectedTab === "transactions" &&
            transactions?.length > 0 &&
            transactions.map((trs, trsIndex) => (
              <div key={`trx-${trs?.id}`} className="mb-4 sm:mb-2">
                {trs.triggerable_type.includes("Order") && (
                  <OrderProfileItem item={trs} />
                )}
                {trs.triggerable_type.includes("Transfer") && (
                  <TransferProfileItem item={trs} />
                )}
                {trs.triggerable_type.includes("CauseUser") && (
                  <CauseUserProfileItem item={trs} />
                )}
                {trs.triggerable_type.includes("Donation") && (
                  <DonationProfileItem item={trs} />
                )}
                {trs.triggerable_type.includes("SystemGift") && (
                  <SystemGiftProfileItem item={trs} />
                )}
                {trsNextPageUrl != null && (
                  <div>
                    {trsIndex === transactions.length - 1 && (
                      <div
                        className="text-hover w-full text-center text-primary underline py-2 btn-effects font-LatoRegular text-sm sm:text-base"
                        onClick={() => {
                          // console.log(
                          //   " trs next page url : ...  ",
                          //   trsNextPageUrl
                          // );
                          transactions = null;
                          if (trsNextPageUrl != null) {
                            dispatchStartGetUserTransactions(trsNextPageUrl);
                          }
                        }}
                      >
                        {t('profile.load_more')}...
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          {selectedTab === "causes" &&
            causes &&
            causes.map((cause, index) => (
              <CauseProfileItem
                item={cause}
                key={`cause-${cause?.id}`}
                setQrModal={setQrModal}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

const mapState = ({ profile }) => ({
  transactions: profile.userTransactions,
  trsNextPageUrl: profile.trsNextPageUrl,
  causes: profile.userCauses,
});

const mapDispatch = (dispatch) => ({
  dispatchStartGetUserTransactions: (next_url) =>
    dispatch(startGetUserTransactions(next_url)),
  dispatchStartGetUserCauses: () => dispatch(startGetUserCauses()),
});

export default connect(mapState, mapDispatch)(YourHistoric);

const customStyles = {
  content: {
    top: "10%",
    borderRadius: "20px",
    color: "black",
    height: "fit-content",
    width: (window.innerWidth > 640) ? "25%" : "80%",
    margin: "auto",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    borderColor: "#396B7C",
  },
};