import React from "react";

import { useState } from "react";

import img_seta from "../../assets/new-landing-page/seta.svg";
import img_campanha_arrecadacao from "../../assets/new-landing-page/campanha_arrecadacao.svg";
import img_dinamicas_e_desafios from "../../assets/new-landing-page/dinamicas_e_desafios.svg";
import img_experiencias_voluntarias from "../../assets/new-landing-page/experiencias_voluntarias.svg";
import img_governanca_social from "../../assets/new-landing-page/governanca_social.svg";
import img_produto_solidario from "../../assets/new-landing-page/produto_solidario.svg";
import img_projetos_incentivados from "../../assets/new-landing-page/projetos_incentivados.svg";
import img_estrela from "../../assets/new-landing-page/builder/335c5678bd6b15f3834467b6e6f58d0de122b330139c690973793133e6671d71.svg";
import img_carrinho from "../../assets/new-landing-page/builder/875b4bf671abd7898e9f169ec9160a4b9cdfee3bb63c2078d7f9ce0cca8a7a26.svg";
import img_barra_fotos from "../../assets/new-landing-page/builder/f35be72697c62a59f19fab5a9c33e807b26e12a1012bc808f006213a5fb19ce0.svg";
import img_1 from "../../assets/new-landing-page/builder/10c0192b7de692b451ca7cb297943fdde7a047beb52c24e91c3124f94fad4da3.png";
import img_2 from "../../assets/new-landing-page/builder/aff3a009e688676cc479d66fc1efacad4535f9744de7c570b750ba84f75ff4d4.png";
import img_3 from "../../assets/new-landing-page/builder/3aaec04ae387df5e269c45ad5af213077320d2025a48132c56eb80ab480affb6.png";
import img_4 from "../../assets/new-landing-page/builder/2c469c909649561f265494f6cc894de3aff80cd5dfe698160da72c2eddae478e.png";
import img_5 from "../../assets/new-landing-page/builder/fb32f3ded468f448b954559b97ab43e8e2b6cba2109e5b0b57d63697935d9282.png";
import img_6 from "../../assets/new-landing-page/builder/a7ae3340566ede98387604cd5e4de56c25927ee88f04f9f6aa540fac96a2018e.png";
import img_7 from "../../assets/new-landing-page/builder/27e5fe306f4edbcd320428df80f9a6a401e1d77e8a49cf9092d0efe711757cfd.png";
import img_espiral from "../../assets/new-landing-page/espiral.svg";


import "./tailwind.js";

export const ImpactDeliver = () => {

    const items = [
        {
            title: "Governança Social",
            description: "Nossa estrutura de Governança Social assegura práticas comerciais íntegras e transparentes. Descubra como implementamos a ética em cada passo que damos."
        },
        {
            title: "Dinâmicas e Desafios",
            description: "Conheça nossas Dinâmicas e Desafios baseados no mercado com estratégias robustas e as soluções adaptativas para um progresso empresarial sustentável."
        },
        {
            title: "Projetos Incentivados",
            description: "Invista em Projetos Incentivados e veja o retorno em escala social. Aproveite incentivos fiscais ao contribuir para o desenvolvimento sustentável."
        },
        {
            title: "Experiências Voluntárias",
            description: "Engaje sua equipe com Experiências Voluntárias que causam impacto. Participe de nossos programas e construa uma marca que se destaca pelo bem social."
        },       
        {
            title: "Produto Solidário",
            description: "Transforme compras em ação social com nossos Produtos Solidários. Cada aquisição apoia diretamente projetos que fazem a diferença."
        },        
        {
            title: "Campanha de Arrecadação",
            description: "Amplie sua influência com Campanhas de Arrecadação que tocam corações. Veja como nossas iniciativas conectam você a causas valiosas e a uma rede de apoio crescente."
        }, 
    ];

    const [showItem, setShowItem] = useState(items[0]);
    const [clicked, setClicked] = useState(0);


    return (
        <>
            <div id="entregas-de-impacto" className={`flex z-10 flex-col items-center mt-0 w-full bg-zinc-50 max-md:max-w-full bg-[url(${img_espiral})] bg-no-repeat bg-[right_25px_top_-25px]`}>

                <div className="px-5 w-full max-w-[1176px] max-md:max-w-full" >
                    <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                        <div className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full md:-mt-16">
                            <div className="flex flex-col grow px-8 py-5 w-full bg-white border border-solid border-gray-900 border-opacity-10 rounded-[32px] max-md:px-5 max-md:mt-5">
                                <img
                                    loading="lazy"
                                    src={img_seta}
                                    className="aspect-square w-[53px]"
                                />
                                <div className="flex flex-col pb-14 mt-6">
                                    <div className="text-xl text-gray-900 font-[5]">
                                        Mapeamento de Impacto
                                    </div>
                                    <div className="mt-4 text-sm leading-6 text-gray-900 text-opacity-50">
                                        Nosso 'Mapeamento de Impacto' é uma ferramenta avançada que
                                        avalia a responsabilidade socioambiental e a
                                        sustentabilidade, orientando o posicionamento estratégico da
                                        sua marca.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col ml-5 w-[33%]  max-md:ml-0 max-md:w-full md:-mt-16">
                            <div className="flex flex-col grow px-8 py-5 w-full bg-white border border-solid border-gray-900 border-opacity-10 rounded-[32px] max-md:px-5 max-md:mt-5">
                                <img
                                    loading="lazy"
                                    src={img_seta}
                                    className="aspect-square w-[53px]"
                                />
                                <div className="flex flex-col mt-6">
                                    <div className="text-xl text-gray-900 font-[5]">
                                        Estratégia de Responsabilidade Socioambiental
                                    </div>
                                    <div className="mt-4 text-sm leading-6 text-gray-900 text-opacity-50">
                                        Aprimore seu negócio com nossa 'Estratégia de
                                        Responsabilidade Socioambiental', um planejamento
                                        estratégico que reforça a autenticidade dos seus valores
                                        culturais, humanos e sustentáveis, fortalecendo os pilares
                                        fundamentais do seu modelo de negócio.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full md:-mt-16">
                            <div className="flex flex-col grow px-8 py-5 w-full bg-white border border-solid border-gray-900 border-opacity-10 rounded-[32px] max-md:px-5 max-md:mt-5">
                                <img
                                    loading="lazy"
                                    src={img_seta}
                                    className="aspect-square w-[53px]"
                                />
                                <div className="flex flex-col mt-6">
                                    <div className="text-xl text-gray-900 font-[5]">
                                        Gamificação de boas ações
                                    </div>
                                    <div className="mt-4 text-sm leading-6 text-gray-900 text-opacity-50">
                                        Recompensa seus colaboradores e clientes por participarem de
                                        seus projetos de impacto com karma, uma moeda social
                                        (cashback) que pode ser trocada por uma variedade de
                                        benefícios oferecidos pela sua empresa ou pela nossa rede de
                                        parceiros.
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-16 w-full max-w-[1176px] max-md:mt-10 max-md:max-w-full">
                    <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                        <div className="main-container max-w-full h-[427px] relative  mx-auto my-0 scale-75 -ml-11 md:scale-100 md:-ml-0">
                            <div className='flex w-[268.965px] h-[100px] justify-between items-center relative z-[27] mt-[8.345px] mr-0 mb-0 ml-[101.399px]'>
                                <div
                                    onClick={() => {
                                        setShowItem(items[0]);
                                        setClicked(0);
                                    }}
                                    className={`w-[100px] h-[100px] hover:cursor-pointer shrink-0 bg-[url(${img_governanca_social})] bg-[length:100%_100%] bg-no-repeat relative z-[27] ${clicked === 0 ? 'rounded-full border-[2px] border-[#004150]' : ''}`}>


                                </div>
                                <div
                                    onClick={() => {
                                        setShowItem(items[1]);
                                        setClicked(1);
                                    }}
                                    className={`w-[100px] h-[100px] hover:cursor-pointer shrink-0 bg-[url(${img_dinamicas_e_desafios})] bg-[length:100%_100%] bg-no-repeat relative z-[12] ${clicked === 1 ? 'rounded-full border-[2px] border-[#004150]' : ''}`}></div>
                            </div>
                            <div className='flex w-[274px] h-[14px] justify-between items-center relative z-[25] mt-[12.655px] mr-0 mb-0 ml-[107px]'>
                                <span className="h-[14px] shrink-0 font-['Volkswagen'] text-[12px] font-bold leading-[13.632px] text-[#004150] relative text-left whitespace-nowrap z-[25]">
                                    Governança Social
                                </span>
                                <span className="h-[14px] shrink-0 font-['Volkswagen'] text-[12px] font-bold leading-[13.632px] text-[#004150] relative text-left whitespace-nowrap z-[11]">
                                    Dinâmicas e Desafios
                                </span>
                            </div>
                            <div className='w-[469px] h-[150px] relative z-[42] mt-[10.5px] mr-0 mb-0 ml-[10px]'>
                                <div className={`w-[221.5px] h-[150px] bg-[url(${img_estrela})] bg-cover bg-no-repeat absolute top-0 left-[113px] z-[42]`}>
                                    <div className='flex w-[130px] h-[130px] pt-[10px] pr-[10px] pb-[10px] pl-[10px] flex-col gap-[6px] justify-center items-center flex-nowrap bg-[#01414f] rounded-[1000px] border-solid border-2 border-[#fff] relative shadow-[0_4px_15px_0_rgba(0,0,0,0.25)] z-[44] mt-[6.5px] mr-0 mb-0 ml-[45px]'>
                                        <span className="flex w-[96px] h-[36px] justify-center items-start shrink-0 font-['Volkswagen'] text-[11px] font-bold leading-[12.496px] text-[#fff] relative text-center z-[45]">
                                            ESTRATÉGIAS DE <br />
                                            RESPONSABILIDADE <br />
                                            SOCIOAMBIENTAL
                                        </span>
                                        <div className='w-[28.527px] h-[27.429px] shrink-0 relative z-[46]'>
                                            <div className='w-full h-[98.52%] absolute top-[1.48%] left-0 z-[47]'>
                                                <div className='w-[28.527px] h-[20.52px] relative z-[48] mt-0 mr-0 mb-0 ml-0'>
                                                    <div className={`w-full h-full bg-[url(${img_carrinho})] bg-[length:100%_100%] bg-no-repeat absolute top-0 left-0 z-[49]`} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    onClick={() => {
                                        setShowItem(items[2]);
                                        setClicked(2);

                                    }}
                                    className={`w-[100px] h-[100px] hover:cursor-pointer bg-[url(${img_projetos_incentivados})] bg-[length:100%_100%] bg-no-repeat absolute top-[5.004px] left-[3.392px] z-[18] ${clicked === 2 ? 'rounded-full border-[2px] border-[#004150]' : ''}`}>

                                </div>
                                <div
                                    onClick={() => {
                                        setShowItem(items[3]);
                                        setClicked(3);
                                    }}
                                    className={`w-[21.32%] h-[66.67%] hover:cursor-pointer bg-[url(${img_experiencias_voluntarias})] bg-[length:100%_100%] bg-no-repeat absolute top-[3.34%] left-[74.28%] z-[33] ${clicked === 3 ? 'rounded-full border-[2px] border-[#004150]' : ''}`}>

                                </div>
                                <span className="flex h-[9.33%] justify-start items-start font-['Volkswagen'] text-[12px] font-bold leading-[13.632px] text-[#004150] absolute top-[78.33%] left-0 text-left whitespace-nowrap z-[15]">
                                    Projetos Incentivados
                                </span>
                                <span className="flex h-[9.33%] justify-start items-start font-['Volkswagen'] text-[12px] font-bold leading-[13.632px] text-[#004150] absolute top-[78.33%] left-[73.13%] text-left whitespace-nowrap z-[31]">
                                    Experiências Voluntárias
                                </span>
                            </div>
                            <div className='flex w-[268.965px] h-[100px] justify-between items-center relative z-[8] mt-[-2.837px] mr-0 mb-0 ml-[101.399px]'>
                                <div
                                    onClick={() => {
                                        setShowItem(items[4]);
                                        setClicked(4);
                                    }}
                                    className={`w-[100px] h-[100px] hover:cursor-pointer shrink-0 bg-[url(${img_produto_solidario})] bg-[length:100%_100%] bg-no-repeat relative z-[8] ${clicked === 4 ? 'rounded-full border-[2px] border-[#004150]' : ''}`}>
                                </div>
                                <div
                                    onClick={() => {
                                        setShowItem(items[5]);
                                        setClicked(5);
                                    }}
                                    className={`w-[100px] h-[100px] hover:cursor-pointer shrink-0 bg-[url(${img_campanha_arrecadacao})] bg-[length:100%_100%] bg-no-repeat relative z-[3] ${clicked === 5 ? 'rounded-full border-[2px] border-[#004150]' : ''}`}>

                                </div>
                            </div>
                            <div className='flex w-[293px] h-[14px] justify-between items-center relative z-[7] mt-[12.337px] mr-0 mb-0 ml-[108px]'>
                                <span className="h-[14px] shrink-0 font-['Volkswagen'] text-[12px] font-bold leading-[13.632px] text-[#004150] relative text-left whitespace-nowrap z-[7]">
                                    Produto Solidário
                                </span>
                                <span className="h-[14px] shrink-0 font-['Volkswagen'] text-[12px] font-bold leading-[13.632px] text-[#004150] relative text-left whitespace-nowrap z-[1]">
                                    Campanha de Arrecadação
                                </span>
                            </div>
                        </div>
                        <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full" >
                            <div className="flex flex-col grow self-stretch pb-20 max-md:mt-10 max-md:max-w-full">
                                <div className="flex flex-col text-3xl text-black font-[5] max-md:max-w-full">
                                    <div className="shrink-0 w-14 bg-slate-400 h-[3px]" />
                                    <div className="justify-center px-5 mt-6 max-md:max-w-full">
                                        <span className="text-black ">Entregas de </span>
                                        <span className="text-black font-bold">Impacto</span>
                                    </div>
                                </div>
                                <div className="flex flex-col justify-center px-5 mt-12 rounded-[40px] text-cyan-950 max-md:mt-10 max-md:max-w-full">
                                    <div className="text-2xl font-[5] max-md:max-w-full">
                                        {showItem.title}
                                    </div>
                                    <div className="mt-4 text-sm leading-5 max-md:max-w-full">
                                        {showItem.description}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img
                    loading="lazy"
                    src={img_barra_fotos}
                    className="self-stretch mt-11 w-full aspect-[25] max-md:mt-10 max-md:max-w-full hidden md:block"
                />
                {/* hidden on sm */}
                <div className="md:flex gap-0 px-5 mt-0 max-md:flex-wrap w-full hidden">

                    <div className="flex flex-col w-1/4 max-md:w-full">
                        <img
                            loading="lazy"
                            src={img_1}
                            className="aspect-square"
                        />
                    </div>
                    <div className="flex flex-col w-1/4 max-md:w-full">
                        <img
                            loading="lazy"
                            src={img_2}
                            className="aspect-square"
                        />
                    </div>
                    <div className="flex flex-col w-1/4 max-md:w-full">
                        <img
                            loading="lazy"
                            src={img_3}
                            className="aspect-square"
                        />
                    </div>
                    <div className="flex flex-col w-1/4 max-md:w-full">
                        <img
                            loading="lazy"
                            src={img_4}
                            className="aspect-square"
                        />
                    </div>
                    <div className="flex flex-col w-1/4 max-md:w-full">
                        <img
                            loading="lazy"
                            src={img_5}
                            className="aspect-square"
                        />
                    </div>
                    <div className="flex flex-col w-1/4 max-md:w-full">
                        <img
                            loading="lazy"
                            src={img_6}
                            className="aspect-square"
                        />
                    </div>
                    <div className="flex flex-col w-1/4 max-md:w-full">
                        <img
                            loading="lazy"
                            src={img_7}
                            className="aspect-square"
                        />
                    </div>


                </div>
            </div>

        </>
    );
}