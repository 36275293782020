import axios from "axios";
// import { BASE_URL } from "../../config/env";
import qs from "qs";

// update profile
export const handleUpdateProfile = (profile) => {
  return new Promise((resolve, reject) => {
    const infoObj = {
      firstname: profile?.firstname,
      lastname: profile?.lastname,
      email: profile?.email,
      birthdate : profile?.birthdate,
      gender: profile?.gender,
    };
    // console.log("handle update profile", infoObj);

    axios
      .post(
        `${process.env.REACT_APP_API_URI}/api/user/profile/user-info-update`,
        infoObj
      )
      .then((res) => {
        if (res?.data?.success) {
          if (
            profile?.country_id !== undefined ||
            profile?.phone !== undefined ||
            profile?.document !== undefined ||
            profile?.address !== undefined ||
            profile?.number !== undefined ||
            profile?.district !== undefined ||
            profile?.city !== undefined ||
            profile?.state_abbr !== undefined ||
            profile?.postal_code !== undefined 
          ) {
            const profileObj = {
              country_id: profile?.country_id,
              phone: profile?.phone,
              address: profile?.address,
              number: profile?.number,
              district: profile?.district,
              city: profile?.city,
              state_abbr: profile?.state_abbr,
              postal_code: profile?.postal_code,
              document:
                profile?.document === 0 ? "" : profile?.document,
            };

            axios
              .post(
                `${process.env.REACT_APP_API_URI}/api/user/profile/user-profile-update`,
                profileObj
              )
              .then((res) => {
                // console.log("info updater response", res.data.data);
                resolve(res.data.data);
              })
              .catch((res) => {
                // console.log(
                //   "info updater err response ......",
                //   res?.response
                // );
                reject(res?.response?.data);
              });
          } else {
            resolve(res.data.data);
          }
        } else {
          reject(res?.data);
        }

        //console.log("User-Profile-Update", profileObj);
      })
      .catch((err) => {
        reject(err?.response?.data);
      });
  });
};

export const handleDeleteProfile = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URI}/api/user/profile/delete/${id}`
      )
      .then((res) => {
        // console.log("delete profile ......", res.data);
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

export const getUserActivities = (id, p_id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URI}/api/activities/get-activity-by-user/${id}?page=${p_id}`
      )
      .then(({ data }) => {
        resolve(data?.data);
      })
      .catch((err) => reject(err));
  });
};

// update password
export const handleUpdatePassword = (updatePasswordData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URI}/api/account/change-password`,
        updatePasswordData
      )
      .then((res) => {
        //console.log("update password ......", res.data);

        resolve(res.data);
      })
      .catch((err) => resolve(err));
  });
};

export const handleGetUserPublicTransactions = (pageUrl) => {
  const getURL =
    pageUrl ||
    `${process.env.REACT_APP_API_URI}/api/user/activities/public`;
  return new Promise((resolve, reject) => {
    axios
      .get(getURL)
      .then(({ data }) => {
        resolve(data?.data);
      })
      .catch((err) => reject(err));
  });
};

// user trans
export const handleGetUserTransactions = (pageUrl) => {
  const getURL =
    pageUrl || `${process.env.REACT_APP_API_URI}/api/user/activities`;
  //console.log(" inside get url :::.    ", getURL);
  return new Promise((resolve, reject) => {
    axios
      .get(getURL)
      .then(({ data }) => {
        // console.log(
        //   "get activities on current user ... ... ...  ......",
        //   data?.data
        // );
        resolve(data?.data);
      })
      .catch((err) => reject(err));
  });
};

// user trans
export const handleGetForeignTransactions = ({
  foreignId,
  nextURL,
}) => {
  const id = foreignId;
  const pageUrl = nextURL;
  const getURL =
    pageUrl ||
    `${process.env.REACT_APP_API_URI}/api/foreign-user/${id}`;
  return new Promise((resolve, reject) => {
    axios
      .get(getURL)
      .then(({ data }) => {
        // console.log(
        //   "get activities on FOREINNNNNNNNNNN user ... ... ...  ......",
        //   data
        // );
        data?.success && data?.data
          ? resolve(data?.data)
          : resolve({ success: data?.success });
        //console.log("data is sent");
      })
      .catch((err) => {
        //console.log("ERR", err);
        reject(err);
      });
  });
};

// user cause
export const handleGetUserCauses = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URI}/api/user/causes/attended`
      )
      .then((res) => {
        //console.log("user causes response ......", res.data);

        resolve(res.data);
      })
      .catch((err) => resolve(err));
  });
};

// get saved card of user
export const handleGetUserCreditCard = (callback) => {
  axios
    .get(
      `${process.env.REACT_APP_API_URI}/api/credit-card/get-user-cards`
    )
    .then((res) => {
      callback(res.data.data);
    })
    .catch((err) => {
      //console.log("get user credit card error ", err);
      callback("err");
    });
};

// card info fetch with customer and card id

export const fetchUserCardWithIds = (ids, callback) => {
  axios
    .get(
      `${process.env.REACT_APP_API_URI}/api/credit-card?customer_id=${ids.customer_id}&card_id=${ids.card_id}`
    )
    .then((res) => {
      callback(res.data);
    })
    .catch((err) => {
      //console.log("get card with ids error ", err);
      callback("err");
    });
};

export const getNeededDollars = (calcObj) => {
  return new Promise((resolve, reject) => {
    // const newBase = process.env.REACT_APP_API_URI.slice(0, -4);
    axios
      .post(
        `${process.env.REACT_APP_API_URI}/api/basket-price-calculation`,
        calcObj
      )
      .then((res) => {
        //console.log("calculation response ", res.data);
        // if (res.success) {
        resolve(res.data);
        // }
      })
      .catch((err) => {
        // console.log(
        //   " res data : calc response ...... ....... ........  : ",
        //   err.response.data
        // );
        resolve(err);
      });
  });
};

// make transfer
export const handleMakeTransfer = (trObj) => {
  return new Promise((resolve, reject) => {
    const _amount = parseInt(trObj.amount);
    const _receiver_id = parseInt(trObj.receiver_id);
    const _description = trObj.description;
    const _is_private = trObj.is_private;

    const payload = qs.stringify({
      amount: _amount,
      receiver_id: _receiver_id,
      description: _description,
      is_private: _is_private,
    });

    axios
      .post(
        `${process.env.REACT_APP_API_URI}/api/user/transfers/make`,
        payload
      )
      // to do, burada error dönüyor en son halledilecek.
      .then((res) => {
        //console.log("make trans response ......", res.data);
        if (res.data) {
          resolve(res.data);
        }
      })
      .catch((err) => reject(err));
  });
};

export const getUserSuggestions = (txt, callback) => {
  axios
    .get(
      `${process.env.REACT_APP_API_URI}/api/search-user?term=${txt}`
    )
    .then(({ data }) => {
      // console.log("USER SEARCH  ......", txt);
      // console.log("USER SEARCH RESPONSE ......", data);
      callback(data?.data);
    })
    .catch((err) => callback({ err }));
};

export const updateProfileImage = (formData, callback) => {
  let token = localStorage.getItem("token");
  token = token?.replace(/['"]+/g, "");

  axios
    .post(
      `${process.env.REACT_APP_API_URI}/api/user/profile/avatar-save`,
      formData,
      {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
    .then(({ data }) => {
      //console.log("update profile  ......", data);
      callback(data?.data?.avatar);
    })
    .catch((err) => callback({ err }));
};

export const handleGetAddressByPostalCode = (postalCode) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_API_URI}/api/get-address-by-postal-code/${postalCode}`)
      .then(({ data }) => {
        resolve(data?.data);
      })
      .catch((err) => resolve("... all goals error", err));
  });

}