import React from "react";
import MainHeader from "../components/MainHeader";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Footer from "../components/Footer";
import pattern_hero from "../assets/pattern_hero.png";
import ProfileCard from "../components/Profile/ProfileCard";
import { useTranslation } from "react-i18next";
import firebase from "firebase/compat/app";
// import './PaymentSuccess.css'; // Assuming you store your responsive styles here

function PaymentSuccess({ profileData }) {
  const history = useHistory();
  const totals = history.location?.state?.data;
  const { t } = useTranslation();

  let totalPrice = parseFloat(totals?.dollarPrice) + parseFloat(totals?.shipmentPrice);
  totalPrice = totalPrice.toFixed(2);

  React.useEffect(() => {
    document.title = `magikk - ${t("cart.payment_success")}`;
    firebase.analytics().logEvent('purchase', {
      total_karma: totals?.karmaPrice,
      total_dollars: totals?.dollarPrice,
      total_shipment: totals?.shipmentPrice || 0,
      donations_count: profileData?.donations_count || 0,
      is_social: (profileData?.is_apple || profileData?.is_gmail || profileData?.is_facebook) || false,
      email: profileData?.email || '',
      id: profileData?.id || '',
    });
  }, []);

  profileData.total_karma -= totals?.karmaPrice;
  return (
    <div className="bg-bodyBg">
      <MainHeader search={true} />
      <div className="mx-auto px-4 sm:px-6 lg:px-8 flex flex-col lg:flex-row">
        <div className="flex-1 flex flex-col">
          <div className="text-2xl lg:text-3xl mb-4 lg:mb-8 flex items-center">
            <div className="whitespace-nowrap">
              {t("payment_success.confirmed")}
            </div>
            <div className="flex-grow border-t border-black"></div>
          </div>
          <div
            className="bg-no-repeat bg-center bg-primary rounded-xl p-4 pb-12 h-96"
            style={{ backgroundImage: `url(${pattern_hero})` }}
          >
            <h4 className="text-xl lg:text-3xl text-white mx-4 lg:mx-24 text-center my-4 lg:my-8">
              {t("payment_success.thank_you_to_contribute_to_a_better_economy!")}
            </h4>
            <p className="text-sm lg:text-lg text-white mx-4 lg:mx-24 text-center my-4 lg:my-8">
              {t("payment_success.We_just_sent_you_an_email_to")}{" "}
              <span className="underline">
                {profileData?.email}
              </span>{" "}
              {t("payment_success.with_your_order_details")}
            </p>
          </div>
        </div>

        <div className="mt-6 lg:mt-0 lg:ml-10 p-4 lg:p-10 bg-secondary rounded-xl flex flex-col items-center text-white">
          <ProfileCard page={"cart"} />
          <div className="border-t-2 mt-4 border-white w-full"></div>
          <div className="w-full flex flex-col my-3 space-y-2">
            {totalPrice && totalPrice > 0 && (
              <div className="flex justify-between items-center text-blue-400">
                <span>Total</span>
                <span className="text-xl">
                  {"R$ "} {totalPrice}
                </span>
              </div>
            )}
            {totals?.karmaPrice && totals?.karmaPrice > 0 && (
              <div className="flex justify-between items-center">
                Total karma {totals?.karmaPrice} kk
              </div>
            )}
          </div>
          <button
            className="btn-1 btn-effects text-white font-bold whitespace-nowrap p-4 w-full mt-8"
            onClick={() => history.push("/")}
          >
            {t("payment_success.back_to_magikk")}
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
}

const mapState = ({ auth, profile }) => ({
  profileData: profile.user || auth.user,
});

export default connect(mapState)(PaymentSuccess);
