import React, { useState, useEffect } from "react";
import { AiOutlineRight, AiOutlineDown } from "react-icons/ai";
import { connect } from "react-redux";
import { getSustainableGoals } from "../../redux/Auth/auth.actions";
import {
  handleUserSustGoals,
  handleUpdateSustGoals,
} from "../../redux/Auth/auth.helpers";
import SustainableItem from "../PublicProfile/SustainableItem";
import { useTranslation } from "react-i18next";
import { setToastrTxt } from "../../redux/Cart/cart.actions";

function YourSustainableInterests({
  dispatchGetSustainableGoals,
  sustainableGoals,
  dispatchSetToastrTxt,
}) {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);
  const [userSusList, setUserSusList] = useState([]);

  useEffect(() => {
    dispatchGetSustainableGoals();
    handleUserSustGoals().then((res) => {
      setUserSusList(res?.map((s) => s.id));
    });
  }, []);

  const handleSubmit = async () => {
    const resp = await handleUpdateSustGoals(userSusList);
    if (resp?.success) {
      dispatchSetToastrTxt({
        title: t("toastr.sustainable_goals"),
        txt: t("toastr.successfully_update"),
      });
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      setTimeout(() => {
        dispatchSetToastrTxt();
      }, 3500);
    }
  };

  return (
    <div className="bg-white rounded-ftxl mb-8 p-4 md:p-8">
      <div
        className="flex flex-row justify-between items-center px-4 md:px-8 rounded-ftxl btn-effects"
        onClick={() => setShow(!show)}
      >
        <h3 className="font-LatoRegular text-base md:text-xl font-bold flex-wrap">
          {t("your_sust_int.your_sust_int")}
        </h3>
        {show ? (
          <AiOutlineDown size={21} />
        ) : (
          <AiOutlineRight size={21} />
        )}
      </div>
  
      <div
        className={`transform transition-all duration-500 ${
          !show ? "max-h-0" : "max-h-100"
        } overflow-hidden h-auto font-LatoRegular`}
      >
        <div className="w-full grid md:grid-cols-3 grid-cols-1">
          {sustainableGoals?.map((sG) => {
            const isSelected =
              userSusList && userSusList?.includes(sG.id);
            return (
              <div className="px-4 pb-2" key={sG.id}>
                <SustainableItem
                  item={sG}
                  setUserSusList={setUserSusList}
                  userSusList={userSusList}
                  selectable={true}
                  isSelected={isSelected}
                />
              </div>
            );
          })}
        </div>
  
        <div className="flex flex-row justify-around items-center px-4 md:px-12 py-4 mb-6">
          <div className="w-full md:w-5/12"></div>
          <div className="w-full md:w-5/12">
            <div
              onClick={handleSubmit}
              className="btn-1 btn-effects mx-auto py-4 md:py-6 w-full md:w-48 whitespace-nowrap"
            >
              {t("your_pass.update_changes")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ auth }) => ({
  sustainableGoals: auth.sustainableGoals,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchGetSustainableGoals: () => dispatch(getSustainableGoals()),
  dispatchSetToastrTxt: (_txt) => dispatch(setToastrTxt(_txt)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(YourSustainableInterests);
