import i18next from "i18next";
import authTypes from "./auth.types";

const setLocalStorageLang = (data) => {
  localStorage.setItem("lang", data);
};

const INITIAL_STATE = {
  loading: true,
  user: false,
  lang: "pt",
  // successModal: true
};

const userReducer = (state = INITIAL_STATE, action) => {
  // console.log(action.type, action.payload);
  switch (action.type) {
    case authTypes.START_FACEBOOK_LOGIN:
      return {
        ...state,
        loading: true,
      };
    case authTypes.START_GOOGLE_LOGIN:
      return {
        ...state,
        loading: true,
      };
    case authTypes.START_APPLE_LOGIN:
      return {
        ...state,
        loading: true,
      };
    case authTypes.LOADING:
      return {
        ...state,
        loading: true,
      };
    case authTypes.CREATE_ACCOUNT:
    case authTypes.EMAIL_SIGN_IN_START:
      return {
        ...state,
        loading: true,
      };
    case authTypes.SET_PROFILE_INFO:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case authTypes.SET_SUSTAIN_GOALS:
      return {
        ...state,
        loading: false,
        sustainableGoals: action.payload,
      };
    case authTypes.CHECK_CURRENT_USER:
      return {
        ...state,
        loading: true,
      };

    case authTypes.SIGN_OUT_SUCCESS:
      return {
        ...INITIAL_STATE,
        loading: false,
      };

    case authTypes.SET_FORM_ERROR:
      return {
        ...state,
        formError: action.payload,
        loading: false,
      };

    case authTypes.SIGN_OUT_START:
      return {
        ...state,
        loading: true,
      };

    case authTypes.SET_LANG:
      i18next.changeLanguage(action.payload);
      setLocalStorageLang(action.payload);
      return {
        ...state,
        lang: action.payload,
      };
    case authTypes.SHOW_SUCCESS_MODAL:
      return {
        ...state,
        successModal: true,
      };
    case authTypes.HIDE_SUCCESS_MODAL:
      return {
        ...state,
        successModal: false,
      };

    //////

    case authTypes.GET_PROFILE_INFO:
      return {
        ...state,
        loading: true,
      };
    case authTypes.GET_MODAL:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default userReducer;
