import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-modal';
import { AiOutlineClose } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { startGetSelectedCategoryData } from '../../redux/Market/market.actions';

function CategoryModal({
  setSelectedCategoryName,
  categoryModalOpen,
  setCategoryModalOpen,
  goodsOrCauses,
  setGoodsOrCauses,
}) {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const categories = useSelector((state) => state.market.categories);

  const productCats = categories?.products;
  const causeCats = categories?.causes;

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '10px',
    },
  };

  const onCategoryChange = (item) => {
    const type = item.type === 'goods' ? 'goods' : 'causes';
    setGoodsOrCauses(type);
    dispatch(startGetSelectedCategoryData({ id: item.id, type }));
    let name = i18n.language === 'en' ? item?.name_en : item?.name;
    setSelectedCategoryName(name);
    setCategoryModalOpen(false);
    // setModalState(item.name);
  };

  return (
    <Modal
      isOpen={categoryModalOpen}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <AiOutlineClose
        size={20}
        className="text-black btn-effects"
        onClick={() => {
          setCategoryModalOpen(false);
        }}
      />
      <h2 className="font-VolksDemiBold text-md w-full text-center mb-4 text-2xl">
        {t('category.what_you_looking')}
      </h2>
      <div className="flex flex-row">
        <div className="mr-16">
          <h3 className="font-bold mb-2">{t('category.rewards')}</h3>
          {productCats?.map((pC) => (
            <div
              key={pC?.id}
              onClick={() => {
                onCategoryChange(pC);
              }}
              className="border-b py-1 btn-effects"
            >
              {i18n.language === 'en' ? pC.name_en : pC.name}
            </div>
          ))}
        </div>
        <div>
          <h3 className="font-bold mb-2">{t('category.causes')}</h3>

          {causeCats?.map((cC, index) => (
            <div
              key={index}
              onClick={() => {
                onCategoryChange(cC);
              }}
              className="border-b py-1 btn-effects"
            >
              {i18n.language === 'en' ? cC.name_en : cC.name}
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
}

export default CategoryModal;
