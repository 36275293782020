import React, { useState } from "react";
import colors from "../../constants/colors";
import helpers from "../../helpers/textFormat.js";
import { AiOutlineQrcode } from "react-icons/ai";
import { AiTwotoneHeart } from "react-icons/ai";
// import { start } from "@craco/craco/lib/cra";
import {
  AiOutlineClockCircle,
  AiOutlineLink,
  AiOutlineRight,
  AiOutlineCalendar,
} from "react-icons/ai";

import { IoLocationOutline } from "react-icons/io5";

function CauseProfileItem({ item, showModal, setQrModal }) {
  const activity_string = item?.title;
  const description =
    item?.description.length > 200
      ? item?.description.substring(0, 200) + "..."
      : item?.description;
  const location = item?.location;
  const imageSource =
    item?.images && item.images[0]?.url ? (
      <img src={item.images[0].url} className="object-cover rounded-full " alt="cause"/>
    ) : (
      <AiTwotoneHeart color={colors.theme} size={28} />
    );

  const starts = new Date(item?.start_date);
  const ends = new Date(item?.finish_date);

  return (
    <div className="flex flex-col bg-white font-LatoRegular justify-between rounded-ftxl shadow-md mb-2 border-b font-LatoRegular ">
      <div className="flex flex-row bg-white w-full items-center justify-start pr-4 rounded-ftxl">
        <a
          href={`/cause-detail/${item?.id}`}
          target="_blank"
          className="w-2/12 items-center justify-center flex h-20 p-6 py-16 hover:opacity-75 cursor-pointer  "
        >
          <div className="bg-secondary h-14 w-14 justify-center items-center flex rounded-xl relative aspect-square border-bodyBg border">
            {imageSource}
          </div>
        </a>
        <a
          href={`/cause-detail/${item?.id}`}
          target="_blank"
          className="w-6/12 py-8  text-sm hover:opacity-75 cursor-pointer"
        >
          <h4 className="font-VolksMedium font-bold mb-2">{activity_string}</h4>
          <h3 className="mb-1 text-primary flex flex-row">
            <AiOutlineCalendar size={21} className="mr-2" />{" "}
            {helpers.dateFormat(starts, true)} -{" "}
            {helpers.dateFormatFromTo(starts, ends, true)}
          </h3>
          <h3 className="mb-1 text-primary flex flex-row">
            <IoLocationOutline size={21} className="mr-2" /> {location}
          </h3>

          <h4 className="text-gray-400">{description}</h4>
        </a>
        <div
          className="w-10 h-10 ml-auto btn-effects mr-16 flex roundend-2xl bg-bodyBg hover:opacity-50"
          onClick={() => {
            setQrModal({
              title: activity_string,
              location: JSON.stringify(item?.location),
              amount: item?.amount,
            });
          }}
        >
          <AiOutlineQrcode color={"black"} size={42} />
        </div>
      </div>
    </div>
  );
}

export default CauseProfileItem;
