import React from "react";
import { connect } from "react-redux";
import register1 from "../../../assets/register-images/register1.png";
import register2 from "../../../assets/register-images/register2.png";
import register3 from "../../../assets/register-images/register3.png";
import register4 from "../../../assets/register-images/register4.png";
import shadow from "../../../assets/shadow.png";

export const RegisterHero = (props) => {
  return (
    <div className="bg-theme h-75 font-LatoRegular w-full md:w-1/2 relative mt-12 md:mt-0">
      <img
        src={shadow}
        alt="good"
        className="bg-secondary w-9/12 top-0 right-0 absolute rounded-xl"
      />
      <div className="w-9/12 top-24 right-24 flex flex-row justify-around">
        <div className="w-1/2 flex-col justify-between z-10 bg-theme h-full px-2">
          <img src={register1} alt="good" className="mt-5 mb-5 rounded-xl" />
          <img src={register3} alt="good" className="mt-5 mb-5 rounded-xl" />
        </div>
        <div className="w-1/2 flex-col z-10 px-2">
          <img src={register2} alt="good" className="mt-10 mb-10 rounded-xl" />
          <img src={register4} alt="good" className="mt-10 mb-10 rounded-xl" />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterHero);
