import profileTypes from "./profile.types";

const INITIAL_STATE = {
  loading: false,
  // user
  lang: "tr",
  toastrTxt: "",
};

const marketReducer = (state = INITIAL_STATE, action) => {
  // console.log(action.type, action.payload);
  switch (action.type) {
    case profileTypes.RESET:
      return INITIAL_STATE;
    case profileTypes.START_GET_USER_TRANSACTIONS:
    case profileTypes.START_GET_FOREIGN_TRANSACTIONS:
    case profileTypes.START_GET_USER_CAUSES:
      return {
        ...state,
        loading: true,
      };
    case profileTypes.SET_PROFILE_DATA:
      return {
        ...state,
        user: action.payload,
      };
    case profileTypes.SET_USER_TRANSACTIONS:
      // console.log(" general data ...   ", action.payload);
      let transactionsPageNumber =
        action.payload.current_page.toString();
      // console.log(" page  umber ... ", transactionsPageNumber);
      let userTransactions;
      if (transactionsPageNumber == "1") {
        userTransactions = action.payload.data;
      } else {
        // console.log(transactionsPageNumber);
        userTransactions = state.userTransactions;
        userTransactions = userTransactions.concat(
          action.payload.data
        );
      }

      return {
        ...state,
        userTransactions: userTransactions,
        loading: false,
        trsNextPageUrl: action.payload.next_page_url,
      };
    case profileTypes.SET_FOREIGN_USER:
      return {
        ...state,
        foreignUser: action.payload,
      };
    case profileTypes.CLEAR_TRANSACTIONS:
      return {
        ...state,
        userTransactions: [],
        loading: false,
        trsNextPageUrl: "",
        foreignNextPageUrl: "",
        foreignTransactions: [],
      };
    case profileTypes.SET_USER_CAUSES:
      // console.log(" my user causes : ", action.payload);
      return {
        ...state,
        userCauses: action.payload,
        loading: false,
      };
    case profileTypes.SET_TOASTR_TXT:
      // console.log(" set toast txt : ", action.payload);
      return {
        ...state,
        loading: false,
        toastrTxt: action.payload,
      };

    case profileTypes.START_GET_ADDRESS_BY_POSTAL_CODE:
        // console.log(" set toast txt : ", action.payload);
        return {
          ...state,
          addressByPostalCode: action.payload,
        };
    default:
      return state;
  }
};

export default marketReducer;
