import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import img_magikk from "../../assets/new-landing-page/builder/d2497f501d0c62cb10b48ea8d34220a9449ae9193317d0396629e2ea6afeea1b.svg";

const scrollToAnchor = (anchorId) => {
    const anchorElement = document.getElementById(anchorId);
    if (anchorElement) {
        anchorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
};

export const Menu = (props) => {
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <div className="flex justify-between items-center px-5 w-full relative z-50"
                style={{ backgroundImage: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(91deg, #7FD0DD -3.25%, #74B8DB 100%)' }}
            >
                <div className="flex flex-col flex-1 justify-center w-4/12 hover:cursor-pointer"
                    onClick={() => history.push('/entrance')}
                >
                    <div className="flex flex-col justify-center items-start md:px-10 md:py-4">
                        <img
                            loading="lazy"
                            src={img_magikk}
                            className="max-w-full aspect-[3.85] w-[136px]"
                        />
                    </div>
                </div>
                <div className="flex flex-row justify-center md:w-3/12 w-8/12  items-end gap-0 text-sm text-cyan-950">
                    <a className="px-4 py-6 hover:cursor-pointer" onClick={() => history.push('/login')}>Login</a>
                    <a className="px-4 py-6 hover:cursor-pointer" onClick={() => history.push('/register')}>Criar conta</a>
                </div>


                {/* <button
                    className="md:hidden flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
                </button>

                <div className={`${isOpen ? "flex" : "hidden"} md:flex absolute md:relative top-full md:top-auto left-0 w-full md:w-9/12 flex-col md:flex-row gap-0 text-sm font-[510] text-cyan-950 z-50 ${props.companyForm ? 'opacity-0' : ''}`}
                    style={{ backgroundImage: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(91deg, #7FD0DD -3.25%, #74B8DB 100%)' }}
                >
                    <a className="px-4 py-6 hover:cursor-pointer" onClick={() => scrollToAnchor('entregas-de-impacto')}>Como atuamos?</a>
                    <a className="px-4 py-6 hover:cursor-pointer" onClick={() => scrollToAnchor('marketplace')}>Marketplace</a>
                    <a className="px-4 py-6 hover:cursor-pointer" onClick={() => scrollToAnchor('karma')}>karma</a>
                    <div
                        onClick={() => history.push('/market')}
                        className="px-4 py-6 hover:cursor-pointer"
                    >Doe Agora</div>
                    <div
                        onClick={() => history.push('/login')}
                        className="px-4 py-6 hover:cursor-pointer"
                    >Login</div>
                </div> */}
            </div>
        </>
    );
};
