import authTypes from "./auth.types";

export const emailSignInStart = (userCredentials) => ({
  type: authTypes.EMAIL_SIGN_IN_START,
  payload: userCredentials,
});

export const checkCurrentUser = () => {
  return {
    type: authTypes.CHECK_CURRENT_USER,
  };
};

export const setProfileInfo = (profile) => {
  return {
    type: authTypes.SET_PROFILE_INFO,
    payload: profile,
  };
};

export const startFacebookLogin = (data) => {
  return {
    type: authTypes.START_FACEBOOK_LOGIN,
    payload: data,
  };
};

export const startGoogleLogin = (data) => ({
  type: authTypes.START_GOOGLE_LOGIN,
  payload: data,
});

export const startAppleLogin = (data) => ({
  type: authTypes.START_APPLE_LOGIN,
  payload: data,
});

/////// ********
///

export const createAccountSuccess = () => {
  return {
    type: "CREATE_ACCOUNT_SUCCESS",
  };
};

export const startGetProfileData = () => {
  return {
    type: authTypes.START_GET_PROFILE_DATA,
  };
};

export const getProfileInfo = () => {
  return {
    type: authTypes.GET_PROFILE_INFO,
  };
};

export const createAccount = (account) => {
  return {
    type: authTypes.CREATE_ACCOUNT,
    payload: account,
  };
}; // bunun bitişi de setProfileInfo ya bağlanıyor

export const setFormError = (errorMessage) => {
  return {
    type: authTypes.SET_FORM_ERROR,
    payload: errorMessage,
  };
};

export const signOutStart = () => ({
  type: authTypes.SIGN_OUT_START,
});

export const signOutSuccess = () => ({
  type: authTypes.SIGN_OUT_SUCCESS,
});

// sustainable goals

export const getSustainableGoals = () => ({
  type: authTypes.GET_SUSTAIN_GOALS,
});

export const setSustainableGoals = (goals) => ({
  type: authTypes.SET_SUSTAIN_GOALS,
  payload: goals,
});

// modal actions

export const setLang = (payload) => {
  return {
    type: authTypes.SET_LANG,
    payload,
  };
};

export const showSuccessModal = () => {
  return {
    type: authTypes.SHOW_SUCCESS_MODAL,
  };
};

export const hideSuccessModal = () => {
  return {
    type: authTypes.HIDE_SUCCESS_MODAL,
  };
};
