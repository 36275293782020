import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Provider, connect } from "react-redux";
import store from "./redux/createStore";
import "./i18n";
import { CookiesProvider } from 'react-cookie';

ReactDOM.render(
    <Provider store={store}>
    {/* <PersistGate loading={null}>	   */}


      <Suspense fallback={<div>Loading...</div>}>
        <CookiesProvider>
          <App />
        </CookiesProvider>
      </Suspense>
      {/* </PersistGate> */}
    </Provider>,
  document.getElementById("root")
);
