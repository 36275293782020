import React from "react";
import MainHeader from "../components/MainHeader";
import Footer from "../components/Footer";
import YourProfile from "../components/AccountSettings/YourProfile";
import YourPassword from "../components/AccountSettings/YourPassword";
import YourSustainableInterests from "../components/AccountSettings/YourSustainableInterests";
import { useTranslation } from "react-i18next";

function AccountSettings() {
  const { t, i18n } = useTranslation();

  document.title = "magikk - configurações da conta";

  return (
    <div className="bg-bodyBg">
      <MainHeader search={true} />
      <h3 className="font-LatoRegular text-2xl font-bold mb-8 lg:ml-96 md:ml-48 ml-12">
        {t("account_set.account_settings")}
      </h3>
      <div className="mx-auto w-11/12 md:w-3/5">
        <YourProfile />
        <YourPassword />
        {/* <YourPaymentMethod /> */}
        <YourSustainableInterests />
      </div>
      <Footer />
    </div>
  );
}

export default AccountSettings;
