import {
  all,
  call,
  delay,
  put,
  takeLatest,
} from "redux-saga/effects";
import cartTypes from "./cart.types";
import {
  handleCartPayment,
  handleMakeDonation,
  handleMakePix,
} from "./cart.helpers";
import { setToastrTxt, setDonationResult, setPixResult } from "./cart.actions";

function* startCartPayment({ payload }) {
  try {
    // console.log(
    //   " giden cart bilgileri ...... ...... ",
    //   JSON.stringify(payload)
    // );

    const cardes = payload.payments.find(
      (x) => x.name === "credit_card"
    );

    // console.log(" card. .. . . . . . . . .  ...... ...... ", cardes.card_info);
    const data = yield handleCartPayment(payload);

    // console.log(" gelen cart data d......... ", data);

    if (data) {
      if (data.success) {
        //alert('success');
        yield put(setToastrTxt({ title: "Payment", txt: "Success" }));
        yield delay(2000);
        yield put(setToastrTxt());
        // yield put(showSuccessPaymentModal(true));
      } else {
        if (data.message) {
          //alert(data.message);
          yield put(
            setToastrTxt({
              title: "Payment",
              txt: data.message,
              err: true,
            })
          );
          yield delay(2000);
          yield put(setToastrTxt());
        }
      }
    } else {
      yield put(
        setToastrTxt({
          title: "Payment",
          txt: "Payment Error",
          err: true,
        })
      );
      yield delay(2000);
      yield put(setToastrTxt());
      //alert('Error');
    }
  } catch (err) {
    yield put(
      setToastrTxt({
        title: "Payment",
        txt: "Payment Error",
        err: true,
      })
    );
    yield delay(2000);
    yield put(setToastrTxt());
    //console.log('CART PAYMENT ERROR : ', err);
    //alert('Error');
  }
}

export function* onStartCartPayment() {
  //console.log(' saga ... . . . . . . . . . . . ');
  yield takeLatest(cartTypes.START_CART_PAYMENT, startCartPayment);
}

// make donation

function* startMakeDonation({ payload }) {
  // console.log('donation', payload)
  try {
    // console.log(
    //   'inside saga................................................................',
    //   payload,
    // );
    const data = yield handleMakeDonation(payload);
    if (data && data.success) {
      //console.log('make dona data...', data);

      // if (!data.message || data.message === "Success") {
      yield put(setDonationResult({ data: true }));

      // console.log(data);
      // data?.cause?.title
      // data?.payment?.value
      yield put(setToastrTxt({ title: "Donation successfull. " }));
      yield delay(2000);
      yield put(setToastrTxt());
      //yield put({ success: false });
    } else {
      //alert(data.message);
      yield put(setDonationResult({ data: false }));

      let message = "Unknown error";
      let title = "Donation failed.";

      if (
        data?.data?.errors[0]?.code &&
        data?.data?.errors[0]?.description
      ) {
        title = data?.data?.errors[0]?.code;
        message = data?.data?.errors[0]?.description;
      } else if (data?.title && data?.message) {
        title = data?.title;
        message = data?.message;
      }

      yield put(
        setToastrTxt({
          title: title,
          err: true,
          txt: message,
        })
      );
      yield delay(2000);
      yield put(setToastrTxt());
      //yield put({ success: false });
    }
  } catch (err) {
    //console.log('make dona error', err);
    yield put(setDonationResult({ data: false }));
    yield put(
      setToastrTxt({ title: "Donation failed. ", err: true })
    );
    yield delay(2000);
    yield put(setToastrTxt());
  }
}

export function* onStartMakeDonation() {
  yield takeLatest(cartTypes.START_MAKE_DONATION, startMakeDonation);
}

// MAKE PIX

function* startMakePix({ payload }) {
  // console.log('bbbbbstart make pix')
  try {
    console.log('start make pix')
    // console.log(
    //   'inside saga................................................................',
    //   payload,
    // );
    const data = yield handleMakePix(payload);
    if (data && data.success) {
      //console.log('make dona data...', data);

      // if (!data.message || data.message === "Success") {
      yield put(setPixResult({ data }));

      // console.log(data);
      // data?.cause?.title
      // data?.payment?.value
      yield put(setToastrTxt({ title: "Pix gerado. " }));
      yield delay(2000);
      yield put(setToastrTxt());
      //yield put({ success: false });
    } else {
      //alert(data.message);
      yield put(setPixResult({ data: false }));

      let message = "Unknown error";
      let title = "Pix failed.";

      if (
        data?.data?.errors[0]?.code &&
        data?.data?.errors[0]?.description
      ) {
        title = data?.data?.errors[0]?.code;
        message = data?.data?.errors[0]?.description;
      } else if (data?.title && data?.message) {
        title = data?.title;
        message = data?.message;
      }

      yield put(
        setToastrTxt({
          title: title,
          err: true,
          txt: message,
        })
      );
      yield delay(2000);
      yield put(setToastrTxt());
      //yield put({ success: false });
    }
  } catch (err) {
    //console.log('make dona error', err);
    yield put(setPixResult({ data: false }));
    yield put(
      setToastrTxt({ title: "Pix failed. ", err: true })
    );
    yield delay(2000);
    yield put(setToastrTxt());
  }
}

export function* onstartMakePix() {
  yield takeLatest(cartTypes.START_MAKE_PIX, startMakePix);
}

export default function* cartSagas() {
  yield all([
    // call(onStartCartPayment),
    call(onStartMakeDonation),
    call(onstartMakePix),
  ]);
}
