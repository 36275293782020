import React from "react";
import { connect } from "react-redux";
import company_hero_2 from "../../assets/company_hero_2.png";
import { useTranslation } from "react-i18next";

export const CompanySolutionStatic = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="container mx-auto pt-16 pb-16">
      <div className="flex flex-col justify-between items-center mb-16">
        <h4 className="font-VolksDemiBold text-3xl">
          {t("i_am_ngo.xxx")}
        </h4>
        <div className="w-full flex flex-row mt-20 mb-16">
          <div className="w-1/2 pr-8 text-justify">
          {t("i_am_ngo.yyy")}

          </div>
          <div className="w-1/2 pl-8 text-justify">
          {t("i_am_ngo.zzz")}

          </div>
        </div>
      </div>

      {/* gallery  */}
      <div className="flex flex-row justify-center">
        <div className="w-1/2 p-4">
          <img src={company_hero_2} className="rounded-ftxl shadow-md" alt="company hero 2"/>
        </div>
        {/* <div className="grid grid-cols-2 w-1/2">
          
        </div> */}
      </div>

    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CompanySolutionStatic);
