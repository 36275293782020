import React from "react";

function PriceSet({ karma, price }) {
  return (
    <div className="flex flex-col items-center text-white text-sm font-bold font-VolksMedium">
      {!!price && price > 0 && (
        <div
          className={`px-6 w-full bg-primaryHard text-center rounded-tl-xl rounded-tr-xl ${
            (!karma || karma <= 0) && "rounded-br-xl rounded-bl-xl"
          } ${
            !karma || karma <= 0 ? "py-3 text-base" : "py-1"
          } whitespace-nowrap`}
        >
          R$ {price}
        </div>
      )}
      {!!karma && karma > 0 && (
        <div
          className={`px-6 w-full bg-primary text-center rounded-bl-xl rounded-br-xl ${
            (!price || price <= 0) && "rounded-tr-xl rounded-tl-xl"
          } ${
            !price || price <= 0 ? "py-3 text-base" : "py-1"
          } whitespace-nowrap`}
        >
          {karma} kk
        </div>
      )}
    </div>
  );
}

export default PriceSet;
