import React from "react";
import { connect } from "react-redux";
import colors from "../../constants/colors";
import store_play from "../../assets/store_play.png";
import store_apple from "../../assets/store_apple.png";
import entrance_large from "../../assets/entrance/entrance_large.png";
import entrance_1 from "../../assets/entrance/entrance_1.png";
import entrance_2 from "../../assets/entrance/entrance_2.png";
import entrance_3 from "../../assets/entrance/entrance_3.png";
import entrance_4 from "../../assets/entrance/entrance_4.png";
import { useTranslation } from "react-i18next";
import { IoLogoTiktok } from "react-icons/io5";

import {
  AiOutlineInstagram,
  AiFillLinkedin,
  AiFillFacebook,
} from "react-icons/ai";

export const OurCommunity = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="container mx-auto pt-16 px-10">
      <div className="flex flex-col md:flex-row justify-between items-center mb-16">
        <h4 className="font-VolksDemiBold text-2xl">
          {t("entrance.our_community")}
        </h4>
        <div className="flex flex-row w-full md:w-1/5 items-center justify-between md:justify-end">
          <a
            href="https://www.instagram.com/magikkarma/?hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            <AiOutlineInstagram color={colors.theme} size={24} />
          </a>
          <a
            href="https://www.linkedin.com/company/magikk-co"
            target="_blank"
            rel="noopener noreferrer"
          >
            <AiFillLinkedin color={colors.theme} size={24} />
          </a>

          <a
            href="https://m.facebook.com/magikkarma/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <AiFillFacebook color={colors.theme} size={24} />
          </a>

          <a
            href="https://vm.tiktok.com/ZMLefs8Tf/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IoLogoTiktok color={colors.theme} size={24} />
          </a>
        </div>
      </div>

      {/* gallery  */}
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-1/2 p-4">
          <img src={entrance_large} className="rounded-ftxl shadow-md" alt="entrance large"/>
        </div>
        <div className="grid grid-cols-2 w-full md:w-1/2">
          <div className="p-4">
            <img src={entrance_1} className="rounded-ftxl shadow-md" alt="entrance 1"/>
          </div>
          <div className="p-4">
            <img src={entrance_2} className="rounded-ftxl shadow-md" alt="entrance 2"/>
          </div>
          <div className="p-4">
            <img src={entrance_3} className="rounded-ftxl shadow-md" alt="entrance 3"/>
          </div>
          <div className="p-4">
            <img src={entrance_4} className="rounded-ftxl shadow-md" alt="entrance 4"/>
          </div>
        </div>
      </div>

      <div className="w-full text-center font-VolksDemiBold text-2xl py-16">
        {t("entrance.download_app")}
      </div>
      <div className="flex-row mx-auto w-full md:w-4/12 flex justify-center md:justify-end">
        <a
          href="https://play.google.com/store/apps/details?id=br.com.magikk"
          target="_blank"
          rel="noopener noreferrer"
          className="w-5/12 btn-effects mr-3"
        >
          <img src={store_play} alt="store play"/>
        </a>
        <a
          href="https://apps.apple.com/us/app/magikk-goods-and-good-deeds/id1502548875"
          target="_blank"
          rel="noopener noreferrer"
          className="w-5/12 btn-effects"
        >
          <img src={store_apple} alt="store apple"/>
        </a>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OurCommunity);
