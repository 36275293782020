import React from "react";

import img_globo from "../../assets/new-landing-page/globo.svg";
import img_magikk_karma from "../../assets/new-landing-page/magikk_karma.svg";

import "./tailwind.js";

export const KarmaStamp = () => {
    return (
        <>
            <div className="flex flex-col flex-wrap gap-y-2.5 justify-center content-end px-2.5 mt-2.5 w-full border-b border-solid shadow-lg border-slate-500 border-opacity-20 max-md:max-w-full">
                <div className="flex justify-center items-center px-16 max-md:px-5 max-md:max-w-full">
                    <div className="justify-center w-full max-w-[1179px] max-md:max-w-full">
                        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                            <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                                <div className="flex flex-col grow justify-center self-stretch py-20 text-cyan-950 max-md:max-w-full">
                                    <div className="justify-center mt-6 text-5xl font-[5] leading-[67px] max-md:max-w-full max-md:text-4xl">
                                        <span className="text-cyan-950 font-black">Selo</span>
                                        <span className="text-cyan-950">magikk</span>
                                    </div>
                                    <div className="mt-3.5 text-lg leading-7 max-md:max-w-full">
                                        Marcas que aceitam nossa moeda como pagamento e que visam o
                                        consumo mais{" "}
                                        <span className="font-bold">
                                            inclusivo, responsável e justo
                                        </span>
                                        .
                                    </div>
                                </div>
                            </div>
                            <div className={`flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full bg-[url(${img_globo})] bg-no-repeat  bg-bottom `}>
                                <div className="flex grow justify-center items-center self-stretch px-16 py-14 max-md:px-5 max-md:max-w-full">
                                    <img
                                        loading="lazy"
                                        srcSet={img_magikk_karma}
                                        className="max-w-full aspect-square w-[259px]"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}