import axios from "axios";
// import { BASE_URL } from "../../config/env";

export const handleEmailSignIn = (user) => {
  // console.log("user on helper login", user);
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_API_URI}/api/login`, user)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
};

// social login

export const handleSocialLogin = (socialUser) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URI}/api/login/social`,
        socialUser
      )
      .then(({ data }) => {
        // console.log("SOCIAL LOGIN ENDPOINT RESPONSE ......", data);
        resolve(data);
      })
      .catch((err) => reject(err));
  });
};

// create profile

export const handleCreateProfile = (user) => {
  //console.log("user on create ", user);
  return new Promise((resolve, reject) => {
    // user = {
    //   "firstname": "test",
    //   "lastname": "mail",
    //   "email": "esxddval@mail9.com",
    //   "password": "password",
    //   "password_confirmation": "password"
    // }
    axios
      .post(`${process.env.REACT_APP_API_URI}/api/register`, user)
      .then((data) => {
        // console.log("register data", data);
        // SUCCESS OR
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// export const getUserSuggestions = (txt, callback) => {
//   axios.get(`${BASE_URL}/search-user?term=${txt}`)
//     .then(({ data }) => {
//       console.log('USER SEARCH  ......', txt)
//       console.log('USER SEARCH RESPONSE ......', data)
//       callback(data?.data);
//     })
//     .catch(err => callback({err}))
// };

export const setLocalStorageTokens = async (tokens) => {
  // console.log("set token ", tokens);
  tokens
    ? localStorage.setItem("token", JSON.stringify(tokens))
    : localStorage.setItem("token", "");
  // bu güncellenebilir, başka token var mı?
};

export const handleGetSustainableGoals = async () => {
  // console.log(" handle sust goals request ");
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_API_URI}/api/sustainable-goals`)
      .then((response) => {
        const s_data = response.data.data;

        resolve(s_data);
      })
      .catch((err) => resolve("error ", err));
  });
};

// get user sust goals
export const handleUserSustGoals = async () => {
  // console.log(" handle USER sust goals request ");
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URI}/api/sustainable-goals/get-goals-by-user`
      )
      .then((response) => {
        if (response?.data?.success) {
          const s_data = response.data?.data[0]?.sustainable_goals;
          resolve(s_data);
        } else {
          resolve([]);
        }
      })
      .catch((err) => resolve("error ", err));
  });
};

// UPDATE user sust goals
export const handleUpdateSustGoals = async (newArray) => {
  // console.log(" handle USER sust goals request ");
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URI}/api/sustainable-goals/update-user-goals`,
        newArray
      )
      .then((response) => {
        resolve(response?.data);
      })
      .catch((err) => resolve("error ", err));
  });
};

// QR CODE

export const getQrCode = () => {
  // status: SUCCESS
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URI}/api/user/generate-qrcode`
      )
      .then((response) => {
        const _data = response?.data?.toString();
        const indexOfSvg = _data?.lastIndexOf("<svg");
        const _svg = _data.substring(indexOfSvg, _data.length);
        resolve(_svg);
      })
      .catch((error) => {
        console.log(error);
        resolve(error);
      });
  });
};

export const getUserWithToken = () => {
  // tokens in header
  // console.log("usertoken get saga");
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_API_URI}/api/user/profile`)
      .then((response) => {
        // console.log("/user/profile response => ", response);

        if (response.data?.data?.email) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((err) => {
        console.log("error", err);
        reject(err);
      });
  });
};
