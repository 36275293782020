import React from "react";
import colors from "../../constants/colors";
import { AiTwotoneHeart } from "react-icons/ai";

function DonationPublicItem({ item }) {
	let _activity_string =
		item?.triggerable?.public_activity_string ||
		item?.triggerable?.activity_string;
	let activity_string = _activity_string?.split(" at ")[0];
	const date_string = _activity_string?.split(" at ")[1];
	const description = item?.triggerable?.cause?.title;

	if (!!!activity_string) return null;

	return (
		<div>
			<div className="border-b">
				{
					<div className="flex flex-col public-hover font-LatoRegular justify-between relative">
						<div className="flex flex-row w-full items-center justify-start pt-4">
							<div className="w-2/12 items-center justify-center flex h-20 p-8 py-12 mr-12">
								<div className="bg-secondary justify-center items-center flex rounded-full border-2 border-primary p-2">
									<AiTwotoneHeart color={colors.theme} size={28} />
								</div>
							</div>
							<div className="w-4/5">
								<h4 className="font-LatoRegular font-bold">
									{activity_string}
								</h4>
								<h4 className="mt-4 text-gray-500">{description}</h4>
							</div>
							{/* <PriceSet karma={"22"} price={"23"} /> */}
						</div>
						<div className="font-LatoRegular text-xs text-gray-300 absolute right-0 top-0">
							{date_string}
						</div>

						<div className="border-white border-b border-bodyBg w-12/12 m-auto"></div>
					</div>
				}
			</div>
		</div>
	);
}

export default DonationPublicItem;
