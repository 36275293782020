import axios from "axios";
// import { BASE_URL } from "../../config/env";

// products

export const handleGetProducts = () => {
  // console.log(" handle products request ");
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URI}/api/products/all-products`
      )
      .then(({ data }) => {
        resolve(data?.data);
      })
      .catch((err) => resolve("...---...all_products_return-..."));
  });
};

// causes

export const handleGetCauses = () => {
  // console.log(" handle causes request ");
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_API_URI}/api/causes/all-causes`)
      .then(({ data }) => {
        // console.log("all causes data response ......", data);
        resolve(data);
      })
      .catch((err) => resolve("...---...all_causes_return-...", err));
  });
};

// one product detail by id

export const handleGetProductDetails = (id) => {
  // console.log(" handle product request ");
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URI}/api/products/get-single-product/${id}`
      )
      .then(({ data }) => {
        // console.log("product data response ......", data.data);
        resolve(data?.data);
      })
      .catch((err) => resolve("...---...product_return-...", err));
  });
};

// one product detail by id

export const handleGetAllGoals = (id) => {
  // console.log(" handle all goals request ");
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_API_URI}/api/sustainable-goals`)
      .then(({ data }) => {
        // console.log("all goals response ......", data.data);
        resolve(data?.data);
      })
      .catch((err) => resolve("... all goals error", err));
  });
};

// cause details

export const handleGetCauseDetails = (cause_id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URI}/api/causes/get-single-cause/${cause_id}`
      )
      .then(({ data }) => {
        // console.log("get single cause ......", data?.data?.cause);
        resolve(data?.data?.cause);
      })
      .catch((err) => reject(err));
  });
};

//
// categories

export const handleGetCategories = () => {
  // console.log(" handle categories request ");
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_API_URI}/api/products/categories`)
      .then((res) => {
        // console.log(
        //   "product categories data response ......",
        //   res.data.data
        // );
        const p_data = res.data.data;
        axios
          .get(
            `${process.env.REACT_APP_API_URI}/api/causes/categories`
          )
          .then((response) => {
            // console.log(
            //   "causes categories data response ......",
            //   response.data.data
            // );
            const c_data = response.data.data;
            const data = {
              products: p_data,
              causes: c_data,
            };
            resolve(data);
          });
      })
      .catch((err) => resolve("...---...error-prod-data-..."));
  });
};

// selected category details
export const handleGetSelectedCategoryData = ({ id, type }) => {
  // console.log(" handle categories request ", type, id);
  if (type === "goods") {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.REACT_APP_API_URI}/api/products/category-products/${id}`
        )
        .then((res) => {
          // console.log(
          //   "product categories data response ......",
          //   res.data.data
          // );

          resolve(res.data.data);
        })
        .catch((err) => resolve("get category error ", err));
    });
  } else {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.REACT_APP_API_URI}/api/causes/category-causes/${id}`
        )
        .then((res) => {
          // console.log(
          //   "causes categories data response ......",
          //   res.data.data
          // );
          // const p_data = res.data.data
          resolve(res.data.data);
        })
        .catch((err) => reject("get category error ", err));
    });
  }
};

// handle volunteering
export const handleVolunteering = (causeId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URI}/api/user/causes/attend-to-cause/${causeId}`
      )
      .then((res) => {
        if (res.data.success) {
          resolve(res.data.message);
        }
      })
      .catch((err) => {
        const errorMessage =
          err.response?.data?.message || err.toString();
        resolve(errorMessage);
      });
  });
};

export const handleCancelVolunteer = (id) => {
  // profile data endpoint

  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URI}/api/user/causes/leave-user/${id}`
      )
      .then(({ data }) => {
        // console.log("CANCEL ... ... response ......", data);
        resolve(data);
      })
      .catch((err) => reject(err));
  });
};

// check cause status

export const checkVolState = (causeId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URI}/api/user/causes/check-attended-status/${causeId}`
      )
      .then((res) => {
        if (res.data.success) {
          resolve(res.data?.data?.status);
        }
      })
      .catch((err) => {
        const errorMessage =
          err.response?.data?.message || err.toString();
        reject(errorMessage);
      });
  });
};

// check cause status

export const handleSubscribeSubmit = (email) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_API_URI}/api/subscriber`, {
        email: email,
      })
      .then((res) => {
        if (res.data.success) {
          resolve("success");
        } else {
          resolve(res.data?.message);
        }
      })
      .catch((err) => {
        const errorMessage =
          err.response?.data?.message || err.toString();
        resolve(errorMessage);
      });
  });
};

// handle add prodcts
export const handleAddProductsAndCauses = (pageUrl) => {
  return new Promise((resolve, reject) => {
    axios
      .get(pageUrl)
      .then(({ data }) => {
        resolve(data?.data);
      })
      .catch((err) => {
        console.log("error here : ", err);
        resolve(err);
      });
  });
};

// search

export const handleGetSearchData = (txt) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_API_URI}/api/search?term=${txt}`)
      .then((res) => {
        // console.log("search response ......", res.data.data);

        resolve(res.data.data);
      })
      .catch((err) => resolve("get search er...", err));
  });
};

// reset password
export const handleResetPassword = (mail) => {
  return new Promise((resolve, reject) => {
    //console.log(" imsid eeeeee mail   ...", mail);
    axios
      .post(
        `${process.env.REACT_APP_API_URI}/api/account/reset-password`,
        {
          email: mail,
        }
      )
      .then((res) => {
        const message = res.msg || res.data?.msg;

        resolve("success");
      })
      .catch((err) => {
        //reject('.reset pass error : ', err);
        //alert('Please enter a valid mail.');
        resolve("failed");
      });
  });
};
