const marketTypes = {
  START_LOADER: "START_LOADER",
  START_GET_PRODUCTS: "START_GET_PRODUCTS",
  SET_PRODUCTS: "SET_PRODUCTS",
  //
  START_GET_CAUSES: "START_GET_CAUSES",
  SET_CAUSES: "SET_CAUSES",
  //
  START_GET_PRODUCT: "START_GET_PRODUCT",
  SET_PRODUCT: "SET_PRODUCT",
  //
  START_GET_CAUSE: "START_GET_CAUSE",
  SET_CAUSE: "SET_CAUSE",
  //
  START_GET_ALL_GOALS: "START_GET_ALL_GOALS",
  SET_ALL_GOALS: "SET_ALL_GOALS",
  //
  START_GET_CATEGORIES: "START_GET_CATEGORIES",
  SET_CATEGORIES: "SET_CATEGORIES",
  START_GET_SELECTED_CATEGORY_DATA:
    "START_GET_SELECTED_CATEGORY_DATA",
  SET_SELECTED_CATEGORY_DATA: "SET_SELECTED_CATEGORY_DATA",
  //
  START_GET_SEARCH_DATA: "START_GET_SEARCH_DATA",
  SET_SEARCH_DATA: "SET_SEARCH_DATA",
  START_VOLUNTEERING: "START_VOLUNTEERING",
  SET_VOLUNTEERING_MESSAGE: "SET_VOLUNTEERING_MESSAGE",
  START_CANCEL_VOLUNTEER: "START_CANCEL_VOLUNTEER",
  //
  START_ADD_PRODUCTS: "START_ADD_PRODUCTS",
  START_ADD_CAUSES: "START_ADD_CAUSES",
  FINISH_ADD_PRODUCTS: "FINISH_ADD_PRODUCTS",
  FINISH_ADD_CAUSES: "FINISH_ADD_CAUSES",
};

export default marketTypes;
