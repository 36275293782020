import React from 'react'
import { connect } from 'react-redux'

import AuthHeader from '../../components/Auth/AuthHeader'
import MainSection from '../../components/Auth/ForgetPassword/MainSection'
import Footer from '../../components/Footer'

export const ForgetPassword = (props) => {

    document.title = "magikk - esqueci minha senha";

    return (
        <div>
        <AuthHeader />
        <MainSection />
        <Footer />
    </div>
    )
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword)
