import React from "react";
import { connect } from "react-redux";
import LoginForm from './LoginForm';
import LoginHero from './LoginHero';

export const MainSection = (props) => {
  return (
    <div className="container mx-auto flex flex-col md:flex-row md:mt-12">
      <LoginForm />
      <LoginHero />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MainSection);
