import { combineReducers } from "redux";

import authReducer from "./Auth/auth.reducer";
import marketReducer from "./Market/market.reducer";
import profileReducer from "./Profile/profile.reducer";
import cartReducer from "./Cart/cart.reducer";

export const rootReducer = combineReducers({
  auth: authReducer,
  market: marketReducer,
  profile: profileReducer,
  cart: cartReducer,
});

export default rootReducer;
