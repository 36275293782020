import cartTypes from "./cart.types";

const INITIAL_STATE = {
  loading: false,
};

const cartReducer = (state = INITIAL_STATE, action) => {
  // console.log('state cart',state);
  switch (action.type) {
    case cartTypes.START_CART_PAYMENT:
      // console.log(' start _ cart _ payment : ', action.payload);
      return {
        ...state,
        loading: false,
      };
    case cartTypes.SET_DONATION_RESULT:
      return {
        ...state,
        donation_result: action.payload,
      };
    case cartTypes.SET_PIX_RESULT:
      // console.log('set pix result', action.payload)
      return {
        ...state,
        pix_result: action.payload,
      };
    case cartTypes.CLEAR_CART:
      // console.log('set pix result', action.payload)
        state = undefined;
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default cartReducer;
