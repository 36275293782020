import React from "react";
import { connect } from "react-redux"; 
import login_hero from "../../../assets/login_hero.png";
import shadow from "../../../assets/shadow.png";

export const LoginHero = (props) => {
  return (
    <div className="bg-theme h-75 font-LatoRegular w-full md:w-1/2 relative mt-12 md:mt-0 hidden sm:block">
      <img src={shadow} alt="login background" className="bg-secondary w-9/12 top-0 right-0 absolute rounded-xl" />
      <img src={login_hero} alt="login" className="w-9/12 top-24 right-24 absolute z-2"/>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LoginHero);
