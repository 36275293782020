import React, { useState } from "react";
import { handleSubscribeSubmit } from "../../redux/Market/market.helpers";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { setToastrTxt } from "../../redux/Cart/cart.actions";

function BePartOf({ dispatchSetToastrTxt }) {
  const { t, i18n } = useTranslation();

  const [subscribeEmail, setSubscribeEmail] = useState("");

  const triggerToastr = ({ toastTxt, err }) => {
    dispatchSetToastrTxt({
      title: t("toast.subscription"),  
      txt: toastTxt,
      err: err,
    });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setTimeout(() => {
      dispatchSetToastrTxt();
    }, 3500);
  };

  const clickSubscribeSubmit = async () => {
    if (!subscribeEmail) {
      const txt_alert = t("forget_pass.enter_txt");
      dispatchSetToastrTxt({
        title: t("toast.subscription"),
        txt: txt_alert,
        err: true,
      });
      setTimeout(() => {
        dispatchSetToastrTxt();
      }, 3500);
    }

    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const isValid = validateEmail(subscribeEmail);

    if (!isValid) {
      const txt_alert2 = "Please enter a valid email.";
      dispatchSetToastrTxt({
        title: t("toast.subscription"),
        txt: txt_alert2,
        err: true,
      });
      setTimeout(() => {
        dispatchSetToastrTxt();
      }, 3500);
    } else {
      const subscription = await handleSubscribeSubmit(
        subscribeEmail
      );

      if (subscription === "success") {
        triggerToastr({
          toastTxt: t("toast.register_success"),
        });
      } else {
        triggerToastr({
          toastTxt: t("toast.register_error"),
          err: true,
        });
      }
    }
  };

  return (
    <div className="mx-auto container my-24">
      <div className="overflow-hidden responsive w-full bg-primary h-64 rounded-ftxl flex flex-col items-center justify-around font-VolksDemiBold text-white mt-2">
        <h3 className="w-12/12 md:w-4/12 text-3xl">{t("be_part.be_part")}</h3>
        <div className="bg-paleSecondary md:w-8/12 w-11/12 h-32 md:h-16 rounded-2xl flex md:flex-row flex-col items-center pr-2 py-2 pl-6">
          <input
            placeholder={t("forget_pass.enter_txt")}
            type="text"
            className="w-full ml-2 pl-2 font-LatoRegular placeholder-white bg-transparent outline-none md:text-2xl h-48"
            onChange={(e) => {
              const value = e.target.value;
              setSubscribeEmail(value);
            }}
          />
          <div
            onClick={clickSubscribeSubmit}
            className="md:w-3/12 w-full px-4 ml-auto bg-white flex-row text-center flex justify-center h-full text-black items-center rounded-xl btn-effects"
          >
            <span className="font-OpenSansRegular">
              {t("login.sign_up")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchSetToastrTxt: (_txt) => dispatch(setToastrTxt(_txt)),
  };
};

export default connect(null, mapDispatchToProps)(BePartOf);
