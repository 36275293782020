import React, { useState } from "react";
import { AiOutlineRight, AiOutlineDown } from "react-icons/ai";
import { handleUpdatePassword } from "../../redux/Profile/profile.helpers";
import { useTranslation } from "react-i18next";
import { setToastrTxt } from "../../redux/Cart/cart.actions";
import { connect } from "react-redux";
function YourPassword({ dispatchSetToastrTxt }) {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);
  const [passwordData, setPasswordData] = useState({});
  const [borderError, setBorderError] = useState(false);

  const handleSubmit = async () => {
    const updatePassword = await handleUpdatePassword(passwordData);
    if (!updatePassword.success) {
      setBorderError(true);
      let errMessage = t("your_pass.update_error");
      //console.log("Testing");
      if (updatePassword?.response?.data?.data) {
        //console.log(updatePassword.response);
        let messages =
          updatePassword.response.data.data[
            Object.keys(updatePassword.response.data.data)[0]
          ];
        //console.log(messages);
        if (messages.length > 0 && typeof messages[0] == "string") {
          errMessage = messages[0];
        }
      }
      dispatchSetToastrTxt({
        title: t("toastr.profile"),
        txt: errMessage,
        err: true,
      });
      setTimeout(() => {
        dispatchSetToastrTxt();
      }, 3500);
    } else {
      setBorderError(false);
      const alert_txt = t("your_pass.update_success");

      dispatchSetToastrTxt({
        title: t("toastr.profile"),
        txt: alert_txt,
      });
      setTimeout(() => {
        dispatchSetToastrTxt();
      }, 3500);
    }
  };

  const inputStyle = { paddingLeft: "14px" };
  inputStyle.borderColor = borderError && "red";

  return (
    <div className="bg-white rounded-ftxl mb-8">
      <div
        className="flex flex-row justify-between align-center py-8 px-16 rounded-ftxl btn-effects"
        onClick={() => setShow(!show)}
      >
        <h3 className="font-LatoRegular text-xl font-bold">
          {t("your_pass.your_pass")}
        </h3>
        {show ? (
          <AiOutlineDown size={21} />
        ) : (
          <AiOutlineRight size={21} />
        )}
      </div>

      <div
        className={`transform transition-all duration-500 ${
          !show ? "max-h-0" : "max-h-100"
        } overflow-hidden h-auto font-LatoRegular flex justify-between flex-col`}
      >
        <div className="flex flex-row justify-center py-4">
          <div className="w-10/12">
            <p className="mb-4">{t("your_pass.curr_pass")}</p>
            <input
              style={inputStyle}
              onChange={(e) => {
                setBorderError(false);
                setPasswordData({
                  ...passwordData,
                  current_password: e.target.value?.trim(),
                });
              }}
              type="password"
              className="input-classic w-full"
            />
          </div>
        </div>
        <div className="flex flex-row justify-center py-4">
          <div className="w-10/12">
            <p className="mb-4">{t("your_pass.new_pass")}</p>
            <input
              style={inputStyle}
              onChange={(e) => {
                setBorderError(false);

                setPasswordData({
                  ...passwordData,
                  new_password: e.target.value?.trim(),
                });
              }}
              type="password"
              className="input-classic w-full"
            />
          </div>
        </div>
        <div className="flex flex-row justify-center py-4">
          <div className="w-10/12">
            <p className="mb-4">{t("your_pass.confirm_pass")}</p>
            <input
              style={inputStyle}
              onChange={(e) => {
                setBorderError(false);

                setPasswordData({
                  ...passwordData,
                  new_confirm_password: e.target.value?.trim(),
                });
              }}
              type="password"
              className="input-classic w-full"
            />
          </div>
        </div>
        <div className="flex flex-row justify-around px-12 py-4 mb-6">
          <div className="w-5/12"></div>
          <div className="w-5/12">
            <div
              onClick={handleSubmit}
              className="btn-1 btn-effects ml-auto py-6 md:w-48 whitespace-nowrap"
            >
              {t("your_pass.update_changes")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  dispatchSetToastrTxt: (_txt) => dispatch(setToastrTxt(_txt)),
});

export default connect(null, mapDispatchToProps)(YourPassword);
