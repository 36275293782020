import React from "react";
import fake from "../../assets/market-body/darkProd/darkProd@2x.png";
import { IoTrashOutline } from "react-icons/io5";
import PriceSet from "../Shared/PriceSet";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { startGetProduct } from "../../redux/Market/market.actions";

function CartItem({ item, onUpdate, onDelete }) {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch(null);
  const history = useHistory();
  const [piece, setPiece] = React.useState(item.piece);

  const { title, user, price, karma, images, amount } = item;
  const { firstname, lastname } = user;

  // console.log(" inside item ... ... .. ..... ", item);

  const organizationName =
    item?.organization?.name ||
    `${item?.user?.firstname} ${item?.user?.lastname}`;

  const retrival = item.is_virtual ? "On Site" : "Cargo";

  const imageUrl = images.length ? images[0]?.url : fake;

  const netPrice = parseInt(price);
  const netKarma = karma;

  const handleClick = () => {
    dispatch(startGetProduct(item.id));
    history.push(`/product-detail/${item.id}`);
  };

  const updateItemPiece = (raise) => {
    const result = localStorage.getItem("cart");
    if (result) {
      var array = JSON.parse(result);

      array = array.map((cartitem) => {
        if (cartitem.id == item.id) {
          if (raise) {
            cartitem.piece += 1;
          } else {
            cartitem.piece -= 1;
          }
        }

        return cartitem;
      });

      localStorage.setItem("cart", JSON.stringify(array));
      const _piece = raise ? piece + 1 : piece - 1;
      setPiece(_piece);
      onUpdate();
    }
  };

  return (
    <div className="bg-white rounded-kkxl h-25 w-full my-2 mr-4 p-4 flex flex-row items-center">

      <div
        onClick={handleClick}
        className="h-14 w-14 justify-center items-center flex rounded-xl relative aspect-square border-bodyBg border"
      >

        <img
          src={imageUrl}
          className="h-full w-auto mr-1 btn-effects"
          alt={"item"}
        />
      </div>

      <div className="mr-6 ml-8 btn-effects" onClick={handleClick}>
        <h3 className="font-VolksMedium text-base font-medium">
          {title}
        </h3>
        <h3 className="font-LatoRegular text-sm">
          {organizationName}
        </h3>

        <h3 className="font-LatoRegular text-sm">
          <span className="text-sm font-normal mr-4 text-gray-500">
            Retrieval:
          </span>{" "}
          {retrival}
        </h3>
      </div>
      <div className="ml-auto mr-4">
        <div className="flex flex-row font-LatoRegular text-3xl items-center">
          <div
            className="btn-effects text-4xl"
            onClick={() => {
              if (piece > 1) {
                updateItemPiece();
              }
            }}
          >
            -
          </div>
          <div className="rounded-xl border mx-2 border-textGrey py-1 px-4 text-xl">
            {piece}
          </div>
          <div
            className="btn-effects"
            onClick={() => {
              if (piece < amount) {
                updateItemPiece(true);
              } else {
                const alert_txt = t("cart.alert");
                alert(`${alert_txt} ${amount}`);
              }
            }}
          >
            +
          </div>
        </div>
      </div>

      <div>
        <PriceSet karma={netKarma} price={netPrice} />
      </div>

      <div
        className="mr-4 text-red-300 btn-effects"
        onClick={onDelete}
      >
        <IoTrashOutline size="19" />
      </div>
    </div>
  );
}

export default CartItem;
