import React from 'react';
import { connect } from 'react-redux';
import white_logo from '../assets/white_logo/white.png'
import smartphone from '../assets/smartphone-3/smartphone-3.png'
import google_play from '../assets/google-play-badge/google-play-badge-01.png'
import app_store from '../assets/app_store/app_store.png'

export const Mobile = (props) => {
  return <div className='flex-1 w-full h-full'>
      <div className="bg-primary h-32 flex items-center justify-center">
        <img alt='logo' src={white_logo} className='w-56' />
      </div>
      <div className='bg-bodyBg h-24 rounded-ftxl -mt-6'></div>
      <div className='w-full items-center flex flex-col'>
          <div className='py-8 font-VolksMedium text-2xl'>welcome to magikk</div>
          <div className='bg-secondary rounded-full items-center justify-center flex w-44 h-44'>
              <img src={smartphone} className='w-20 ml-4'/>
          </div>
          <div className='text-center py-6 font-LatoRegular text-xl w-8/12'>download magikk app for a better experience</div>
          <div className='flex items-center flex-col justify-center'>
              <img className='my-4' src={app_store}/>
              <img src={google_play}/>
          </div>
      </div>
  </div>;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Mobile);
