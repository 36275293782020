import React from 'react';
import { connect } from 'react-redux';
import MainHeader from '../../components/MainHeader';
import Footer from '../../components/Footer';
import SustainableList from '../../components/Auth/SustainableList';
import sust_goals from '../../assets/sust_goals.png';
import { useTranslation } from 'react-i18next';

export const SetSustainableGoals = (props) => {
  const { t, i18n } = useTranslation();

  document.title = "magikk - interesses sustentáveis";

  return (
<div>
  <MainHeader />
  <div className="container mx-auto pb-32 pt-16 flex flex-col md:flex-row">
    <div className="rounded-ftxl bg-primary w-full md:w-3/12 text-white mb-8 md:mb-0">
      <h4 className="text-2xl font-VolksMedium pl-6 pr-4 md:pr-20 text-left pt-8">
        {t('i_am_ngo.sign_up_text')}
      </h4>
      <p className="font-LatoRegular pl-6 pr-4 md:pr-12 pt-4 text-xs mb-8 leading-5">
        {t('i_am_ngo.sign_up_body')}
      </p>
      <div className="flex items-center justify-center px-8">
        <img
          src={sust_goals}
          className="object-cover rounded-ftxl -mb-16"
        />
      </div>
    </div>
    <div className="rounded-ftxl w-full md:w-9/12 h-auto md:h-100 pt-10">
      <SustainableList />
    </div>
  </div>
  <Footer />
</div>

  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SetSustainableGoals);
