import axios from "axios";
// import { BASE_URL } from "../../config/env";

// CART PAYMENT

export const handleCartPayment = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        // `${process.env.REACT_APP_API_URI}/api/user/orders/make?language=${language}`,
        `${process.env.REACT_APP_API_URI}/api/user/orders/make`,

        data
      )
      .then((res) => {
        // console.log("order make RESPONSE ***", res.data);
        // if (res.success) {
        resolve(res.data);
        // }
      })
      .catch((err) => {
        // console.log(" res data : payment : ", err.response.data);

        let errMessage = "Payment failed :(";
        let errTitle = "Payment error";
        if (
          err.response?.data?.data &&
          typeof err.response?.data?.data === "string"
        ) {
          errMessage = err.response.data.data;
        }
        if (
          err.response?.data?.message &&
          typeof err.response?.data?.message === "string"
        ) {
          errTitle = err.response.data.message;
        }

        reject({
          success: false,
          message: errMessage,
          title: errTitle,
        });
        //resolve(err);
      });
  });
};

// make donation
export const handleMakeDonation = (payload) => {
  const { data, language } = payload;
  // console.log(
  //   " donation/make payload data  => ",
  //   JSON.stringify(data.data)
  // );
  // console.log(" donation/make ID => ", JSON.stringify(data.id));

  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URI}/api/user/donation/make/${data.id}?language=${language}`,
        data.data
      )
      .then((res) => {
        // console.log("DONATION MAKE RESPONSE ***", res.data);
        // if (res.success) {
        resolve(res.data);
        // }
      })
      .catch((err) => {
        // console.log("DONATION ERR : ", err.response.data);

        let errMessage = "Donation failed :(";
        let title = "Donation error!";
        if (
          err.response?.data?.data &&
          typeof err.response?.data?.data === "string"
        ) {
          errMessage = err.response.data.data;
        }
        if (
          err.response?.data?.message &&
          typeof err.response?.data?.message === "string"
        ) {
          title = err.response.data.message;
        }
        console.log(err.response);

        resolve({
          success: false,
          title: title,
          message: errMessage,
        });

        // if (err?.response?.data?.message) {
        //   errMessage = err.response.data.message;
        // }

        // resolve({
        //   success: false,
        //   message: errMessage,
        // });
      });
  });
};

// make pix
export const handleMakePix = (payload) => {
  // console.log('handlemakepix')
  const { data, language } = payload;
  // console.log(
  //   " donation/make payload data  => ",
  //   JSON.stringify(data.data)
  // );
  // console.log(" donation/make ID => ", JSON.stringify(data.id));

  return new Promise((resolve, reject) => {
    // console.log('helper')
    axios
      .post(
        `${process.env.REACT_APP_API_URI}/api/user/donation/make-pix?language=${language}`,
        data.data
      )
      .then((res) => {
        // console.log("DONATION MAKE RESPONSE ***", res.data);
        // if (res.success) {
          // console.log('resolve',res)
        resolve(res.data);
        // }
      })
      .catch((err) => {
        // console.log("DONATION ERR : ", err.response.data);

        let errMessage = "Donation failed :(";
        let title = "Donation error!";
        if (
          err.response?.data?.data &&
          typeof err.response?.data?.data === "string"
        ) {
          errMessage = err.response.data.data;
        }
        if (
          err.response?.data?.message &&
          typeof err.response?.data?.message === "string"
        ) {
          title = err.response.data.message;
        }
        console.log(err.response);

        resolve({
          success: false,
          title: title,
          message: errMessage,
        });

        // if (err?.response?.data?.message) {
        //   errMessage = err.response.data.message;
        // }

        // resolve({
        //   success: false,
        //   message: errMessage,
        // });
      });
  });
};

// get shipment price

export const getShipmentPrice = (userPostalCode, ngoPostalCode) => {
  return new Promise((resolve, reject) => {
    const payload = {
      cargo_calculate: {
        from: {
          postal_code: ngoPostalCode,
        },
        to: {
          postal_code: userPostalCode,
        },
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_URI}/api/user/orders/basket-price-calculation`,
        payload
      )
      .then((res) => {
        // console.log(" calculate shipment result ... : ", res.data);

        // if (res.success) {
        resolve(res.data?.price);
        // }
      })
      .catch((err) => {
        // console.log(
        //   " get shipment price ... err ...... ....... ........  : ",
        //   err
        // );
        resolve(err);
      });
  });
};

// delivery info

export const handleGetDeliveryInfo = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URI}/api/delivery-information/user`
      )
      .then((res) => {
        // console.log(" get delivery info ... : ", res.data);

        // if (res.success) {
        resolve(res.data?.data);
        // }
      })
      .catch((err) => {
        // console.log(
        //   " get delivery info ... err ...... ....... ........  : ",
        //   err.response.data
        // );
        resolve(err);
      });
  });
};

// NEEDS

export const handleGetNeeds = (products) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URI}/api/user/orders/get-needs`,
        products
      )
      .then((res) => {
        // console.log(" get NEEDS ... : ", res.data);
        resolve(res.data);
      })
      .catch((err) => {
        // console.log(
        //   " get NEEDS ... err ...... ....... ........  : ",
        //   err.response.data
        // );
        resolve(err);
      });
  });
};

// update Delivery Info

export const handleUpdateDeliveryInfo = (delInf) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URI}/api/delivery-information/user/update`,
        delInf
      )
      .then((res) => {
        // console.log(" update DE.INF ... : ", res?.data);
        resolve(res?.data);
      })
      .catch((err) => {
        // console.log(
        //   " UPDATE DELIVERY ... err ...... ....... ........  : ",
        //   err?.response?.data
        // );
        reject(err);
      });
  });
};

// handle calculate price

export const handleCalculateBasket = (prods, language) => {
  return new Promise((resolve, reject) => {
    // console.log(" price calculate prods ... ", prods);
    axios
      .post(
        `${
          process.env.REACT_APP_API_URI
        }/api/user/orders/basket-price-calculation?language=${
          language || "pt"
        }`,
        prods
      )
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//
