import React from "react";
import { useTranslation } from "react-i18next";

function HistoricHeader({ selectedTab, setSelectedTab }) {
  const { t, i18n } = useTranslation();

  const unselectedClass = "bg-white text-primary";
  return (
    <div className="w-full mb-10 md:px-44">
      <div className="mx-4 overflow-hidden rounded-t-ftxl">
        <div className="shadow-sm bg-white flex flex-row p-4 rounded-ftxl">
          <h4 className="mr-auto btn-1 bg-white text-black">
            {t("profile.Your_Historic")}
          </h4>
          <div
            className={`mr-2 btn-1 btn-effects font-LatoRegular text-sm ${
              selectedTab === "causes" && unselectedClass
            }`}
            onClick={() => setSelectedTab("transactions")}
          >
            {t("profile.trans")}
          </div>
          <div
            className={`btn-1 btn-effects font-LatoRegular text-sm ${
              selectedTab === "transactions" && unselectedClass
            }`}
            onClick={() => setSelectedTab("causes")}
          >
            {t("market.causes")}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HistoricHeader;
