import React from "react";
import { connect } from "react-redux";
import { AuthHeader } from "../../components/Auth/AuthHeader";
import MainHeader from "../../components/MainHeader";
import { Redirect, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../../assets/shared/logo/logo.png";

export const TermOfUse = ({ user }) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  
  document.title = "magikk - termos de uso";

  return (
    <div className="bg-bodyBg" style={{ backgroundColor: "#DDD" }}>
      {user ? <MainHeader /> : <AuthHeader />}
      <div
        style={{
          maxWidth: "1000px",
          margin: "auto",
          marginTop: 100,
          alignContent: "center",
          alignItems: "center",
          backgroundColor: "#FFF",
          borderRadius: 40,
          padding: 70,
        }}
      >
        <>
  <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
  <meta name="Generator" content="Microsoft Word 15 (filtered)" />
  <style
    dangerouslySetInnerHTML={{
      __html:
        '\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:"Cambria Math";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n@font-face\n\t{font-family:Calibri;\n\tpanose-1:2 15 5 2 2 2 4 3 2 4;}\n@font-face\n\t{font-family:Georgia;\n\tpanose-1:2 4 5 2 5 4 5 2 3 3;}\n /* Style Definitions */\n p.MsoNormal, li.MsoNormal, div.MsoNormal\n\t{margin:0in;\n\tline-height:115%;\n\tfont-size:11.0pt;\n\tfont-family:"Arial",sans-serif;}\n.MsoChpDefault\n\t{font-family:"Arial",sans-serif;}\n.MsoPapDefault\n\t{line-height:115%;}\n@page WordSection1\n\t{size:596.0pt 842.0pt;\n\tmargin:42.55pt 54.55pt 14.5pt 56.45pt;}\ndiv.WordSection1\n\t{page:WordSection1;}\n-->\n'
    }}
  />
  <div className="WordSection1">
    <p
      className="MsoNormal"
      align="center"
      style={{ textAlign: "center", lineHeight: "normal", border: "none" }}
    >
      <span style={{ color: "black" }}>
        <img
          width={182}
          height={47}
          src={logo}
          alt="magikk"
          style={{ marginLeft: "auto", marginRight: "auto", paddingTop: 10 }}
        />
      </span>
    </p>
    <p
      className="MsoNormal"
      align="center"
      style={{
        marginTop: "9.95pt",
        textAlign: "center",
        lineHeight: "normal",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          TERMOS DE USO{" "}
        </span>
      </b>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "27.4pt",
        marginRight: "0in",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        lineHeight: "normal",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Olá! Que bom contar com seu interesse!{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "14.0pt",
        marginRight: "1.75pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textIndent: "-.3pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Antes de usar nossos serviços, tire um tempo para ler nossos Termos de
        Uso e Serviços e conhecer as regras que regem nossa relação com você.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.35pt",
        marginBottom: ".0001pt",
        marginLeft: ".4pt",
        textAlign: "justify",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Abaixo, esclareceremos alguns pontos que julgamos importantes. Caso
        persista alguma dúvida acerca de quaisquer pontos discutidos ou não
        neste documento, por favor, não hesite em contatar-nos pelo endereço:
        falacomigo@magikk.com{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "0in",
        marginBottom: ".0001pt",
        marginLeft: "1.0pt",
        lineHeight: "normal",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          1. DEFINIÇÕES:{" "}
        </span>
      </b>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "14.0pt",
        marginRight: "0in",
        marginBottom: ".0001pt",
        marginLeft: "1.1pt",
        lineHeight: "normal",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        No presente instrumento, entendemos as expressões abaixo de acordo com
        as seguintes definições:{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "14.0pt",
        marginRight: "1.05pt",
        marginBottom: ".0001pt",
        marginLeft: ".25pt",
        textAlign: "justify",
        textIndent: ".7pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          MAGIKK
        </span>
      </b>
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        : MAGIKK Serviços de Informações na Internet LTDA., sociedade empresária
        limitada, com seus atos constitutivos arquivados na Junta Comercial do
        Estado do Rio de Janeiro sob o NIRE 33.1.0594014-1, inscrita no Cadastro
        Nacional de Pessoas Jurídicas sob o nº 35.352.544/0001-05 e com sede à
        Avenida das Américas, nº 08585, Loja SS0216, Bairro Barra da Tijuca, na
        Cidade do Rio de Janeiro, no Estado do Rio de Janeiro, CEP 22.793-081.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.3pt",
        marginBottom: ".0001pt",
        marginLeft: ".3pt",
        textAlign: "justify",
        textIndent: ".6pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          PLATAFORMA:{" "}
        </span>
      </b>
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Sistema virtual disponibilizado através do sítio eletrônico{" "}
      </span>
      <u>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "#1155CC" }}>
          https://www.magikk.com/
        </span>
      </u>
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "#1155CC" }}>
        {" "}
      </span>
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        e por aplicativo mobile disponível na Apple Store e Google Play, através
        do qual a <b>MAGIKK </b>, as <b>EMPRESAS ANUNCIANTES </b>e as{" "}
        <b>ONGS </b>oferecem seus serviços e/ou produtos para os <b>CLIENTES </b>
        .
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "0in",
        marginBottom: ".0001pt",
        marginLeft: ".95pt",
        lineHeight: "normal",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          USUÁRIOS:{" "}
        </span>
      </b>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "14.0pt",
        marginRight: "1.55pt",
        marginBottom: ".0001pt",
        marginLeft: ".6pt",
        textAlign: "justify",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          a. CLIENTE:{" "}
        </span>
      </b>
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Pessoa física ou jurídica cadastrada na <b>PLATAFORMA </b>, que, através
        do <b>KARMA </b>, acesse e/ou utilize os serviços e/ou produtos ofertados
        pela <b>MAGIKK </b>, pelas <b>EMPRESAS ANUNCIANTES </b>ou pelas{" "}
        <b>ONGS </b>na <b>PLATAFORMA </b>.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: ".4pt",
        marginRight: "1.15pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textAlign: "justify",
        textIndent: ".15pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          b. EMPRESA ANUNCIANTE:{" "}
        </span>
      </b>
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Pessoa física ou jurídica cadastrada na <b>PLATAFORMA </b>, que no uso
        desta irá ofertar os seus produtos e/ou serviços aos <b>CLIENTES </b>e,
        se for de seu interesse, participar das formas de praticar{" "}
        <b>BOAS AÇÕES </b>disponibilizadas pelas <b>ONGS </b>.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: ".4pt",
        marginRight: "1.8pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textIndent: "-.1pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          c. ONG:{" "}
        </span>
      </b>
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Organização sem fins lucrativos cadastrada na <b>PLATAFORMA </b>, que nos
        uso desta irá disponibilizar, ao <b>CLIENTE </b>e à{" "}
        <b>EMPRESA ANUNCIANTE</b>, formas de praticar <b>BOAS AÇÕES </b>.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.7pt",
        marginBottom: ".0001pt",
        marginLeft: ".65pt",
        textAlign: "justify",
        textIndent: ".3pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          KARMA:{" "}
        </span>
      </b>
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Programa de pontuação através do qual se incentiva e beneficia o{" "}
        <b>CLIENTE</b>que pratica <b>BOA AÇÃO </b>, sendo utilizável como moeda
        de troca por serviços e/ou produtos oferecidos pela <b>MAGIKK </b>, pelas{" "}
        <b>EMPRESAS ANUNCIANTES </b>e pelas <b>ONGS </b>na <b>PLATAFORMA </b>ou
        fora dela, em seus estabelecimentos comerciais. Não se confunde com
        dinheiro, embora o <b>CLIENTE </b>possa converter o seu dinheiro em{" "}
        <b>KARMA </b>. Cada <b>KARMA </b>equivale a R$ 1,00 (um real),{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: ".95pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textAlign: "justify",
        textIndent: ".25pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          BOA AÇÃO:{" "}
        </span>
      </b>
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        A prática, pelo <b>CLIENTE</b>, de atos que que gerem impacto social,
        econômico e cultural positivo para a sociedade, tais como, mas não se
        limitando a, doações em dinheiro com o intuito de ajudar pessoas
        carentes, doação e/ou distribuição de alimentos a pessoas carentes,
        doação e/ou distribuição de kits de higiene pessoal a pessoas carentes e
        limpeza voluntária de ambientes coletivos.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "14.0pt",
        marginRight: "1.55pt",
        marginBottom: ".0001pt",
        marginLeft: ".25pt",
        textAlign: "justify",
        textIndent: "-.1pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          TRATAMENTO DE DADOS:{" "}
        </span>
      </b>
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Nos termos do artigo 5, inciso X, da Lei 13.709 de 2018, toda operação
        realizada pela <b>MAGIKK </b>com os dados pessoais dos <b>USUÁRIOS </b>,
        como as que se referem a coleta, produção, recepção, classificação,
        utilização, acesso, reprodução, transmissão, distribuição,
        processamento, arquivamento, armazenamento, eliminação, avaliação ou
        controle da informação, modificação, comunicação, transferência, difusão
        ou extração.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "0in",
        marginBottom: ".0001pt",
        marginLeft: ".65pt",
        lineHeight: "normal",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          2. ADESÃO:{" "}
        </span>
      </b>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "14.0pt",
        marginRight: "1.75pt",
        marginBottom: ".0001pt",
        marginLeft: ".95pt",
        textIndent: ".15pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Este instrumento regula as condições de uso da <b>PLATAFORMA </b>, sendo
        um contrato entre os <b>USUÁRIOS </b>e a <b>MAGIKK </b>celebrado quando
        do uso da <b>PLATAFORMA </b>.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: ".3pt",
        marginBottom: ".0001pt",
        marginLeft: ".35pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        A utilização dos serviços oferecidos através da <b>PLATAFORMA </b>indica
        expressamente que você concorda com todos os termos e condições contidos
        neste instrumento e com as disposições legais aplicáveis à espécie.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: ".8pt",
        marginBottom: ".0001pt",
        marginLeft: ".95pt",
        textIndent: "-.6pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          VOCÊ ENTENDE E CONCORDA QUE A MAGIKK CONSIDERARÁ O USO DA PLATAFORMA
          COMO ACEITAÇÃO DESTES TERMOS E TODAS AS DEMAIS DISPOSIÇÕES LEGAIS
          PERTINENTES À ESPÉCIE.{" "}
        </span>
      </b>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: ".9pt",
        marginBottom: ".0001pt",
        marginLeft: ".25pt",
        textAlign: "justify",
        textIndent: ".05pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          AO ACEITAR OS TERMOS DO PRESENTE INSTRUMENTO, O USUÁRIO AUTORIZA
          EXPRESSAMENTE O TRATAMENTO DE SEUS DADOS, A FIM DE GARANTIR A
          MANUTENÇÃO E O BOM DESEMPENHO DAS FUNCIONALIDADES DA PLATAFORMA.{" "}
        </span>
      </b>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: ".65pt",
        marginBottom: ".0001pt",
        marginLeft: ".95pt",
        textAlign: "justify",
        textIndent: "-.3pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          O USUÁRIO, NESTE ATO, MANIFESTA O SEU COMPLETO CONSENTIMENTO PARA O
          COMPARTILHAMENTO DOS DADOS COLETADOS E TRATADOS PELA EMPRESA, NOS
          TERMOS DESTE INSTRUMENTO, COM OUTRAS EMPRESAS QUE FAÇAM PARTE DE SEU
          GRUPO ECONÔMICO, OU SEJAM SUAS PRESTADORAS DE SERVIÇO.{" "}
        </span>
      </b>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.1pt",
        marginBottom: ".0001pt",
        marginLeft: "1.0pt",
        textIndent: "-.25pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Caso você <b>NÃO CONCORDE </b>com as disposições previstas neste
        instrumento, <b>NÃO </b>acesse, visualize, baixe ou utilize de qualquer
        forma nenhuma página, conteúdo, informação ou serviço da <b>EMPRESA </b>.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: ".05pt",
        marginBottom: ".0001pt",
        marginLeft: "1.0pt",
        textIndent: "-.25pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Os presentes termos estão disponíveis para leitura, a qualquer momento,
        na <b>PLATAFORMA,</b>em
        https://drive.google.com/drive/folders/1iBl9b90OhvNciYgxgiWYSKQoRKTGSryK?usp=sharing
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "0in",
        marginBottom: ".0001pt",
        marginLeft: ".65pt",
        lineHeight: "normal",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          3. QUEM SOMOS E O QUE FAZEMOS:{" "}
        </span>
      </b>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "14.0pt",
        marginRight: "1.3pt",
        marginBottom: ".0001pt",
        marginLeft: ".65pt",
        textAlign: "justify",
        textIndent: "-.25pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        A <b>MAGIKK </b>é uma empresa privada que, por meio da <b>PLATAFORMA </b>
        , através do <b>KARMA </b>, incentiva e beneficia a prática de{" "}
        <b>BOAS AÇÕES </b>pelos
        <b>CLIENTES </b>, a quem é facultado trocar <b>KARMA </b>por produtos
        e/ou serviços oferecidos pela <b>EMPRESA </b>, pelas{" "}
        <b>EMPRESAS ANUNCIANTES </b>e pelas <b>ONGS </b>.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "0in",
        marginBottom: ".0001pt",
        marginLeft: ".4pt",
        lineHeight: "normal",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          4. CONDIÇÕES GERAIS DE USO:{" "}
        </span>
      </b>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "14.0pt",
        marginRight: "1.25pt",
        marginBottom: ".0001pt",
        marginLeft: ".9pt",
        textAlign: "justify",
        textIndent: "-.5pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        A <b>MAGIKK </b>somente licencia a <b>PLATAFORMA </b>que conecta os{" "}
        <b>USUÁRIOS </b>, sendo a sua responsabilidade restrita apenas ao
        funcionamento correto da <b>PLATAFORMA </b>e de suas funcionalidades,
        conforme este instrumento, não detendo a <b>MAGIKK </b>nenhuma
        responsabilidade sobre:{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "0in",
        marginBottom: ".0001pt",
        marginLeft: ".75pt",
        lineHeight: "normal",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          (i){" "}
        </span>
      </b>
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        As negociações a serem realizadas entre os <b>USUÁRIOS </b>;{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: ".55pt",
        marginRight: "1.55pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textIndent: ".05pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          (ii)
        </span>
      </b>
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        A verificação se a <b>EMPRESA ANUNCIANTE </b>e a <b>ONG </b>realmente
        detêm a capacidade técnica eventualmente informada ao <b>CLIENTE</b>;{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "14.0pt",
        marginRight: "1.05pt",
        marginBottom: ".0001pt",
        marginLeft: ".6pt",
        textIndent: ".1pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          (iii)
        </span>
      </b>
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        A qualidade da prestação dos serviços e/ou do produto fornecido pela{" "}
        <b>EMPRESA ANUNCIANTE </b>e pela <b>ONG</b>;{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: ".4pt",
        marginRight: "0in",
        marginBottom: ".0001pt",
        marginLeft: ".75pt",
        lineHeight: "normal",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          (iv){" "}
        </span>
      </b>
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        A efetivação de qualquer pagamento acertado entre os <b>USUÁRIOS </b>;{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: ".55pt",
        marginRight: "1.25pt",
        marginBottom: ".0001pt",
        marginLeft: "1.0pt",
        textIndent: "-.25pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          (v)
        </span>
      </b>
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Eventuais problemas oriundos de demora ou de má prestação de serviços
        e/ou fornecimento de produtos pela <b>EMPRESA ANUNCIANTE </b>e{" "}
        <b>ONG</b>;{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: ".4pt",
        marginRight: "1.85pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textIndent: ".05pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          (vi)
        </span>
      </b>
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Eventual descumprimento por um dos <b>USUÁRIOS </b>do que fora combinado
        no momento da negociação; e,{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: ".4pt",
        marginRight: "0in",
        marginBottom: ".0001pt",
        marginLeft: ".75pt",
        lineHeight: "normal",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          (vii){" "}
        </span>
      </b>
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Qualquer outro ato ou fato decorrente da conduta dos <b>USUÁRIOS </b>.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "14.0pt",
        marginRight: "1.05pt",
        marginBottom: ".0001pt",
        marginLeft: ".4pt",
        textAlign: "justify",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        A <b>MAGIKK </b>possibilita que os <b>CLIENTES </b>e as{" "}
        <b>EMPRESAS ANUNCIANTES </b>contatem-se e negociem entre si de forma
        direta, sem qualquer intervenção, seja na negociação ou na efetivação do
        que fora negociado, não sendo a <b>MAGIKK </b>fornecedora de quaisquer
        dos serviços anunciados na <b>PLATAFORMA </b>, ou
        empregador/representante de qualquer das <b>EMPRESAS ANUNCIANTES </b>e{" "}
        <b>ONGS </b>naquela cadastrados.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.05pt",
        marginBottom: ".0001pt",
        marginLeft: ".35pt",
        textAlign: "justify",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        A <b>MAGIKK </b>não detém nenhuma relação com as{" "}
        <b>EMPRESAS ANUNCIANTES </b>e as <b>ONGS </b>cadastrados, não sendo
        possível imputar à <b>MAGIKK </b>responsabilidade por qualquer dano
        causado aos <b>CLIENTES </b>ou a terceiros, por atos oriundos das{" "}
        <b>EMPRESAS ANUNCIANTESS </b>e/ou das <b>ONGS </b>no momento da
        prestação dos serviços e/ou do fornecimento de produtos intermediados
        através <b>PLATAFORMA </b>.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: ".95pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textAlign: "justify",
        textIndent: "-.25pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        A <b>MAGIKK </b>não interfere na negociação a ser realizada entre{" "}
        <b>USUÁRIOS </b>, ficando somente a cargo destes o acerto acerca das
        condições da prestação do serviço e/ou o fornecimento do produto a ser
        realizado, tais como o valor, a qualidade, a forma, o prazo e outros
        pontos que julgarem necessários.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.4pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textAlign: "justify",
        textIndent: ".4pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Por não figurar como parte nas transações que se realizam entre os
        <b>USUÁRIOS </b>, a <b>MAGIKK </b>também não pode obrigar os{" "}
        <b>USUÁRIOS </b>a honrarem com as possíveis obrigações assumidas no
        momento da realização das negociações na <b>PLATAFORMA </b>.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.45pt",
        marginBottom: ".0001pt",
        marginLeft: ".95pt",
        textAlign: "justify",
        textIndent: ".15pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        No momento do cadastro, os <b>USUÁRIOS </b>poderão utilizar todos os
        serviços disponibilizados na <b>PLATAFORMA </b>, declarando, para tanto,
        terem lido, compreendido e aceitado todos os dispositivos contidos neste
        Termos de Uso.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.65pt",
        marginBottom: ".0001pt",
        marginLeft: "1.0pt",
        textIndent: "-.25pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        O <b>USUÁRIO </b>se compromete a utilizar das funcionalidades da{" "}
        <b>PLATAFORMA </b>com boa-fé, de acordo com a legislação em vigor, a
        moral e os bons costumes.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "0in",
        marginBottom: ".0001pt",
        marginLeft: ".35pt",
        textAlign: "justify",
        textIndent: ".35pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        O <b>USUÁRIO </b>reconhece expressamente que, através deste instrumento,
        recebe da <b>MAGIKK </b>a outorga de uma licença de uso da{" "}
        <b>PLATAFORMA </b>, esta intransferível, sendo vedado o sublicenciamento,
        para uso em território nacional ou estrangeiro, pelo tempo em que
        perdurar a adesão a este termo, restando vedado o uso da{" "}
        <b>PLATAFORMA </b>em desacordo com o previsto neste instrumento.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.2pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textAlign: "justify",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        O <b>USUÁRIO </b>é o único responsável pela segurança de sua senha e pelo
        uso de seu cadastro na <b>PLATAFORMA </b>, por isto, recomendamos que não
        compartilhem com terceiros tais informações e, caso estas informações
        sejam, por qualquer motivo, extraviadas ou <i>hackeadas</i>, o{" "}
        <b>USUÁRIO </b>deverá informar imediatamente à <b>EMPRESA </b>, através
        do falacomigo@magikk.com, a fim de que se possa solucionar a questão.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "2.2pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Cabe somente aos <b>USUÁRIOS </b>responderem por quaisquer danos causados
        a terceiros, a outros <b>USUÁRIOS </b>, à <b>PLATAFORMA </b>ou à própria{" "}
        <b>EMPRESA </b>, decorrentes do uso das funcionalidades da{" "}
        <b>PLATAFORMA </b>.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "27.4pt",
        marginRight: "1.95pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textAlign: "justify",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Os <b>USUÁRIOS </b>não devem utilizar a <b>PLATAFORMA </b>para quaisquer
        fins ou meios ilegais, difamatórios, discriminatórios, abusivos,
        ofensivos, pornográficos, obscenos, agressivos, injuriosos, vexatórios,
        enganosos, caluniosos, violentos, vulgares, ou de assédio, ameaça ou uso
        de falsa identidade, ou seja, qualquer uso escuso que possa prejudicar a{" "}
        <b>EMPRESA </b>, outros <b>USUÁRIOS </b>ou terceiros.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.8pt",
        marginBottom: ".0001pt",
        marginLeft: "1.0pt",
        textIndent: ".05pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Em nenhuma hipótese a <b>MAGIKK </b>será responsabilizada por quaisquer
        danos suportados pelos <b>USUÁRIOS </b>por eventual indisponibilidade
        temporária da <b>PLATAFORMA </b>.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.35pt",
        marginBottom: ".0001pt",
        marginLeft: ".9pt",
        textAlign: "justify",
        textIndent: "-.15pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        O <b>USUÁRIO </b>deve possuir todos os softwares e hardwares necessários
        para acessar à <b>PLATAFORMA </b>, incluindo, mas não se limitando, a
        computador e dispositivo móvel com acesso à Internet, cabendo à{" "}
        <b>EMPRESA </b>, tão somente, disponibilizar a <b>PLATAFORMA </b>ao{" "}
        <b>USUÁRIO, </b>nos termos deste instrumento.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "2.05pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        O uso da <b>PLATAFORMA </b>pelos
        <b>USUÁRIOS </b>fica condicionado ao seu cadastro prévio, bem como ao
        respeito às disposições constantes neste instrumento.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "0in",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        lineHeight: "normal",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          5. CADASTRO:{" "}
        </span>
      </b>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "14.0pt",
        marginRight: "1.5pt",
        marginBottom: ".0001pt",
        marginLeft: "0in",
        textIndent: ".7pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Os serviços oferecidos pela <b>MAGIKK </b>estão disponíveis para pessoas
        físicas absolutamente capazes e pessoas jurídicas devidamente ativas
        perante à Receita Federal.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.85pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textIndent: ".4pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Para a utilização regular da <b>PLATAFORMA </b>, o <b>USUÁRIO </b>deverá
        efetuar cadastro, preenchendo todos os dados solicitados pela{" "}
        <b>PLATAFORMA </b>no momento do cadastramento.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.25pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textAlign: "justify",
        textIndent: ".4pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Para que os <b>USUÁRIOS </b>realizem o seu cadastro na{" "}
        <b>PLATAFORMA,</b>deverão fornecer à <b>MAGIKK </b>os seguintes dados:
        (i) Nome completo ou razão social; (ii) Número do Cadastro de Pessoa
        Física (CPF) ou Cadastro Nacional de Pessoas Jurídicas (CNPJ); (iii)
        Endereço; (iv) Endereço eletrônico/E-mail; (v) Telefone; (vi) Data de
        nascimento.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.6pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textAlign: "justify",
        textIndent: ".4pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        É de exclusiva responsabilidade dos <b>USUÁRIOS </b>fornecer, atualizar
        e garantir a veracidade dos dados cadastrais, não recaindo à{" "}
        <b>MAGIKK </b>qualquer tipo de responsabilidade civil e criminal
        resultante de dados inverídicos, incorretos ou incompletos fornecidos
        pelos <b>USUÁRIOS </b>.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.8pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textAlign: "justify",
        textIndent: "-.3pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        A <b>MAGIKK </b>se reserva o direito de utilizar todos os meios válidos
        e possíveis para identificar seus <b>USUÁRIOS </b>, bem como de solicitar
        dados adicionais e documentos que estime serem pertinentes a fim de
        conferir os dados informados. Neste caso, o uso da <b>PLATAFORMA </b>
        pelo <b>USUÁRIO </b>fica condicionado ao envio dos documentos
        eventualmente solicitados.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: ".55pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textAlign: "justify",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Caso um cadastro seja considerado suspeito de conter dados errôneos ou
        inverídicos, a <b>MAGIKK </b>se reserva ao direito de suspender,
        temporária ou definitivamente, sem necessidade de aviso prévio, o{" "}
        <b>USUÁRIO </b>responsável pelo cadastro. No caso de suspensão não
        assistirá ao <b>USUÁRIO </b>direito a qualquer tipo de indenização ou
        ressarcimento por perdas e danos, lucros cessantes ou ainda danos
        morais.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.4pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textAlign: "justify",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        O <b>USUÁRIO </b>poderá ter acesso às informações coletadas e sobre o
        tratamento de dados realizado pela <b>EMPRESA </b>, de forma gratuita,
        através de solicitação para falacomigo@magikk.com, ou através de seu
        cadastro na <b>PLATAFORMA </b>, podendo editar ou excluir as referidas
        informações a qualquer tempo.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "14.0pt",
        marginRight: "6.6pt",
        marginBottom: ".0001pt",
        marginLeft: ".4pt",
        textIndent: "-.2pt",
        lineHeight: "203%",
        border: "none"
      }}
    >
      
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        A coleta dos dados do <b>USUÁRIO </b>visa identificá-lo, bem como
        habilitá-lo ao correto uso da <b>PLATAFORMA </b>.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "2.6pt",
        marginRight: "1.3pt",
        marginBottom: ".0001pt",
        marginLeft: ".35pt",
        textAlign: "justify",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Ao consentir com os termos do presente instrumento, o <b>USUÁRIO </b>
        declara expressamente estar ciente que a coleta dos seus dados é
        primordial para o bom funcionamento da <b>PLATAFORMA </b>, autorizando,
        desde já, o tratamento de dados pela <b>EMPRESA. </b>
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: ".05pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        O <b>USUÁRIO </b>declara, ainda, estar ciente e concordar que seus dados
        poderão ser compartilhados com empresas do grupo e empresas parceiras
        com o intuito de conhecer melhor as preferências do <b>USUÁRIO </b>.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.15pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textAlign: "justify",
        textIndent: ".4pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        É expressamente vedada a criação de mais de um cadastro por{" "}
        <b>USUÁRIO </b>na <b>PLATAFORMA </b>. Em caso de multiplicidade de
        cadastros elaborados por um só <b>USUÁRIO </b>, a <b>MAGIKK </b>se
        reserva o direito de, a seu exclusivo critério, sem contrapartida
        indenizatória e sem necessidade de prévia anuência ou comunicação,
        inabilitar todos os cadastros existentes em nome deste <b>USUÁRIO </b>,
        podendo não aceitar novo cadastro do referido <b>USUÁRIO </b>na{" "}
        <b>PLATAFORMA </b>.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.35pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textAlign: "justify",
        textIndent: "-.3pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        A <b>PLATAFORMA </b>oferece a possibilidade de o <b>USUÁRIO </b>utilizar
        de algumas de suas funcionalidades sem a necessidade de cadastro,
        entretanto, a ausência de cadastro não descaracteriza o <b>USUÁRIO </b>,
        nem permite o desrespeito a este instrumento.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: ".6pt",
        marginBottom: ".0001pt",
        marginLeft: ".9pt",
        textAlign: "justify",
        textIndent: "-.15pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        O <b>USUÁRIO </b>acessará o seu cadastro na <b>PLATAFORMA </b>por meio
        de <i>login </i>e senha, comprometendo-se a não informar a terceiros
        estes dados, responsabilizando-se integralmente pelo uso que deles seja
        feito. O <b>USUÁRIO </b>será o único responsável pelas operações
        efetuadas em sua conta, uma vez que o acesso só será possível mediante a
        utilização de senha de seu exclusivo conhecimento.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.9pt",
        marginBottom: ".0001pt",
        marginLeft: "1.0pt",
        textIndent: "-.25pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        O <b>USUÁRIO </b>compromete-se a notificar a <b>MAGIKK </b>imediatamente,
        por meio dos canais de contato mantidos pela <b>MAGIKK </b>na{" "}
        <b>PLATAFORMA </b>, a respeito de qualquer uso não autorizado de sua
        conta.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.45pt",
        marginBottom: ".0001pt",
        marginLeft: ".35pt",
        textAlign: "justify",
        textIndent: ".35pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        O <b>USUÁRIO </b>compromete-se a notificar a <b>MAGIKK </b>
        imediatamente, por meio dos canais de contato mantidos pela{" "}
        <b>MAGIKK </b>na <b>PLATAFORMA </b>, a respeito de qualquer conhecimento
        de irregularidades de outros <b>USUÁRIOS </b>que possam ocasionar danos
        aos próprios <b>USUÁRIOS </b>da <b>PLATAFORMA </b>, a esta, a{" "}
        <b>MAGIKK </b>ou a terceiros.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.25pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textIndent: ".4pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Em nenhuma hipótese será permitida a cessão, a venda, o aluguel ou outra
        forma de transferência do cadastro do <b>USUÁRIO </b>.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.0pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textAlign: "justify",
        textIndent: "-.3pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Ao seu exclusivo critério a <b>MAGIKK </b>poderá excluir, inabilitar,
        criar limites no uso do serviço, suspender, bloquear, por tempo
        indeterminado, sem aviso prévio ou contrapartida indenizatória,
        cadastros de <b>USUÁRIOS </b>que sejam considerados ofensivos, que
        infrinjam os termos deste instrumento ou a legislação em vigor.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.25pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textAlign: "justify",
        textIndent: "-.25pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        A <b>MAGIKK </b>se reserva ao direito de não permitir novo cadastro de{" "}
        <b>USUÁRIOS </b>que já tenham sido cancelados, inabilitados, bloqueados,
        excluídos ou suspensos da <b>PLATAFORMA </b>. Não se permitirá, ainda, a
        criação de novos cadastros por pessoas cujos cadastros originais tenham
        sido cancelados, bloqueados, inabilitados, excluídos ou suspensos por
        infrações às políticas da <b>MAGIKK </b>ou à legislação vigente.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "14.0pt",
        marginRight: "1.1pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textAlign: "justify",
        textIndent: "-.25pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        A <b>MAGIKK </b>se reserva o direito de, unilateralmente, sem prévio
        aviso, anuência ou contrapartida indenizatória, recusar qualquer
        solicitação de cadastro de um <b>USUÁRIO </b>na <b>PLATAFORMA </b>, bem
        como cancelar, inabilitar, bloquear, excluir ou suspender o uso de um
        cadastro previamente aceito.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "2.5pt",
        marginBottom: ".0001pt",
        marginLeft: ".9pt",
        textAlign: "justify",
        textIndent: "-.5pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Ao concordar com o presente instrumento, o <b>USUÁRIO </b>declara estar
        ciente de que é o único responsável pelo seu cadastro, sendo certo que
        qualquer prejuízo causado pela inserção de informações desatualizadas,
        inexatas ou inverídicas, não poderão ser imputados à <b>MAGIKK </b>ou à{" "}
        <b>PLATAFORMA </b>.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "0in",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        lineHeight: "normal",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          5.1. CADASTRO COMO EMPRESA ANUNCIANTE E ONG:{" "}
        </span>
      </b>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "14.0pt",
        marginRight: ".95pt",
        marginBottom: ".0001pt",
        marginLeft: ".4pt",
        textAlign: "justify",
        textIndent: ".7pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Para se cadastrar como <b>EMPRESA ANUNCIANTE </b>ou <b>ONG </b>na{" "}
        <b>PLATAFORMA </b>, deve o <b>USUÁRIO </b>preencher o formulário
        solicitado pela <b>PLATAFORMA </b>, bem como enviar quaisquer outros
        documentos que venham a ser solicitados pela própria <b>PLATAFORMA </b>
        ou pela equipe da <b>EMPRESA </b>. Após o recebimento do cadastro, a{" "}
        <b>MAGIKK </b>informará a validação, ou não, do cadastro do{" "}
        <b>USUÁRIO </b>como <b>EMPRESA ANUNCIANTE </b>ou <b>ONG</b>, no prazo de
        até 72 (setenta e duas) horas úteis.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.15pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textAlign: "justify",
        textIndent: "-.3pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        A validação, ou não, de um <b>USUÁRIO </b>como{" "}
        <b>EMPRESA ANUNCIANTE </b>ou <b>ONG </b>na <b>PLATAFORMA </b>, compete
        exclusivamente a <b>EMPRESA </b>, que se reserva ao direito de recusar, a
        seu exclusivo critério, a qualquer tempo e sem contrapartida
        indenizatória, o cadastro de algum <b>USUÁRIO </b>como
        <b>EMPRESA ANUNCIANTE </b>ou <b>ONG</b>.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "0in",
        marginBottom: ".0001pt",
        marginLeft: ".65pt",
        lineHeight: "normal",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          6. DAS FUNCIONALIDADES:{" "}
        </span>
      </b>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "14.0pt",
        marginRight: "1.35pt",
        marginBottom: ".0001pt",
        marginLeft: ".3pt",
        textAlign: "justify",
        textIndent: ".05pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Aos <b>CLIENTES </b>a <b>PLATAFORMA </b>oferece a seguinte
        funcionalidade: o recebimento de <b>KARMA </b>por suas{" "}
        <b>BOAS AÇÕES </b>conforme informadas e/ou realizadas através da{" "}
        <b>PLATAFORMA </b>, este programa de pontuação podendo ser utilizado como
        moeda de troca por produtos e/ou serviços oferecidos pelas{" "}
        <b>EMPRESAS ANUNCIANTES </b>e <b>ONGS </b>.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.7pt",
        marginBottom: ".0001pt",
        marginLeft: ".6pt",
        textIndent: "-.2pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Às <b>EMPRESAS ANUNCIANTES </b>e às <b>ONGS </b>a <b>PLATAFORMA </b>
        oferece a seguinte funcionalidade: conectar os <b>CLIENTES </b>aos seus
        produtos e/ou serviços.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "2.1pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textIndent: "-.3pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        A <b>MAGIKK </b>poderá a qualquer tempo, sem necessidade de aviso prévio,
        ou contrapartida indenizatória, editar e/ou excluir as funcionalidades
        existentes, bem como incluir novas funcionalidades à <b>PLATAFORMA </b>.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "0in",
        marginBottom: ".0001pt",
        marginLeft: ".65pt",
        lineHeight: "normal",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          7. OBRIGAÇÕES DAS EMPRESAS ANUNCIANTES E DAS ONGS:{" "}
        </span>
      </b>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "14.0pt",
        marginRight: "2.1pt",
        marginBottom: ".0001pt",
        marginLeft: ".65pt",
        textIndent: ".05pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Cabe unicamente à <b>EMPRESA ANUNCIANTE </b>e à <b>ONG </b>emitir o
        documento fiscal correspondente aos serviços prestados e/ou produtos
        fornecidos, ao <b>CLIENTE. </b>
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.45pt",
        marginBottom: ".0001pt",
        marginLeft: ".6pt",
        textAlign: "justify",
        textIndent: "-.2pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        A <b>EMPRESA ANUNCIANTE </b>e a <b>ONG </b>cadastrados declaram que
        detêm capacidade legal e técnica para prestar o serviço e/ou fornecer o
        produto ofertado na <b>PLATAFORMA, </b>bem como declaram que são os
        únicos responsáveis por todo e qualquer serviço que realize e/ou produto
        que forneça em favor dos <b>CLIENTES </b>e que tenha sido intermediado
        através da <b>PLATAFORMA </b>.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.05pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textAlign: "justify",
        textIndent: "-.3pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Ainda, a <b>EMPRESA ANUNCIANTE </b>e a <b>ONG </b>ficam responsáveis por
        qualquer dano ocasionado ao <b>CLIENTE </b>ou a terceiros, pelos
        serviços ou produtos que, porventura, deixe de prestar e/ou fornecer; ou
        que seja prestado e/ou fornecido em desacordo com o que fora acertado
        entre as partes; ou, ainda, que apresente{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "14.0pt",
        marginRight: "1.15pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textIndent: "-.3pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        falha ou vícios. Nestes termos, todo e qualquer prejuízo suportado pela{" "}
        <b>MAGIKK </b>poderá ser objeto de ação de regresso contra a{" "}
        <b>EMPRESA ANUNCIANTE </b>e a <b>ONG</b>.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.9pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textIndent: "-.3pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        A <b>MAGIKK </b>não se responsabiliza pelas obrigações tributárias ou
        trabalhistas que recaiam sobre as atividades exercidas pelas{" "}
        <b>EMPRESAS ANUNCIANTESS </b>e as <b>ONGS </b>.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "0in",
        marginBottom: ".0001pt",
        marginLeft: ".55pt",
        lineHeight: "normal",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          8. OBRIGAÇÕES DOS CLIENTES:{" "}
        </span>
      </b>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "14.0pt",
        marginRight: "1.1pt",
        marginBottom: ".0001pt",
        marginLeft: "1.0pt",
        textIndent: "-.3pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Cabe unicamente ao <b>CLIENTE </b>exigir o documento fiscal,
        correspondente aos serviços prestados e/ou produtos fornecidos, da{" "}
        <b>EMPRESA ANUNCIANTE </b>e/ou da <b>ONG</b>.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.45pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textAlign: "justify",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        O <b>CLIENTE</b>compromete-se a efetuar o pagamento pelos serviços
        contratados ou produtos adquiridos junto à <b>EMPRESA ANUNCIANTE </b>
        e/ou à <b>ONG</b>, no uso das funcionalidades da <b>PLATAFORMA </b>, nos
        termos do que for combinado entre as partes no momento da realização das
        negociações.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.1pt",
        marginBottom: ".0001pt",
        marginLeft: ".3pt",
        textAlign: "justify",
        textIndent: ".4pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        O <b>CLIENTE </b>declara estar ciente de que a <b>PLATAFORMA </b>não tem
        nenhum vínculo com as <b>EMPRESAS ANUNCIANTES </b>e as <b>ONGS </b>, de
        forma que todo e qualquer problema oriundo da prestação de serviços e/ou
        do fornecimento de produtos intermediados pela <b>PLATAFORMA </b>somente
        pode ser resolvido junto à respectiva <b>EMPRESA ANUNCIANTE </b>e/ou{" "}
        <b>ONG</b>.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.15pt",
        marginBottom: ".0001pt",
        marginLeft: "1.0pt",
        textIndent: "-.25pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        O <b>CLIENTE</b>é o único responsável pela escolha, realização de
        negociação, acerto de preço, forma de pagamento e a respectiva
        contratação da <b>EMPRESA ANUNCIANTE </b>e/ou da <b>ONG</b>.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "0in",
        marginBottom: ".0001pt",
        marginLeft: "1.0pt",
        textIndent: "-.25pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        O <b>CLIENTE</b>é o único responsável pelo acompanhamento do
        desenvolvimento dos serviços a serem prestados e/ou dos produtos a serem
        fornecidos pelas <b>EMPRESAS ANUNCIANTES </b>e/ou pelas <b>ONGS </b>.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "0in",
        marginBottom: ".0001pt",
        marginLeft: ".55pt",
        lineHeight: "normal",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          9. MÉTODOS DE PAGAMENTO:{" "}
        </span>
      </b>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "14.0pt",
        marginRight: "0in",
        marginBottom: ".0001pt",
        marginLeft: "1.1pt",
        lineHeight: "normal",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Em troca de <b>KARMAS </b>, a <b>PLATAFORMA </b>disponibiliza ao{" "}
        <b>USUÁRIO </b>um dos métodos de pagamento a seguir:{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "14.0pt",
        marginRight: "0in",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        lineHeight: "normal",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        a) Cartão de Crédito VISA; ou,{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: ".55pt",
        marginRight: "0in",
        marginBottom: ".0001pt",
        marginLeft: "1.0pt",
        lineHeight: "normal",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        b) Cartão de Crédito MASTERCARD; ou,{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: ".55pt",
        marginRight: "0in",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        lineHeight: "normal",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        c) PIX{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "14.0pt",
        marginRight: "1.45pt",
        marginBottom: ".0001pt",
        marginLeft: ".95pt",
        textIndent: ".15pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Para processar os pagamentos através de cartão de crédito, será
        necessário que o <b>USUÁRIO </b>cadastre na <b>PLATAFORMA </b>os
        seguintes dados do Cartão de Crédito escolhido:{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "0in",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        lineHeight: "normal",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        a) Nome do titular do Cartão de Crédito;{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: ".55pt",
        marginRight: "0in",
        marginBottom: ".0001pt",
        marginLeft: "1.0pt",
        lineHeight: "normal",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        b) Número do Cartão de Crédito;{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: ".55pt",
        marginRight: "0in",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        lineHeight: "normal",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        c) Bandeira do Cartão de Crédito;{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: ".55pt",
        marginRight: "0in",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        lineHeight: "normal",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        d) Vencimento do Cartão de Crédito (Mês e Ano);{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: ".55pt",
        marginRight: "0in",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        lineHeight: "normal",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        e) Número de segurança do Cartão de Crédito.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "14.0pt",
        marginRight: "1.25pt",
        marginBottom: ".0001pt",
        marginLeft: ".65pt",
        textAlign: "justify",
        textIndent: ".05pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Os pagamentos dos serviços contratados na <b>PLATAFORMA </b>serão
        processados através da plataforma ASAAS sendo necessário que todos os{" "}
        <b>USUÁRIOS </b> também leiam e aceitem os termos de uso e serviços da
        plataforma ASAAS através do endereço eletrônico:
        https://ajuda.asaas.com/pt-BR/articles/102021-termos-e-condicoes-de-uso{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "27.4pt",
        marginRight: "1.05pt",
        marginBottom: ".0001pt",
        marginLeft: ".65pt",
        textAlign: "justify",
        textIndent: "-.25pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Ao contratar a <b>PLATAFORMA </b>, com pagamento através de cartão de
        crédito ou boleto, o <b>USUÁRIO </b>indica expressamente que leu e
        aceitou todas as condições presentes neste instrumento e nos termos de
        uso e serviços específicos da plataforma.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.6pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textAlign: "justify",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Qualquer contestação de pagamentos efetuados através da plataforma ASAAS
        deverá ser resolvida somente entre os <b>USUÁRIOS </b>e a ASAAS, não
        recaindo a <b>MAGIKK </b>qualquer reponsabilidade pelo processamento dos
        pagamentos efetuados junto à plataforma ASAAS.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.95pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textIndent: "-.3pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        A plataforma ASAAS poderá cobrar taxas pela sua utilização, cabendo
        somente ao <b>USUÁRIO </b>se informar acerca dos valores praticados pela
        ASAAS.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.55pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textAlign: "justify",
        textIndent: "-.3pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        A <b>MAGIKK </b>somente se responsabiliza por deletar os dados de
        pagamento fornecidos pelo <b>USUÁRIO </b>de sua própria base de dados,
        declarando o <b>USUÁRIO </b>estar ciente de que não compete a{" "}
        <b>MAGIKK </b>promover a exclusão dos referidos dados da base de dados
        da ASAAS.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "0in",
        marginBottom: ".0001pt",
        marginLeft: "1.0pt",
        lineHeight: "normal",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          10. POLÍTICA DE PRIVACIDADE E DO TRATAMENTO DOS DADOS:{" "}
        </span>
      </b>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "14.0pt",
        marginRight: ".95pt",
        marginBottom: ".0001pt",
        marginLeft: ".4pt",
        textAlign: "justify",
        textIndent: ".7pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Durante a utilização da <b>PLATAFORMA </b>, pelo <b>USUÁRIO </b>, a{" "}
        <b>MAGIKK </b>coletará e armazenará as informações fornecidas pelos{" "}
        <b>USUÁRIOS </b>, nos termos deste instrumento, conjuntamente com
        informações geradas automaticamente, tais como, mas não se limitando a,
        as características do dispositivo de acesso, do navegador, registros de
        acesso à aplicação (IP, com data e hora), informações acessadas, telas
        acessadas, dados de geolocalização, histórico de aplicações, dentre
        outras, dos <b>USUÁRIOS </b>, que serão armazenadas no banco de dados e
        também no <i>cookie </i>do browser.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.0pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textAlign: "justify",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Os dados coletados dos <b>USUÁRIOS </b>, pela <b>EMPRESA </b>, por meio da
        utilização da <b>PLATAFORMA </b>, serão utilizados para fins de
        aprimoramento da navegação do <b>USUÁRIO </b>, bem como para fins
        publicitários e estatísticos.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.55pt",
        marginBottom: ".0001pt",
        marginLeft: ".35pt",
        textAlign: "justify",
        textIndent: ".35pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        O <b>USUÁRIO </b>declara compreender, aceitar e consentir que todos os
        dados coletados por meio da <b>PLATAFORMA </b>sejam tratados, nos termos
        do artigo 5, inciso X, da Lei 13.709 de 2018, pela <b>MAGIKK </b>ou por
        terceiros, sendo estes:{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.1pt",
        marginBottom: ".0001pt",
        marginLeft: ".75pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          (i){" "}
        </span>
      </b>
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        a AWS, inscrita no CNPJ sob o nº 23.412.247/0001-10, endereço eletrônico
        https://aws.amazon.com/; <b>(iv)</b>a Google Analytics, inscrita no CNPJ
        sob o nº 06.990.590/0001-23, endereço eletrônico
        https://www.google.com/;{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.15pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textAlign: "justify",
        textIndent: "-.4pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Todos os dados fornecidos pelo <b>USUÁRIO </b>à <b>EMPRESA </b>, por meio
        da utilização da <b>PLATAFORMA </b>, serão considerados confidenciais
        pela <b>EMPRESA </b>, esta se compromete a adotar todos os esforços com o
        intuito de preservar a segurança de seus sistemas na guarda de tais
        dados, atendendo aos padrões de segurança estabelecidas no Decreto nº
        8.771/2016, tais como:{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "2.15pt",
        marginBottom: ".0001pt",
        marginLeft: "1.0pt",
        textIndent: "-.25pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          (i)
        </span>
      </b>
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Utilização de métodos padrões de mercado para criptografar os dados
        coletados, além de demais formas padrão de encriptação, para garantir
        sua inviolabilidade;{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: ".4pt",
        marginRight: "2.05pt",
        marginBottom: ".0001pt",
        marginLeft: ".65pt",
        textIndent: ".1pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          (ii)
        </span>
      </b>
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Emprego de softwares de alta tecnologia para proteção contra o acesso
        não autorizado aos sistemas, sendo estes considerados ambientes
        controlados e de segurança;{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "14.0pt",
        marginRight: "1.45pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textAlign: "justify",
        textIndent: ".05pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          (iii){" "}
        </span>
      </b>
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Disponibilização de acesso controlado a locais de armazenamento de dados
        pessoais apenas a pessoas previamente autorizadas e autenticadas,
        comprometidas ao sigilo de tais dados, inclusive mediante a assinatura
        de termo de confidencialidade;{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: ".4pt",
        marginRight: "1.55pt",
        marginBottom: ".0001pt",
        marginLeft: ".75pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          (iv){" "}
        </span>
      </b>
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Aplicação de mecanismos de autenticação de acesso aos registros capazes
        de individualizar o responsável pelo tratamento e acesso dos dados
        coletados em decorrência da utilização da <b>PLATAFORMA </b>;<b>(v) </b>
        Anonimização dos dados do <b>USUÁRIO </b>ao serem compartilhados com
        terceiros não parceiros da <b>EMPRESA </b>; e,{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: ".4pt",
        marginRight: "1.65pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textAlign: "justify",
        textIndent: ".05pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          (vi){" "}
        </span>
      </b>
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Manutenção do inventário indicando momento, duração, identidade do
        funcionário ou do responsável pelo acesso e o arquivo objeto, com base
        nos registros de conexão e de acesso a aplicações, conforme determinado
        no artigo 13 do Decreto nº 8.771/2016.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "2.25pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Os dados dos <b>USUÁRIOS, </b>coletados pela <b>EMPRESA </b>, por meio da
        utilização <b>PLATAFORMA, </b>poderão ser compartilhados com terceiros,
        nas hipóteses relacionadas abaixo:{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "0in",
        marginBottom: ".0001pt",
        marginLeft: ".4pt",
        textAlign: "justify",
        textIndent: ".35pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          (i){" "}
        </span>
      </b>
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Quando necessário às atividades comerciais da <b>EMPRESA </b>, e das
        empresas de seu grupo econômico, com o intuito de possibilitar a
        prestação dos serviços ofertados por meio da <b>PLATAFORMA </b>. Neste
        caso, a <b>MAGIKK </b>compartilhará o CPF/CNPJ,, nome, telefone,
        histórico de causas, tais como doações e trabalhos voluntários,
        histórico de compras e e-mail do <b>USUÁRIO </b>, com a empresa que
        aceita boas ações como pagamento, que realizará um estudo para entender
        melhor como se relacionar e entregar mais campanhas de acordo com cada
        perfil;{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: ".4pt",
        marginRight: ".3pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textIndent: ".05pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          (ii)
        </span>
      </b>
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Em caso de transações e alterações societárias envolvendo a{" "}
        <b>MAGIKK </b>, hipótese em que a transferência dos dados será necessária
        para a continuidade dos serviços ofertados por meio da <b>PLATAFORMA </b>
        ; <b>(iii) </b>Para a proteção dos interesses da <b>MAGIKK </b>em caso
        de conflito, inclusive em demandas judiciais; e <b>(iv) </b>Mediante
        ordem judicial ou por requerimento de autoridades administrativas que
        detenham competência legal para sua requisição.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "2.1pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textIndent: "-.3pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        A <b>MAGIKK </b>garante ao <b>USUÁRIO </b>, no que diz respeito ao
        processamento de dados pessoais, os seguintes direitos:{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "0in",
        marginBottom: ".0001pt",
        marginLeft: ".75pt",
        lineHeight: "normal",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          (i){" "}
        </span>
      </b>
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        A confirmação da existência de tratamento de seus dados pessoais;{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: ".55pt",
        marginRight: "1.25pt",
        marginBottom: ".0001pt",
        marginLeft: "1.0pt",
        textIndent: "-.25pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          (ii)
        </span>
      </b>
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        O acesso aos seus dados coletados pela <b>PLATAFORMA </b>, através do seu
        próprio login ou mediante requisição ao falacomigo@magikk.com;{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: ".4pt",
        marginRight: "36.5pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textIndent: ".05pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          (iii)
        </span>
      </b>
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        A correção de seus dados, caso estes estejam incompletos, inexatos ou
        desatualizados; <b>(iv) </b>O bloqueio ou eliminação de dados
        desnecessários, excessivos ou tratados em desconformidade com a
        legislação brasileira aplicável;{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: ".4pt",
        marginRight: ".95pt",
        marginBottom: ".0001pt",
        marginLeft: ".95pt",
        textIndent: "-.15pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          (v)
        </span>
      </b>
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        A portabilidade dos dados pessoais, para si ou para terceiro, mediante
        requisição expressa feita pelo <b>USUÁRIO </b>à <b>EMPRESA </b>, através
        de falacomigo@magikk.com;{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: ".4pt",
        marginRight: "1.35pt",
        marginBottom: ".0001pt",
        marginLeft: "1.0pt",
        textIndent: "-.25pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          (vi)
        </span>
      </b>
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        A eliminação dos dados pessoais tratados com o seu consentimento, desde
        que não haja determinação legal para mantê-los registrados junto à{" "}
        <b>EMPRESA </b>;{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: ".4pt",
        marginRight: "1.5pt",
        marginBottom: ".0001pt",
        marginLeft: ".65pt",
        textIndent: ".1pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          (vii)
        </span>
      </b>
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        A obtenção de informações sobre entidades públicas ou privadas com as
        quais a <b>MAGIKK </b>compartilhou seus dados; e,{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: ".4pt",
        marginRight: "1.1pt",
        marginBottom: ".0001pt",
        marginLeft: ".95pt",
        textIndent: "-.15pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          (viii)
        </span>
      </b>
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Informações sobre a possibilidade e consequência do não fornecimento do
        consentimento do <b>USUÁRIO </b>.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "2.0pt",
        marginBottom: ".0001pt",
        marginLeft: "1.0pt",
        textIndent: "-.25pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        O <b>USUÁRIO </b> poderá enviar e-mail para falacomigo@magikk.com,
        apontando dúvidas e/ou requerimentos relacionados a seus dados pessoais.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "14.0pt",
        marginRight: "6.6pt",
        marginBottom: ".0001pt",
        marginLeft: ".4pt",
        textIndent: "-.2pt",
        lineHeight: "203%",
        border: "none"
      }}
    >
      
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        A <b>MAGIKK </b>poderá excluir os dados pessoais coletados dos{" "}
        <b>USUÁRIOS </b>:{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "2.6pt",
        marginRight: "1.25pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textAlign: "justify",
        textIndent: ".05pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          (i){" "}
        </span>
      </b>
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Quando a finalidade para a qual foram coletados seja alcançada; ou,
        quando deixarem de ser necessários ou pertinentes para o alcance da
        finalidade, conforme finalidades descritas nos presentes Termos de Uso e
        Política de Privacidade;{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: ".4pt",
        marginRight: "1.05pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textIndent: ".05pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          (ii)
        </span>
      </b>
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Quando o <b>USUÁRIO </b>revogar seu consentimento, nos casos em que este
        se fizer necessário, requerendo a exclusão à <b>MAGIKK </b>através do
        falacomigo@magikk.com; ou,{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: ".4pt",
        marginRight: "0in",
        marginBottom: ".0001pt",
        marginLeft: ".75pt",
        lineHeight: "normal",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          (iii){" "}
        </span>
      </b>
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Caso seja determinado por autoridade competente.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "14.0pt",
        marginRight: "0in",
        marginBottom: ".0001pt",
        marginLeft: "1.0pt",
        lineHeight: "normal",
        border: "none"
      }}
    >
      <b>
        <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
          11. DISPOSIÇÕES GERAIS:{" "}
        </span>
      </b>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "14.0pt",
        marginRight: "1.25pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textAlign: "justify",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Qualquer cláusula ou condição deste instrumento que, por qualquer razão,
        venha a ser reputada nula ou ineficaz por qualquer juízo ou tribunal,
        não afetará a validade das demais disposições destes Termos, as quais
        permanecerão plenamente válidas e vinculantes, gerando efeitos em sua
        máxima extensão.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.4pt",
        marginBottom: ".0001pt",
        marginLeft: "1.0pt",
        textIndent: "-.6pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        A falha da <b>MAGIKK </b>em exigir quaisquer direitos ou disposições dos
        presentes Termos não constituirá renúncia, podendo esta exercer
        regularmente o seu direito, dentro dos prazos legais.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: ".95pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textAlign: "justify",
        textIndent: "-.4pt",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        Todos os materiais, patentes, marcas, registros, domínios, nomes,
        privilégios, criações, imagens e todos os direitos conexos e
        relacionados com a<b>PLATAFORMA </b>e desenvolvidos pela <b>EMPRESA </b>,
        são e permanecerão de única e exclusiva propriedade da <b>EMPRESA </b>,
        concordando os <b>USUÁRIOS </b>em não praticar ato ou fato que, por
        qualquer modo, prejudique os direitos previstos aqui e tampouco
        reivindicar qualquer direito ou privilégio sobre os mesmos.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: ".55pt",
        marginBottom: ".0001pt",
        marginLeft: ".4pt",
        textAlign: "justify",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        A <b>MAGIKK </b>poderá alterar este instrumento a qualquer momento,
        bastando, para tanto, publicarmos uma versão revisada em nosso site. Por
        este motivo, recomendamos veementemente que sempre visite esta seção de
        nosso site, lendo-a, periodicamente. Mas, para contribuir com o bom
        relacionamento, também enviaremos um e-mail informando acerca dessas
        mudanças.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "13.8pt",
        marginRight: "1.1pt",
        marginBottom: ".0001pt",
        marginLeft: ".7pt",
        textAlign: "justify",
        lineHeight: "101%",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        O presente instrumento constitui o entendimento integral entre o{" "}
        <b>USUÁRIO </b>e a <b>MAGIKK </b>e é regido pelas Leis Brasileiras,
        ficando eleito o foro da cidade de domicílio do <b>USUÁRIO </b>como
        único competente para dirimir questões decorrentes do presente
        instrumento, com renúncia expressa a qualquer outro foro, por mais
        privilegiado que seja.{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      align="center"
      style={{
        marginTop: "13.8pt",
        textAlign: "center",
        lineHeight: "normal",
        border: "none"
      }}
    >
      <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        ***{" "}
      </span>
    </p>
    <p
      className="MsoNormal"
      align="center"
      style={{
        marginTop: "199.7pt",
        textAlign: "center",
        lineHeight: "normal",
        border: "none"
      }}
    >
    <span style={{ fontFamily: '"Calibri",sans-serif', color: "black" }}>
        MAGIKK SERVICOS DE INFORMACOES NA INTERNET LTDA / 35.352.544/0001-05
      </span>
    </p>
  </div>
</>



      </div>

    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TermOfUse);
