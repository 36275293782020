import React from "react";

import img_seta from "../../assets/new-landing-page/seta.svg";
import img_instituto_lar from "../../assets/new-landing-page/builder/d5c7c25d312454851df0d95979feb7f6d73a413386cf5dda4a28b5be1d859740.png";
import img_instituto_crianca from "../../assets/new-landing-page/builder/0318244be88e2211088cd083c40b0ce372889bd02f04637cd095b79de5611253.png";
import img_maikai from "../../assets/new-landing-page/builder/c5f2111291d9fb4cf0850294456e7f008bec799ee67b1343fcc74007593083a3.png";
import img_ffp from "../../assets/new-landing-page/builder/02f9be00acf8b035aee472ce09c6f6dc04ccb05daa1e103683afc38ec50d12b0.png";
import img_brownie from "../../assets/new-landing-page/builder/browniedoluiz.png";
import img_spoleto from "../../assets/new-landing-page/builder/spoleto.png";
import img_greenpeople from "../../assets/new-landing-page/builder/greenpeople.png";
import img_corona from "../../assets/new-landing-page/builder/corona.png";


import "./tailwind.js";

export const BePartOf = () => {
    return (
        <>
                <div className="flex justify-center items-center px-16 py-20 w-full bg-white max-md:px-5 max-md:max-w-full">
                    <div className="flex flex-col mt-9 mb-3 w-full max-w-[1176px] max-md:max-w-full">
                        <div className="flex flex-col font-[5] max-md:max-w-full">
                            <div className="flex flex-col justify-center text-3xl text-cyan-950 max-md:max-w-full">
                                <div className="shrink-0 w-14 bg-slate-400 h-[3px]" />
                                <div className="mt-6 max-md:max-w-full">
                                    Faça parte desta história e tenha também o{" "}

                                    <span className="font-bold">selo de impacto</span> conectado a
                                    sua marca.{" "}
                                </div>
                            </div>
                            <div className="justify-center mt-5 text-xl text-cyan-950 max-md:max-w-full">
                                Empresas que geraram impacto com a{" "}
                                <span className=" text-slate-400">magikk. </span>
                            </div>
                        </div>
                        <div className="flex flex-col justify-center mt-20 max-md:mt-10 max-md:max-w-full">
                            <div className="flex gap-5 justify-center items-center px-3 max-md:flex-wrap">
                                <img
                                    loading="lazy"
                                    srcSet={img_brownie}
                                    className="shrink-0 self-stretch h-28"
                                />
                                <img
                                    loading="lazy"
                                    srcSet={img_corona}
                                    className="shrink-0 self-stretch my-auto h-28"
                                />
                                <img
                                    loading="lazy"
                                    srcSet={img_greenpeople}
                                    className="shrink-0 self-stretch my-auto h-28"
                                />
                                <img
                                    loading="lazy"
                                    srcSet={img_spoleto}
                                    className="shrink-0 self-stretch my-auto h-28"
                                />
                            </div>
                        </div>
                    </div>
                </div>
        </>
    );
}